import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TicketList from "../TicketList";
import { useEffect, useState } from "react";
import { useWeb3Context } from "src/hooks";
import { getLotteryContract } from "src/utils/contract.utils";
import {
  convertUserRawTicketResponse,
  fetchUserTicketsForOneRound,
  processRawTicketsResponse,
} from "../../state/user-tickets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Grid } from "@material-ui/core";
import { parseRetrievedNumber } from "../../helpers";
import { makeStyles } from "@material-ui/core/styles";
import { Round, RoundNumberInput, RoundId } from "../styles";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    background: "#171E3B",
    padding: "32px 30px 40px",
  },
}));

const ViewTicketsModal = ({ lotteryId, open, onClose }) => {
  const { provider, chainID, address } = useWeb3Context();
  const lotteryContract = getLotteryContract(chainID, provider);
  const classes = useStyles();

  const [tickets, setTickets] = useState(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const getTix = async () => {
      const rawTicketInfo = await fetchUserTicketsForOneRound(address, lotteryId, lotteryContract);
      const mappedTix = convertUserRawTicketResponse(rawTicketInfo);
      console.log(mappedTix);

      setTickets(mappedTix);
      setFetched(true);
    };

    if (open && lotteryId) {
      getTix();
    }
  }, [open, lotteryId]);

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item>
            <Round>Round</Round>
          </Grid>
          <Grid item>
            <RoundNumberInput>
              <RoundId>{lotteryId}</RoundId>
            </RoundNumberInput>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {fetched ? (
          <Grid container direction="column">
            <TicketList tickets={tickets}></TicketList>
            <Grid item>
              <Button
                onClick={onClose}
                variant="outlined"
                fullWidth={true}
                style={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.02em",
                  color: "#FAFCFC",
                  marginTop: "25px",
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewTicketsModal;
