import { Radio, RadioProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const ICON_SIZE = 20;
const ICON_INNER_SIZE = 8;

const useStyles = makeStyles({
  root: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: ICON_SIZE,
    height: ICON_SIZE,
    border: "1px solid #0C2FAE",
    backgroundColor: "#000829",
  },
  checkedIcon: {
    backgroundColor: "#000829",

    "&:before": {
      borderRadius: "50%",
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: ICON_INNER_SIZE,
      height: ICON_INNER_SIZE,
      transform: "translate(-50%, -50%)",
      backgroundColor: "#0C2FAE",
      content: '""',
    },
  },
});

export function StyledRadioButton(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
