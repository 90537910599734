import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Social from "./Social";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { Paper, Link, Box, Typography } from "@material-ui/core";
import { addresses } from "../../constants";
import "./sidebar.scss";
import ConnectMenu from "../TopBar/ConnectMenu.jsx";

function NavContent({ theme, toggleTheme }) {
  const AaltoAddress = "https://pancakeswap.finance/swap?outputCurrency=" + addresses[56].AALTO_ADDRESS;
  const chartAddress = "https://dexscreener.com/bsc/" + addresses[56].AALTO_ADDRESS;
  const [isActive] = useState();
  const address = useAddress();
  const { chainID } = useWeb3Context();

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("home") >= 0 && page === "home") {
      return true;
    }
    if (currentPath.indexOf("account") >= 0 && page === "account") {
      return true;
    }
    if (currentPath.indexOf("bank") >= 0 && page === "bank") {
      return true;
    }
    if (currentPath.indexOf("lottery") >= 0 && page === "lottery") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    if ((currentPath.indexOf("calculator") >= 0 || currentPath.indexOf("calculator") >= 0) && page === "calculator") {
      return true;
    }
    if ((currentPath.indexOf("lottery") >= 0 || currentPath.indexOf("lottery") >= 0) && page === "lottery") {
      return true;
    }
    if ((currentPath.indexOf("wrap") >= 0 || currentPath.indexOf("wrap") >= 0) && page === "wrap") {
      return true;
    }
    if ((currentPath.indexOf("prediction") >= 0 || currentPath.indexOf("prediction") >= 0) && page === "prediction") {
      return true;
    }
    return false;
  }, []);

  const CustomTypography = styled(Typography)`
    width: 250px;
    display: block;
    color: #ffffff;
    font-weight: normal;
    padding: 12px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
  `;
  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">
          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">
              <Box display="flex" className="dapp-topbar" justifyContent="center">
                <ConnectMenu theme={theme} />
              </Box>
              <Link
                component={NavLink}
                id="dash-nav"
                to="/home"
                isActive={(match, location) => {
                  return checkPage(match, location, "home");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <CustomTypography variant="h6">Dashboard</CustomTypography>
              </Link>
              <Link
                component={NavLink}
                id="bank-nav"
                to="/bank"
                isActive={(match, location) => {
                  return checkPage(match, location, "bank");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <CustomTypography variant="h6">Bank</CustomTypography>
              </Link>
              <Link
                component={NavLink}
                id="lottery-nav"
                to="/lottery"
                isActive={(match, location) => {
                  return checkPage(match, location, "lottery");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <CustomTypography variant="h6">Whirlpool</CustomTypography>
              </Link>
              <Link key="4" href="https://aalto-wrapper.com" target="_blank" component="a">
                <CustomTypography variant="h6">Wrap</CustomTypography>
              </Link>
              <Link
                component={NavLink}
                id="calc-nav"
                to="/calculator"
                isActive={(match, location) => {
                  return checkPage(match, location, "calculator");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <CustomTypography variant="h6">Calculator</CustomTypography>
              </Link>
              <Link key="4" href="https://aalto-wrapper.com" target="_blank" component="a">
                <CustomTypography variant="h6">Wrap</CustomTypography>
              </Link>
              <Link key="0" href="https://docs.Aaltofinance.xyz/" target="_blank" component="a">
                <CustomTypography variant="h6">Analytics</CustomTypography>
              </Link>
              <Link key="0" href="https://www.aalto-protocol.com/whitepaper" target="_blank" component="a">
                <CustomTypography variant="h6">Docs</CustomTypography>
              </Link>
              <Link key="0" href={AaltoAddress} target="_blank" component="a">
                <CustomTypography variant="h6">Buy</CustomTypography>
              </Link>
              <Link key="0" href="https://aalto.chainbet.gg/" target="_blank" component="a">
                <CustomTypography variant="h6">Casino</CustomTypography>
              </Link>
            </div>
          </div>
        </div>
        <Box className="dapp-menu-bottom" display="flex" justifyContent="space-between" flexDirection="column">
          <div className="dapp-menu-social">
            <Social />
          </div>
        </Box>
      </Box>
    </Paper>
  );
}

export default NavContent;
