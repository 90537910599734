import { ethers } from "ethers";
import { getERC20Contract } from "./contract.utils";
import { awaitTransactionComplete } from "./utils";

export const checkApproval = async (
  token: string,
  owner: string,
  spender: string,
  signer: ethers.Signer,
  amount: ethers.BigNumber,
) => {
  const erc20 = getERC20Contract(token, signer);
  const allowance: ethers.BigNumber = await erc20.allowance(owner, spender);
  if (allowance.lt(amount)) {
    return false;
  }

  return true;
};

export const doApproval = async (token: string, spender: string, signer: ethers.Signer) => {
  const erc20 = getERC20Contract(token, signer);
  const tx = await erc20.approve(spender, ethers.constants.MaxUint256);
  await awaitTransactionComplete(tx);
};
