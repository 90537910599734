import { Grid, Box, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectCurrentRound } from "../state/selectors";
import Button from "@material-ui/core/Button";
import { LotteryStatus } from "../lotto.types";
import { useEffect, useState } from "react";
import moment from "moment";
import { LotteryCountdown } from "../../../components/LotteryCountdown";
import { LotteryBackground } from "./LotteryBackground";

const Subtitle = styled.h5`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;

  /* Brand / Main */
  margin: 0;
  padding-bottom: 30px;
  color: #03e1d0;
`;

const PrizeValue = styled.h2`
  margin-top: 0;
  display: inline-block;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.1;
  text-align: center;

  @media (min-width: 600px) {
    margin-top: 34px;
  }
`;

const SubText = styled.span`
  display: block;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const HeaderSection = styled(Grid)`
  position: relative;
  padding: 32px 0px 32px;
  background-repeat: no-repeat;
  background-position: calc(50% - 55px) center;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 832px;
  max-height: 468px;
  padding-bottom: 33.5%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 64px;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TicketWrapper = styled(Grid)`
  padding-bottom: 60px;

  @media (min-width: 600px) {
    padding-bottom: 0;
    padding-left: 40px; 
  }
`

const LotteryImage = styled.img`
  max-width: 100%;

  @media (min-width: 900px) {
    max-width: 638px;
  }
`

const LotteryWrapper = styled(Box)`
  padding-top: 10px;

  @media (min-width: 600px) {
    padding-top: 30px;
  }
`

const LotteryHeader = ({ onBuyTicketOpen }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const currentRound = useSelector(selectCurrentRound);
  const { endTime, totalTokenValue, status } = currentRound;

  const endTimeMoment = moment(endTime * 1000);

  const [seconds, setSeconds] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [hours, setHours] = useState(null);

  const setTime = () => {
    const diff = moment.duration(endTimeMoment.diff(Date.now()));

    if (diff.hours() <= 0 && diff.minutes() <= 0 && diff.seconds() <= 5) {
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setHours(Math.abs(diff.hours()));
      setMinutes(Math.abs(diff.minutes()));
      setSeconds(Math.abs(diff.seconds()));
    }
  };

  useEffect(() => {
    setTime();

    const timer = setInterval(() => {
      setTime();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <HeaderSection
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <LotteryBackground />

        <Grid item justifyContent="center">
          <LotteryImage 
            srcSet="images/logo-whirpool-large.png 1276w, images/logo-whirpool-medium.png 850w, images/logo-whirpool-small.png  720w"
            sizes="(min-width: 1440px) 44vw, (min-width: 600px) 90vw, 100vw"
            src="images/logo-whirpool-small.png"
            alt="Aalto Protocol Lottery. The Whirlpool."
          />

          <Subtitle>The first ever multi-token bingo lottery!</Subtitle>
        </Grid>
        <Grid item container direction={isSmallScreen ? 'column' : 'row'} justifyContent="center" alignItems="center">
          <Grid item>
            <PrizeValue>
              <span className="aalto-txt">${totalTokenValue}</span> 
              <SubText>in prizes</SubText>
            </PrizeValue>
          </Grid>

          <TicketWrapper item>
            <Button className="aalto-rec-button big" onClick={onBuyTicketOpen} disabled={status !== LotteryStatus.OPEN}>
              {status !== LotteryStatus.OPEN ? "Next Round Starting Soon" : "Buy Tickets"}
            </Button>
          </TicketWrapper>
        </Grid>
        <Grid item>
          <LotteryWrapper>
            <LotteryCountdown title="Next draw in" hours={hours} minutes={minutes} seconds={seconds} paddingBottom={6} />
          </LotteryWrapper>
        </Grid>
      </HeaderSection>
      <VideoWrapper>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/nvBlOc6p5lY?rel=0&showinfo=0&autoplay=1&loop=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
    </>
  );
};

export default LotteryHeader;
