import styled from "styled-components";
import { LotteryStatus } from "../types";
import { Paper, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { ReactComponent as Metamask } from "../../../assets/metamask.svg";
import { ReactComponent as AaltoIcon } from "../../../assets/icons/logo.svg";
import { ReactComponent as AshareIcon } from "../../../assets/icons/ashare.svg";
import { ReactComponent as AmesIcon } from "../../../assets/icons/ames.svg";
import { watchAssetInMetamask } from "src/helpers/metamask";
import { selectCurrentRound } from "../state/selectors";
import { tokensUI } from "./utils";
import LotteryDetails from "./LotteryDetails";
import ViewTickets from "./History/ViewTickets";

const TokensWrapper = styled.span`
  display: flex;
  position: relative;
  cursor: pointer;

  & svg {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }
`;
const CardSection = styled.div`
  margin-top: 5px;
  margin-bottom: 35px;
`;

const TokensList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px 0px;
`;

const NextDrawTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  color: #e4ebea !important;
`;

const DrawDate = styled(Typography)`
  font-size: 20px !important;
  font-weight: 300 !important;
  color: #fafcfc !important;
`;

const DrawNumber = styled(Typography)`
  padding-top: 4px;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #848a89 !important;
  text-transform: uppercase;
`;

const DrawTotalAmount = styled(Typography)`
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 30px;
  color: rgb(3, 225, 208) !important;
  text-align: right;
`;

const TokenImg = styled.img`
  width: 32px;
  height: 32px;
`;

const BuyTicketsButton = styled(Button)`
  max-height: inherit;
  background: #020de0;
  color: #fafcfc;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  padding: 14px 30px;
  margin: 0;
  width: 100%;

  &:hover {
    background: #020de0;
  }
`;

const MetaMaskIcon = styled(Metamask)`
  position: absolute;
  width: 14px !important;
  height: 14px !important;
  top: 50%;
  left: -5%;
`;

const NextDrawCard = ({ onBuyTicketOpen }) => {
  const currentRound = useSelector(selectCurrentRound);
  const { status, lotteryId, endTimeDate, tokens, totalTokenValue, countWinnersPerBracket } = currentRound;

  const getNextDrawId = () => {
    if (status === LotteryStatus.OPEN) {
      return `${lotteryId}`;
    }
    if (status === LotteryStatus.PENDING) {
      return "";
    }
    return lotteryId + 1;
  };

  const getNextDrawDateTime = () => {
    if (status === LotteryStatus.OPEN) {
      return format(new Date(endTimeDate), "dd-MM-yyyy, hh:mmaaa O");
    }
    return "";
  };

  const getIcons = icon => {
    switch (icon) {
      case "Aalto":
        return <AaltoIcon />;
      case "Ashare":
        return <AshareIcon />;
      case "Ames":
        return <AmesIcon />;
    }
  };

  return (
    <Paper style={{ margin: "24px auto", maxWidth: "635px" }} className="aalto-card">
      <CardSection>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <NextDrawTitle variant="h5">Next Draw</NextDrawTitle>
          </Grid>
          <Grid item xs={6}>
            <DrawDate align="right">{Boolean(endTimeDate) && getNextDrawDateTime()}</DrawDate>
            <DrawNumber align="right">{`Draw #${getNextDrawId()}`}</DrawNumber>
          </Grid>
        </Grid>
      </CardSection>
      <CardSection>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Typography>Prize Pot</Typography>
          </Grid>
          <Grid item xs={6} justifyContent="flex-end">
            <DrawTotalAmount>${totalTokenValue}</DrawTotalAmount>
            <TokensList>
              {tokens?.length &&
                tokens.map(token => (
                  <TokensWrapper
                    onClick={() => watchAssetInMetamask(tokensUI[token.tokenAddress]?.tokenKey)}
                    role="button"
                    key={token.tokenAddress}
                  >
                    <span>
                      {tokensUI[token.tokenAddress]?.icon ? (
                        getIcons(tokensUI[token.tokenAddress]?.name)
                      ) : (
                        <TokenImg src={tokensUI[token.tokenAddress]?.image} />
                      )}
                      <MetaMaskIcon />
                    </span>
                  </TokensWrapper>
                ))}
            </TokensList>
          </Grid>
        </Grid>
      </CardSection>
      <CardSection>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Typography>Your Tickets</Typography>
          </Grid>
          <Grid item xs={6}>
            <ViewTickets lotteryId={lotteryId}></ViewTickets>
          </Grid>
        </Grid>
      </CardSection>
      <CardSection>
        <BuyTicketsButton onClick={onBuyTicketOpen} disabled={status !== LotteryStatus.OPEN}>
          {status !== LotteryStatus.OPEN ? "Next Round Starting Soon" : "Buy Tickets"}
        </BuyTicketsButton>
      </CardSection>

      <LotteryDetails
        tokens={tokens}
        countWinnersPerBracket={countWinnersPerBracket}
        isHistoricRound={status === LotteryStatus.CLAIMABLE}
      ></LotteryDetails>
    </Paper>
  );
};

export default NextDrawCard;
