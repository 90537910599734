import { useCallback, useState } from "react";
import { useAddress } from "src/hooks/web3Context";
import { AppBar, Toolbar, Box, Button, SvgIcon, Link, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import ConnectMenu from "./ConnectMenu.jsx";
import "./topbar.scss";
import { addresses } from "../../constants";

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: "10px",
    },
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "transparent",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("981")]: {
      display: "none",
    },
  },
}));

const CustomTypography = styled(Typography)`
  display: block;
  color: #ffffff;
  font-weight: normal;
  padding: 12px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
`;

function TopBar({ theme, toggleTheme, handleDrawerToggle }) {
  const classes = useStyles();
  const isVerySmallScreen = useMediaQuery("(max-width: 980px)");
  const [isActive] = useState();

  const AaltoAddress = "https://app.bogged.finance/bsc/swap?tokenIn=BNB&tokenOut=" + addresses[56].AALTO_ADDRESS;
  const chartAddress = "https://dexscreener.com/bsc/" + addresses[56].AALTO_ADDRESS;
  const address = useAddress();

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("home") >= 0 && page === "home") {
      return true;
    }
    if (currentPath.indexOf("bank") >= 0 && page === "bank") {
      return true;
    }
    if (currentPath.indexOf("lottery") >= 0 && page === "lottery") {
      return true;
    }
    if (currentPath.indexOf("wrap") >= 0 && page === "wrap") {
      return true;
    }
    if (currentPath.indexOf("account") >= 0 && page === "account") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    if ((currentPath.indexOf("calculator") >= 0 || currentPath.indexOf("calculator") >= 0) && page === "calculator") {
      return true;
    }
    if ((currentPath.indexOf("prediction") >= 0 || currentPath.indexOf("prediction") >= 0) && page === "prediction") {
      return true;
    }
    return false;
  }, []);

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className="dapp-topbar" style={{ justifyContent: "space-between" }}>
        <Box display="flex" style={{ padding: "0px 12px" }}>
          <img src="images/logoWithText.png" alt="" height="26px" width="128px" />
        </Box>
        <Button
          id="hamburger"
          aria-label="open drawer"
          edge="start"
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <SvgIcon component={MenuIcon} />
        </Button>
        {!isVerySmallScreen && (
          <>
            <div className="dapp-menu-links-top">
              <div className="dapp-nav-top" id="navbarNav">
                <Link
                  component={NavLink}
                  id="dash-nav"
                  to="/home"
                  isActive={(match, location) => {
                    return checkPage(match, location, "home");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <CustomTypography variant="h6">Dashboard</CustomTypography>
                </Link>
                <Link
                  component={NavLink}
                  id="bank-nav"
                  to="/bank"
                  isActive={(match, location) => {
                    return checkPage(match, location, "bank");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <CustomTypography variant="h6">Bank</CustomTypography>
                </Link>
                <Link
                  component={NavLink}
                  id="lottery-nav"
                  to="/lottery"
                  isActive={(match, location) => {
                    return checkPage(match, location, "lottery");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <CustomTypography variant="h6">Whirlpool</CustomTypography>
                </Link>
                {/* <Link
                  component={NavLink}
                  id="wrap-nav"
                  to="/wrap"
                  isActive={(match, location) => {
                    return checkPage(match, location, "wrap");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <CustomTypography variant="h6">Wrap</CustomTypography>
                </Link> */}
                <Link
                  component={NavLink}
                  id="calc-nav"
                  to="/calculator"
                  isActive={(match, location) => {
                    return checkPage(match, location, "calculator");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <CustomTypography variant="h6">Calculator</CustomTypography>
                </Link>
                <Link key="4" href="https://aalto-wrapper.com" target="_blank" component="a">
                  <CustomTypography variant="h6">Wrap</CustomTypography>
                </Link>
                <Link
                  key="0"
                  href="https://defined.fi/bsc/0x4fea7445f515b215f4284f33cb6be610d5df0c33?cache=adaa7"
                  target="_blank"
                  component="a"
                >
                  <CustomTypography variant="h6">Analytics</CustomTypography>
                </Link>
                <Link key="1" href="https://www.aalto-protocol.com/whitepaper" target="_blank" component="a">
                  <CustomTypography variant="h6">Docs</CustomTypography>
                </Link>
                <Link key="2" href={AaltoAddress} target="_blank" component="a">
                  <CustomTypography variant="h6">Buy</CustomTypography>
                </Link>
                <Link
                  key="3"
                  href="https://aalto.chainbet.gg/"
                  target="_blank"
                  component="a"
                >
                  <CustomTypography variant="h6">Casino</CustomTypography>
                </Link>
              </div>
            </div>
          </>
        )}

        {!isVerySmallScreen && (
          <Box display="flex">
            <ConnectMenu theme={theme} />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
