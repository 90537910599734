import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { LotteryTicket } from "./types";

export enum LotteryStatus {
  PENDING = "pending",
  OPEN = "open",
  CLOSE = "close",
  CLAIMABLE = "claimable",
}

export interface TokenRewardInfo {
  bracket?: number;
  amountToken?: string;
  prizeValue?: number;
}

export interface RewardClaim {
  roundId: number;
  ticketIds: number[];
  brackets: number[];
}

export interface LotteryToken {
  name?: string;
  image?: string;
  treasuryFee?: number;
  burnFee?: number;
  amountTokenCollected: number;
  ticketCount: number;
  availableForPurchase: number;
  maxTicketAmount: number;
  tokenAddress: string;
  pairAddress: string;
  rewardPerBracket: number[];
  totalValue?: number;
  pricePerTicket?: BigNumber;
  rewardInfo?: TokenRewardInfo[];
  currentPrice?: number;
  isApproved?: boolean;
  bracketRewards?: { amount: string; value: string }[];
}

export interface AaltoLotteryTicket {
  id: string;
  number: string;
  status: boolean;
  rewardBracket?: number;
  roundId?: string;
  rewardAmount?: string;
}

export interface LotteryRoundUserTickets {
  isLoading?: boolean;
  tickets?: LotteryTicket[];
}

export interface LotteryBase {
  status?: LotteryStatus;
  startTime?: number;
  endTime?: number;
  startTimeDate?: string;
  endTimeDate?: string;
  discountDivisor?: BigNumber;
  isLoading?: boolean;
  lotteryId?: any;
  tokens?: LotteryToken[];
  rewardsBreakdown?: number[];
  rewardsBreakdownBN?: ethers.BigNumber[];
  countWinnersPerBracket?: number[];
  treasuryFee?: number;
  finalNumber?: number;
  maxNumberTicketsPerBuyOrClaim: BigNumber;
  totalTokenValue?: number;
  firstTicketId?: number;
  firstTicketIdNextLottery?: number;
  userInfo?: UserTicketInfoForLottery;
}

export interface UserTicketInfoForLottery {
  user: string;
  totalTickets: number;
  tickets: UserTokenData[];
  winningTickets?: LotteryTicket[];
  totalBaseTicketsForRound: LotteryTicket[];
  isLoading: boolean;
}

export interface AaltoLotteryState {
  isLoading: boolean;
  currentRound: LotteryBase;
}

export interface UserTokenData {
  lotteryId: string;
  lottery: LotteryBase;
  tokenAddress: string;
  amountTokenIn: number;
  ticketIds: number[];
  balanceUI?: string;
  balanceBN?: BigNumber;
  tickets: LotteryTicket[];
}

export interface LotteryUser {
  address: string;
  lotteries: LotteryBase[];
  currentLottery: LotteryBase;
  currentTickets: UserTokenData[];
}

export enum FetchStatus {
  Idle = "IDLE",
  Fetching = "FETCHING",
  Fetched = "FETCHED",
  Failed = "FAILED",
}
