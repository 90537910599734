import { Box, Typography } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;

  @media (min-width: 900px) {
    padding: 64px 0;
    flex-direction: row;
  }
`

const Heading = styled(Typography)`
  font-size: 40px;
  font-weight: 900;
  padding-bottom: 32px;
  letter-spacing: -0.046em;

  @media (min-width: 600px) {
    font-size: 50px;
  }

  @media (min-width: 900px) {
    padding-bottom: 0;
  }

  @media (min-width: 1280px) {
    font-size: 86px;
  }
`

interface PageHeadingProps {
  title: string;
}

export function PageHeading({ title, children }: PropsWithChildren<PageHeadingProps>) {
  return (
    <Wrapper>
      <Heading variant="h1">{title}</Heading>
      {children}
    </Wrapper>
  )
}
