import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import { parseRetrievedNumber } from "../../helpers";
import LotteryNumber from "./LotteryNumber";

const NumberFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: 92px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const NumberHeader = styled.div`
  width: 234px;
  height: 38px;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;
  color: #fafcfc;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const WinningNumber = ({ winningNumber }) => {
  if (winningNumber && !Array.isArray(winningNumber)) {
    winningNumber = parseRetrievedNumber(String(winningNumber)).split("");
  }

  return (
    <Grid item container direction="column" justifyContent="center">
      <Grid item>
        <NumberFrame>
          <NumberHeader>Winning Number</NumberHeader>
          <Grid container justifyContent="center">
            {winningNumber ? (
              winningNumber.map((num, i) => {
                return <LotteryNumber index={i} value={num} key={i}></LotteryNumber>;
              })
            ) : (
              <Skeleton variant="rect"></Skeleton>
            )}
          </Grid>
        </NumberFrame>
      </Grid>
    </Grid>
  );
};

export default WinningNumber;
