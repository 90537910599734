import { useEffect, useState } from "react";
import styled from 'styled-components';
import { Paper, Grid, Typography, Box, Zoom, Container, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { trim, formatCurrency } from "../../helpers";
import moment from 'moment';
import { useTheme } from "@material-ui/core/styles";
import "./treasury-dashboard.scss";
import ProgressCountdown from "src/components/ProgressCountdown";

function TreasuryDashboard() {
  
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const circSupply = useSelector(state => {
    return state.app.circSupply;
  });
  const totalSupply = useSelector(state => {
    return state.app.totalSupply;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap;
  });
  
  const AaltoBalance = useSelector(state => {//titano_ balance
    return state.account.balances && state.account.balances.aalto;
  }); 

  const rfvBalance = useSelector(state => {//titano_ balance
    return state.app.rfv;
  }); 
  const mvtBalance = useSelector(state => {//titano_ balance
    return state.app.mvt;
  }); 
  const blvBalance = useSelector(state => {//titano_ balance
    return state.app.blv;
  }); 

  const nextRebase = useSelector(state => {
    return state.app.nextRebase;
  });
  const backedLiquidity = useSelector(state => {
    return state.app.backedLiquidity;
  });

  const CustomSpan = styled.span`
    width: fit-content;
    background-color: #fd3d56;
    -webkit-background-clip: text;
    background-clip: text;
    text-shadow: #FF3030f0 1px 0 10px;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
  `

  const CustomHeaderText = styled(Typography)`
    margin-top:2px !important;
    margin-bottom:2px !important;
  `
  return (
    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >
  
        <Box className={`hero-metrics`}>
          <Paper className="aalto-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box className="metric market">
                <div>
                <CustomHeaderText variant="h6" color="textSecondary">
                  Market Cap
                </CustomHeaderText>
                <Typography variant="h5">
                  {marketCap?formatCurrency(marketCap, 0):<Skeleton type="text" />}
                  {/* {!marketCap && <Skeleton type="text" />} */}
                </Typography>
                </div>
              </Box>

              <Box className="metric price">
                <CustomHeaderText variant="h6" color="textSecondary">
                <CustomSpan>AALTO </CustomSpan>
                   Price
                </CustomHeaderText>
                <Typography variant="h5">
                  {/* appleseed-fix */}
                  {marketPrice ? formatCurrency(marketPrice, 12) : <Skeleton type="text" />}
                </Typography>
              </Box>

              <Box className="metric wsoprice">
                <CustomHeaderText variant="h6" color="textSecondary">
                  Next Rebase                  
                </CustomHeaderText>

                <Typography variant="h5">
                  <ProgressCountdown str="Rebasing" base={moment().toDate()} unixDeadline={nextRebase} description="Next Rebase" />
                </Typography>
              </Box>

              <Box className="metric circ" >
                <CustomHeaderText variant="h6" color="textSecondary">
                  Total Supply
                </CustomHeaderText>
                <Typography variant="h5">
                {/* <>{new Intl.NumberFormat("en-US").format(trimmedStakingAPY)}%</> */}
                  { totalSupply ? (
                    new Intl.NumberFormat("en-US").format(totalSupply)
                  ) : (
                    <Skeleton type="text" />
                  )}
                </Typography>
              </Box>

              <Box className="metric bpo">
                <CustomHeaderText variant="h6" color="textSecondary">
                  Circulating Supply
                </CustomHeaderText>
                <Typography variant="h5">
                {circSupply?new Intl.NumberFormat("en-US").format(parseInt(circSupply)): (<Skeleton type="text" />)}
                </Typography>
              </Box>

              <Box className="metric index">
                <CustomHeaderText variant="h6" color="textSecondary">
                  Your Balance                  
                </CustomHeaderText>
                <Typography variant="h5">
                    {AaltoBalance?trim(AaltoBalance, 2):"0.00"} SUP
                </Typography>
              </Box>
              {/* <Box className="metric index">
                <CustomHeaderText variant="h6" color="textSecondary">
                  Backed Liquidity                  
                </CustomHeaderText>
                <Typography variant="h5">
                    {backedLiquidity?trim(backedLiquidity, 2):"0.00"}%
                </Typography>
              </Box> */}
            </Box>
          </Paper>
        </Box>

      <Zoom in={true}>
      <Grid container spacing={2} className="data-grid">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Paper className="aalto-card">
            <div className="card-header">
              <Typography variant="body1">$SUP Price</Typography>
              <Typography variant="h5">
                {/* appleseed-fix */}
                {marketPrice ? formatCurrency(marketPrice, 12) : <Skeleton type="text" />}
              </Typography>
            </div>             
          </Paper>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Paper className="aalto-card">
            <div className="card-header">
              <Typography variant="body1">Market Value Of Treasury Assets</Typography>
              <Typography variant="h5">
              {mvtBalance ? formatCurrency(mvtBalance, 4) : <Skeleton type="text" />}
                {/* {mvt ? formatCurrency(mvt, 2) : <Skeleton type="text" />} */}
              </Typography>
            </div>             
          </Paper>
        </Grid>

      
      </Grid>
      </Zoom>

    <Zoom in={true}>
    <Grid container spacing={2} className="data-grid">
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Paper className="aalto-card">
          <div className="card-header">
            <Typography variant="body1">BNB Liquidity Value</Typography>
            <Typography variant="h5">
              {/* appleseed-fix */}
              {blvBalance ? formatCurrency(blvBalance, 4) : <Skeleton type="text" />}
            </Typography>
          </div>             
        </Paper>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Paper className="aalto-card">
          <div className="card-header">
            <Typography variant="body1">Risk Free Value Market Value</Typography>
            <Typography variant="h5">
            {rfvBalance ? formatCurrency(rfvBalance, 4) : <Skeleton type="text" />}
              {/* {mvt ? formatCurrency(mvt, 2) : <Skeleton type="text" />} */}
            </Typography>
          </div>             
        </Paper>
      </Grid>


    </Grid>
    </Zoom>

        
      </Container>
    </div>
  );
}

export default TreasuryDashboard;
