import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { ReactElement } from "react";
import styled from "styled-components";

const Heading = styled.h1`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 90px;
  color: #fafcfc;
  margin: 0;
`;

const InfoCard = styled.div`
  display: flex;  

  padding: 32px;
  background: #171e3b;
  border: 1px solid #2f3333;
  border-radius: 4px;
`

const Count = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  margin-right: 40px;
  background: #000829;
  border-radius: 900px;
`

const Number = styled.span`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 86px;
  line-height: 90px;
  /* identical to box height, or 105% */
  letter-spacing: -0.046em;

  /* Gray / 300 */

  color: #e4ebea;
`;

const InstructionHeading = styled.div`
  margin-bottom: 28px;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;

  /* Gray / 100 */

  color: #fafcfc;
`;

const InstructionSub = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;

  color: #fff5ef;

  opacity: 0.8;
`;

const AdditionalContent = styled.div`
  padding-top: 35px;
`

const GridItem = styled(Grid)`
  width: 100%;
  max-width: 635px;
`

interface CardProps {
  number?: string;
  heading: string;
  description: string;
  additional?: ReactElement;
}

const Card = ({ number, heading, description, additional }: CardProps) => {
  return (
    <InfoCard>
      {number && (  
        <Count>
          <Number>{number}</Number>
        </Count>
      )}

      <div>
        <InstructionHeading>{heading}</InstructionHeading>
        <InstructionSub>{description}</InstructionSub>

        {additional && (
          <AdditionalContent>{additional}</AdditionalContent>
        )}
      </div>  
    </InfoCard>
  )
}

const LotteryInfo = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Heading>How To Play</Heading>
      </Grid >
      <GridItem item>
        <Card number="1" heading="Buy Tickets" description="Price for 1 ticket: $5 worth of any participating token" />
      </GridItem>
      <GridItem item>
        <Card number="2" heading="Wait for the Draw" description="New Whirlpool cycle every 24hrs" />
      </GridItem>
      <GridItem item>
        <Card number="3" heading="Check for Prizes" description="Once the round’s over, come back to the page and check to see if you’ve won!" />
      </GridItem>

      <Grid item>
        <Heading>
          FAQ
        </Heading>
      </Grid>

      <GridItem item>
        <Card 
          heading="Want to learn more?" 
          description="Check our documentation to learn more on how The Whirlpool lottery works." 
          additional={
            <Button
              variant="outlined"
              href="https://aalto-defi.notion.site/The-Whirlpool-ea209f026d294afc99ef0c97bc6d97ce"
              target="__blank"
            >
              Go to docs
            </Button>
          }
        />
      </GridItem>
    </Grid>
  );
};

export default LotteryInfo;
