interface IAddresses {
  [key: number]: { [key: string]: string };
}

const BSC_ADDRESSES = {
  AALTO_ADDRESS: "0xcE18FbBAd490D4Ff9a9475235CFC519513Cfb19a",
  BANK_ADDRESS: "0x5F6D61ADdE8f4cf9079016ADF52438EF9Dfb028E",
  TREASURY_ADDRESS: "0xE28A3c2Feb553DC3C29f2982D15f92309041f5F9",
  RFV_ADDRESS: "0xecE4e55220aCA34617E6FEAD3d54d2b24b41f2a3",
  DEAD_ADDRESS: "0x000000000000000000000000000000000000dEaD",
  PRESERVATION_ADDRESS: "0x4a95751bffFF50E713AAd9A1bc58386010A37250",
  LOTTERY_ADDRESS: "0x2ee2f54e95ce6f24dAdbDfa8221a6F763E8eEB96",

  BUSD_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  ETH_ADDRESS: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
  AALTO_BNB_LP_ADDRESS: "0x4fea7445f515b215f4284f33cb6be610d5df0c33",
  WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  WBNB_USDT_LP_ADDRESS: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
  AMES_ADDRESS: "0xb9E05B4C168B56F73940980aE6EF366354357009",
  LOCKED_ADDRESS: "0x7ee058420e5937496F5a2096f04caA7721cF70cc",
};

export const ADDRESSES: IAddresses = {
  56: {
    ...BSC_ADDRESSES,
  },
  31337: Object.assign(BSC_ADDRESSES, {
    LOTTERY_ADDRESS: "0xC6312Fd032da961C576aA7dC1d0ECC9d03E1910e",
  }),
};
