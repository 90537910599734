import type { Signer } from "@ethersproject/abstract-signer";
import type { Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ethers } from "ethers";
import { ethersToBigNumber } from "./bigNumber";
import BigNumber from "bignumber.js";

export interface UserSimpleBalance {
  tokenAddress: string;
  balanceBN: BigNumber;
  balanceUI: string;
}

export const getUserTokenBalances = async (
  user: string,
  tokenAddresses: string[],
  signer?: Signer | Provider,
): Promise<UserSimpleBalance[]> => {
  const balances: UserSimpleBalance[] = [];
  for (const address of tokenAddresses) {
    const token = new ethers.Contract(address, ["function balanceOf(address) public view returns (uint256)"], signer);
    const balance: ethers.BigNumber = await token.balanceOf(user);
    balances.push({
      tokenAddress: address,
      balanceBN: ethersToBigNumber(balance),
      balanceUI: balance.gt(ethers.constants.Zero) ? formatEther(balance).slice(0, 8) : "0.0",
    });
  }

  return balances;
};
