import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { Round, RoundId, RoundNumberInput } from "../styles";

const DrawnDate = styled.div`
  height: 28px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;
  color: #e4ebea;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const RounderHeader = ({ lotteryId, endTimeDate }) => {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Round>Round</Round>
      </Grid>
      <Grid item>
        <RoundNumberInput>
          <RoundId>{lotteryId ? lotteryId : <Skeleton></Skeleton>}</RoundId>
        </RoundNumberInput>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          paddingTop: "5px",
        }}
      >
        {endTimeDate ? <DrawnDate>Drawn: {endTimeDate}</DrawnDate> : <Skeleton variant="rect"></Skeleton>}
      </Grid>
    </Grid>
  );
};

export default RounderHeader;
