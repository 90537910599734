import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import TicketInput from "./TicketInput";

export const ScrollableContainer = styled.div`
  height: 350px;
  width: 100%;
  overflow-y: scroll;
`;

const TicketList = ({ tickets }) => {
  return (
    <ScrollableContainer>
      {tickets && (
        <Grid container direction="column" spacing={1}>
          {tickets.map(ticket => (
            <Grid item key={ticket.id}>
              <TicketInput
                ticket={ticket}
                duplicateWith={ticket.duplicateWith}
                //updateTicket={updateTicket}
                disabled={true}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </ScrollableContainer>
  );
};

export default TicketList;
