import { JsonRpcProvider } from "@ethersproject/providers";
import { Contract } from "ethers";
import axios from "axios";
import { formatEther } from "@ethersproject/units";

export async function getGaugeRewardValue(
  gaugeAddress: string,
  poolId: string,
  provider: JsonRpcProvider,
  pendingRewards: string,
) {
  try {
    const gauge = new Contract(gaugeAddress, ["function totalSupply() public view returns (uint256)"], provider);
    // get user pending gauge reward amount (Ex. Technicolor)
    // (pools total $ liquidity / gauges total supply) * users pending reward amount

    const [req, totalSupply] = await Promise.all([
      axios.get("https://dex-backend-prod.herokuapp.com/pool/base-info/" + poolId),
      gauge.totalSupply(),
    ]);

    const totalLiquidity = Number(Number(req.data.totalLiquidity).toFixed(4));
    const supply = Number(Number(formatEther(totalSupply)).toFixed(4));
    const reward = Number(Number(pendingRewards).toFixed(4));
    const value = (totalLiquidity / supply) * reward;
    return value;
  } catch (error) {
    console.error("getGaugeRewardValue failed");
    return 0;
  }
}
