import { Box, Typography, SvgIcon, Link } from "@material-ui/core";
import styled from "styled-components";
import { differenceInDays } from "date-fns";
import { ReactComponent as WaveIcon } from "../../../assets/icons/wave.svg";
import { ReactComponent as LaunchIcon } from "../../../assets/icons/launch.svg";
import { WAVE_TARGET_DATE } from "../constants";

const Label = styled(Typography)`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 42px;
  font-weight: 900;
`;

const Container = styled.div`
  display: none;
  padding: 12px 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid #2f3333;
  text-align: center;
  background: linear-gradient(180deg, #171e3b 0%, #161a67 100%);
  border-radius: 4px;
  box-shadow: 0 0 50px #0ad7cccc;
  border: 1px solid transparent;
  transition: border-color 500ms ease;

  &:last-child {
    margin-right: 0;
  }

  & svg {
    width: 26px;
    height: 26px;
  }
`;

const SubTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;

  & > svg {
    width: 12px;
    height: 12px;
  }
`

const SubTitle = styled(Typography)`
  margin: 0 6px 0 0;
  font-weight: bold;
  font-size: 16px;
  color: #D0D6D6;
  letter-spacing: -0.02em;
`

const CustomLink = styled(Link)`
  margin: 0 !important; 

  &:hover h4 {
    color: white;
  }

  &:hover > * {
    box-shadow: 0 0 60px #0ad7cc;
    border-color: #03E1D0;
  }
`

export function WaveCountdown() {
  const days = differenceInDays(WAVE_TARGET_DATE, new Date()) + 1

  return (
    <CustomLink 
      href={'https://aalto-defi.notion.site/Tidal-Wave-Rebate-Cycle-Quarterly-Bonuses-9f4f9e635f7b4d429693e1fbcd1f8818'} 
      target="_blank"
    >
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center">
          <SvgIcon color="primary" component={WaveIcon} viewBox="0 0 26 26" />
          <Label variant="h4">{days > 0 ? days : 0} days</Label>
          <SvgIcon color="primary" component={WaveIcon} viewBox="0 0 26 26" />
        </Box>
        <SubTitleContainer>
          <SubTitle as="p">
            Until Next Tidal Wave Rebate Cycle
          </SubTitle>
          <SvgIcon color="primary" component={LaunchIcon} viewBox="0 0 12 12" />
        </SubTitleContainer>
      </Container>
    </CustomLink>
  );
}
