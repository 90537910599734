import { ReactComponent as AaltoIcon } from "../../../assets/icons/logo.svg";
import { ReactComponent as AshareIcon } from "../../../assets/icons/ashare.svg";
import {
  AALTO_ADDRESS,
  AMES_ADDRESS,
  ASHARE_ADDRESS,
  GRAPE_TOKEN_ADDRESS,
  QUANTIC_TOKEN_ADDRESS,
  SEVENS_FINANCE_ADDRESS,
} from "src/data/tokens";
import { ReactComponent as AmesIcon } from "../../../assets/icons/ames.svg";
import logo777 from "../../../assets/icons/777.png";
import quanticLogo from "../../../assets/icons/quantic-logo.png";

export const tokensUI = {
  [AALTO_ADDRESS]: {
    name: "Aalto",
    icon: AaltoIcon,
    address: AALTO_ADDRESS,
    tokenKey: "AALTO",
  },
  [ASHARE_ADDRESS]: {
    name: "Ashare",
    icon: AshareIcon,
    address: ASHARE_ADDRESS,
    tokenKey: "ASHARE",
  },
  [AMES_ADDRESS]: {
    name: "Ames",
    icon: AmesIcon,
    address: AMES_ADDRESS,
    tokenKey: "AMES",
  },
  [SEVENS_FINANCE_ADDRESS]: {
    name: "SEVENS",
    icon: null,
    image: logo777,
    address: SEVENS_FINANCE_ADDRESS,
    tokenKey: "SEVENS",
  },
  [QUANTIC_TOKEN_ADDRESS]: {
    name: "Quantic",
    icon: null,
    image: quanticLogo,
    address: QUANTIC_TOKEN_ADDRESS,
    tokenKey: "QUANTIC",
  },
  [GRAPE_TOKEN_ADDRESS]: {
    name: "Grape",
    icon: null,
    image: "https://assets.coingecko.com/coins/images/22718/small/grape.png?1642474204",
    address: GRAPE_TOKEN_ADDRESS,
    tokenKey: "GRAPE",
  },
};
