import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";
import { getToken } from "src/data/tokens";
import { Grid, Typography } from "@material-ui/core";
import TicketInput from "../TicketInput";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentRound } from "../../state/selectors";
import { doRewardClaim, getUserClaimBatches } from "../../state/user-tickets";
import { useAppDispatch } from "src/store";
import { error, info } from "src/slices/MessagesSlice";
import useGetUnclaimedRewards from "../../hooks/getUnclaimedUserRewards";
import { FetchStatus } from "../../lotto.types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useWeb3Context } from "src/hooks/web3Context";
import { parseRetrievedNumber } from "../../helpers";
import WinningNumber from "../History/WinningNumber";

export const Container = styled.div`
  height: 420px;
  width: 100%;
  overflow: auto;
`;

const Round = styled.span`
  width: 70px;
  height: 30px;
  margin-right: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #fafcfc;
`;

const RoundNumberInput = styled.span`
  align-items: center;
  padding: 7px 14px;
  gap: 8px;
  width: 56px;
  height: 34px;
  background: #000829;
  border: 1px solid #2f3333;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-bottom: 5px;
`;

const RoundId = styled.span`
  width: 28px;
  height: 24px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fafcfc;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 0;
  padding-bottom: 40px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0;
`;

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: "44px",
    maxHeight: "44px",
    marginRight: "5px",
  },
  padded: {
    padding: "1rem",
  },
  margin: {
    marginBottom: "20px",
  },
  dialogPaper: {
    background: "#171E3B",
    padding: "32px 30px 40px",
    maxWidth: "445px",
    width: "100%",
  },
}));

const ClaimButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: initial;
  padding: 10px 30px;
  margin-top: 45px;
  background: #020de0;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background: #020de0;
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.12) !important;
  }
`;

const ClaimModal = ({ onClose, open, lotteryContract }) => {
  const { connected } = useWeb3Context();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [claiming, setClaiming] = useState(false);
  const [claimingComplete, setClaimingComplete] = useState(false);

  const [rewardsChecked, setRewardsChecked] = useState(false);
  const [hasRewardsToClaim, setHasRewardsToClaim] = useState(false);

  const currentRound = useSelector(selectCurrentRound);
  const { maxNumberTicketsPerBuyOrClaim } = currentRound;

  const { fetchAllRewards, unclaimedRewards, fetchStatus } = useGetUnclaimedRewards();

  useEffect(() => {
    const doFetch = async () => {
      fetchAllRewards();
    };
    if (connected && !rewardsChecked && open) {
      doFetch();
    }
  }, [fetchStatus, connected, open]);

  useEffect(() => {
    if (fetchStatus === FetchStatus.Fetched) {
      // Manage showing unclaimed rewards modal once per page load / once per lottery state change
      if (unclaimedRewards.length > 0 && !rewardsChecked) {
        setHasRewardsToClaim(true);
        setRewardsChecked(true);
      }

      if (unclaimedRewards.length === 0 && !rewardsChecked) {
        setHasRewardsToClaim(false);
        setRewardsChecked(true);
      }
    }
  }, [unclaimedRewards, rewardsChecked, fetchStatus]);

  const doClaim = async () => {
    try {
      setClaiming(true);

      for (const reward of unclaimedRewards) {
        dispatch(info(`Claiming for round: ${reward.roundId}`));
        const batchesForRound = getUserClaimBatches(reward.rewardData, reward.roundId);
        for (const batch of batchesForRound) {
          dispatch(info(`Round: ${reward.roundId} - Claiming for ticket count: ${batch.ticketIds.length}`));
          await doRewardClaim(reward.roundId, batch.ticketIds, batch.brackets, lotteryContract);
        }
      }

      onClose(true);
      setClaimingComplete(true);
    } catch (e) {
      console.log(e);
      dispatch(error("Error claiming prizes"));
      setClaimingComplete(true);
      onClose(false);
    }
  };

  const NoWinner = () => {
    return (
      <div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: "-30px",
          }}
        >
          <Grid item justifyContent="center">
            <h4
              style={{
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "38px",
                letterSpacing: "0.01em",
                color: "#F0F7F7",
              }}
            >
              Sorry, no prizes to claim..
            </h4>
          </Grid>
          <Grid item>
            <span
              style={{
                fontWeight: 300,
                fontSize: "20px",
                lineHeight: "31px",
                letterSpacing: "-0.01em",
                color: "#F0F7F7",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              Try again next round to win a variety of tokens in The Whirlpool!
            </span>
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: "30px",
          }}
        >
          <Button
            onClick={() => onClose(true)}
            variant="outlined"
            fullWidth={true}
            style={{
              border: "1px solid white",
              borderRadius: "4px",
            }}
          >
            Go Back
          </Button>
        </div>
      </div>
    );
  };

  const getTicketInfo = rewardData => {
    const tickets = rewardData.map(tx => {
      return {
        id: tx.id,
        numbers: parseRetrievedNumber(String(tx.number)),
      };
    });

    return (
      <Grid item>
        {tickets.map(ticket => {
          return <TicketInput ticket={ticket} duplicateWith={[]} disabled={true}></TicketInput>;
        })}
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      {rewardsChecked && hasRewardsToClaim && (
        <CustomDialogTitle>
          <Typography
            variant="h4"
            style={{
              fontWeight: 700,
              lineHeight: "38px",
              fontSize: "30px",
              letterSpacing: "-0.01em",
              color: "#F0F7F7",
            }}
          >
            Congratulations!
          </Typography>
        </CustomDialogTitle>
      )}
      <CustomDialogContent>
        {rewardsChecked && !hasRewardsToClaim ? (
          <NoWinner />
        ) : (
          <Container>
            {fetchStatus == FetchStatus.Fetching && (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            )}

            {rewardsChecked && hasRewardsToClaim && (
              <Grid container direction="column">
                {unclaimedRewards?.length &&
                  unclaimedRewards.map((rw, i) => {
                    return (
                      <Grid container direction="column" key={i}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            style={{
                              marginBottom: "40px",
                            }}
                          >
                            <Round>Round</Round>
                            <RoundNumberInput>
                              <RoundId>{rw.roundId}</RoundId>
                            </RoundNumberInput>
                          </Typography>
                        </Grid>
                        <Grid container direction="column">
                          {rw.tokenInfo.map(tk => {
                            const token = getToken(tk.tokenAddress);
                            return (
                              <Grid
                                container
                                key={tk.tokenAddress}
                                justifyContent="space-between"
                                alignItems="center"
                                style={{
                                  display: "flex",
                                  marginBottom: "40px",
                                }}
                              >
                                <Grid
                                  item
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <span>
                                    <img src={token.image} className={classes.logo}></img>
                                  </span>

                                  <span
                                    style={{
                                      fontSize: "24px",
                                      lineHeight: "30px",
                                      fontWeight: 500,
                                      letterSpacing: "-0.01em",
                                      color: "#F0F7F7",
                                      display: "inline-block",
                                    }}
                                  >
                                    {token.symbol}
                                  </span>
                                </Grid>

                                <Grid item justifyContent="center" alignItems="center">
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      lineHeight: "31px",
                                      fontWeight: 300,
                                      letterSpacing: "-0.01em",
                                      color: "#FAFCFC",
                                      display: "inline-block",
                                    }}
                                  >
                                    ~{tk.tokenRewardTotal}
                                  </span>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>

                        <Grid container direction="column">
                          <Grid
                            item
                            style={{
                              marginBottom: "25px",
                            }}
                          >
                            <WinningNumber winningNumber={rw.finalNumber} />
                          </Grid>
                        </Grid>

                        <Grid container direction="column">
                          {getTicketInfo(rw.rewardData)}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Container>
        )}

        {rewardsChecked && hasRewardsToClaim && (
          <ClaimButton onClick={doClaim} variant="outlined" disabled={claiming || claimingComplete}>
            {claiming ? "Claiming.." : "Claim"}
          </ClaimButton>
        )}
      </CustomDialogContent>
    </Dialog>
  );
};

export default ClaimModal;
