import { Grid, Button } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";

import ClaimModal from "./Modals/ClaimModal";

const Heading = styled.h3`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -0.046em;
  font-feature-settings: "ss02" on;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const CheckWinnings = ({ lotteryContract }) => {
  const [rewardsChecked, setRewardsChecked] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);

  const handleClaimClosed = (disable: boolean) => {
    if (disable) {
      setRewardsChecked(true);
    }

    setOpenClaimModal(false);
  };

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Heading>Are you a winner?</Heading>
      </Grid>

      <Grid item>
        <Button disabled={rewardsChecked} className="aalto-rec-button" onClick={() => setOpenClaimModal(true)}>
          Check Now
        </Button>
      </Grid>

      <ClaimModal open={openClaimModal} onClose={handleClaimClosed} lotteryContract={lotteryContract}></ClaimModal>
    </Grid>
  );
};

export default CheckWinnings;
