import { LotteryStatus } from "../types";
import { NUM_ROUNDS_TO_CHECK_FOR_REWARDS, TICKET_LIMIT_PER_REQUEST } from "./constants";
import { LotteryTicket } from "../types";
import { ethers } from "ethers";
import { getLotteryBaseInfo } from "./LotterySlice";
import moment from "moment";
import { awaitTransactionComplete } from "src/utils/utils";
import { formatBigNumber } from "src/utils/formatBalance";
import { parseRetrievedNumber } from "../helpers";

interface RoundDataAndUserTickets {
  roundId: string;
  userTickets: LotteryTicket[];
  finalNumber: string;
}

export interface LotteryRoundGraphEntity {
  id: string;
  totalUsers: string;
  totalTickets: string;
  winningTickets: string;
  status: LotteryStatus;
  finalNumber: string;
  startTime: string;
  endTime: string;
  ticketPrice: SerializedBigNumber;
}

export const doRewardClaim = async (lotteryId, ticketIds, brackets, lotteryContract) => {
  try {
    const tx = await lotteryContract.claimTickets(lotteryId, ticketIds, brackets);
    await awaitTransactionComplete(tx);
  } catch (error) {
    // error.message.code = 4001 User denied tx
    console.log(error.message);
    return false;
  }
};

export const checkWinningTickets = async (lotteryContract, user: string) => {
  // Start at current and work our way back as needed
  let currentLotteryId = await lotteryContract.currentLotteryId();
  currentLotteryId = currentLotteryId.toNumber();

  let winnersClaimWindow = await lotteryContract.winnersClaimWindow();
  winnersClaimWindow = winnersClaimWindow.toNumber();
  let tixToClaim = [];

  // count back from current
  let currentId: number = currentLotteryId;

  let toCheck = new Array<number>(NUM_ROUNDS_TO_CHECK_FOR_REWARDS);
  if (currentId < NUM_ROUNDS_TO_CHECK_FOR_REWARDS) {
    toCheck = new Array<number>(currentId);
  }

  for (const _ of toCheck) {
    if (currentId == 0) {
      return tixToClaim;
    }

    const lotto = await getLotteryBaseInfo(currentId, lotteryContract);
    if (lotto.status !== "claimable") {
      currentId--;
      continue;
    }

    // Check from the end of the lottery, not current time
    const claimWindow = moment.unix(lotto.endTime + winnersClaimWindow);
    const claimDiffDays = claimWindow.diff(moment().utc(), "days");
    if (claimDiffDays <= 0) {
      console.log("claim time up");
      continue;
    }

    // Load up every user ticket for the round
    const userTicketRawData = await fetchUserTicketsForOneRound(user, currentId, lotteryContract);
    // User was not in the round
    if (!userTicketRawData.length || !userTicketRawData[0].length) {
      currentId--;
      continue;
    }

    // Flatten the ticket data into single objects in an array (id, number, status)
    const userTickets = processRawTicketsResponse(userTicketRawData);

    const winningTicketsForPastRounds = await getWinningTickets(
      {
        roundId: String(currentId),
        userTickets,
        finalNumber: String(lotto.finalNumber),
      },
      lotteryContract,
    );

    if (winningTicketsForPastRounds?.rewardData) {
      tixToClaim.push({
        roundId: currentId,
        rewardData: winningTicketsForPastRounds.rewardData,
        tokenInfo: winningTicketsForPastRounds.tokenInfo,
        finalNumber: lotto.finalNumber,
      });
    }
    //  count back until done
    currentId--;
  }

  return tixToClaim;
};

export const processRawTicketsResponse = (ticketsResponse: Awaited<ReturnType<any>>): LotteryTicket[] => {
  const [ticketIds, ticketNumbers, ticketStatuses] = ticketsResponse;

  if (ticketIds?.length > 0) {
    return ticketIds.map((ticketId, index) => {
      return {
        id: ticketId.toString(),
        number: ticketNumbers[index].toString(),
        status: ticketStatuses[index],
      };
    });
  }
  return [];
};

export const convertUserRawTicketResponse = tix => {
  if (!tix?.length) {
    return [];
  }
  const parsedTix = processRawTicketsResponse(tix);
  const randomTicketsAsStringArray = convertUserTicketNumbersForUI(tix[1]);
  const uiTickets = Array.from({ length: Number(tix[0].length) }, (_, i) => i + 1).map(index => ({
    id: parsedTix[index - 1].id,
    numbers: randomTicketsAsStringArray[index - 1],
    duplicateWith: [],
    isComplete: true,
  }));

  return uiTickets;
};

export const convertUserTicketNumbersForUI = (ticketNumbers: number[]) => {
  return ticketNumbers.map(ticket => parseRetrievedNumber(ticket.toString()).split(""));
};

export const fetchUserTicketsForMultipleRounds = async (
  idsToCheck: string[],
  account: string,
  lotteryContract,
): Promise<{ roundId: string; userTickets: LotteryTicket[] }[]> => {
  const ticketsForMultipleRounds = [];
  for (let i = 0; i < idsToCheck.length; i += 1) {
    const roundId = idsToCheck[i];
    const ticketsForRound = await fetchUserTicketsForOneRound(account, roundId, lotteryContract);
    ticketsForMultipleRounds.push({
      roundId,
      userTickets: ticketsForRound,
    });
  }
  return ticketsForMultipleRounds;
};

const getRewardBracketByNumber = (ticketNumber: string, finalNumber: string): number => {
  // Winning numbers are evaluated right-to-left in the smart contract, so we reverse their order for validation here:
  // i.e. '1123456' should be evaluated as '6543211'
  const ticketNumAsArray = ticketNumber.split("").reverse();
  const winningNumsAsArray = finalNumber.split("").reverse();
  const matchingNumbers = [];

  // The number at index 6 in all tickets is 1 and will always match, so finish at index 5
  for (let index = 0; index < winningNumsAsArray.length - 1; index++) {
    if (ticketNumAsArray[index] !== winningNumsAsArray[index]) {
      break;
    }
    matchingNumbers.push(ticketNumAsArray[index]);
  }

  // Reward brackets refer to indexes, 0 = 1 match, 5 = 6 matches. Deduct 1 from matchingNumbers' length to get the reward bracket
  const rewardBracket = matchingNumbers.length - 1;
  return rewardBracket;
};

const fetchTokenRewardsForTickets = async (winningTickets: LotteryTicket[], lotteryContract, tokenAddress: string) => {
  try {
    // multicall this then or something
    let tokenRewardTotal = ethers.constants.Zero;
    const rewards = [];
    let idx = 0;
    for (const tic of winningTickets) {
      const value = await lotteryContract.viewTokenRewardsForTicketId(
        tic.roundId,
        tic.id,
        tic.rewardBracket,
        tokenAddress,
      );

      rewards[idx] = value;
      tokenRewardTotal = tokenRewardTotal.add(value);
      idx++;
    }
    // Deduct fees
    let rewardMinusFees = Number(formatBigNumber(tokenRewardTotal));
    rewardMinusFees -= rewardMinusFees * (1000 / 10000);

    const ticketsWithUnclaimedRewards = winningTickets.map((winningTicket, index) => {
      return { ...winningTicket, tokenAddress, tokenReward: rewards[index] };
    });
    return { ticketsWithUnclaimedRewards, tokenRewardTotal: rewardMinusFees.toFixed(6) };
  } catch (error) {
    console.error(error);
    return { ticketsWithUnclaimedRewards: null, tokenRewardTotal: null };
  }
};

/**
 * Send in the raw ticket information or ticket number jacks will be off
 * @param roundDataAndUserTickets
 * @param lotteryContract
 * @returns
 */
export const getWinningTickets = async (
  roundDataAndUserTickets: RoundDataAndUserTickets,
  lotteryContract,
): Promise<any> => {
  const { roundId, userTickets, finalNumber } = roundDataAndUserTickets;

  const ticketsWithRewardBrackets = userTickets.map(ticket => {
    return {
      roundId,
      id: ticket.id,
      number: ticket.number,
      status: ticket.status,
      rewardBracket: getRewardBracketByNumber(ticket.number, finalNumber),
    };
  });

  // A rewardBracket of -1 means no matches. 0 and above means there has been a match
  const allWinningTickets = ticketsWithRewardBrackets.filter(ticket => {
    return ticket.rewardBracket >= 0;
  });

  // If ticket.status is true, the ticket has already been claimed
  const unclaimedWinningTickets = allWinningTickets.filter(ticket => {
    return !ticket.status;
  });

  if (unclaimedWinningTickets.length > 0) {
    // Get the tokens involved in the round
    const tokens = await lotteryContract.viewLotteryTokens(roundId);

    let rewardData = [];
    const tokenInfo = [];

    // Tally up the total token reward amount for the token for all winning tickets
    for (const token of tokens) {
      const info = await fetchTokenRewardsForTickets(unclaimedWinningTickets, lotteryContract, token.tokenAddress);
      if (info.ticketsWithUnclaimedRewards.length) {
        tokenInfo.push({ tokenAddress: token.tokenAddress, tokenRewardTotal: info.tokenRewardTotal });

        info.ticketsWithUnclaimedRewards.forEach(tk => {
          if (!rewardData.find(rw => rw.id == tk.id)) {
            rewardData.push(tk);
          }
        });
      }
    }

    return { rewardData, tokenInfo };
  }

  // We make it down here if there are no unclaimed winners
  // Can be used for historical views
  if (allWinningTickets.length > 0) {
    return allWinningTickets;
  }

  return null;
};

export const viewUserInfoForLotteryId = async (
  account: string,
  lotteryId: string,
  cursor: number,
  perRequestLimit: number,
  lotteryContract,
): Promise<LotteryTicket[]> => {
  try {
    const data = await lotteryContract.viewUserInfoForLotteryId(account, lotteryId, cursor, perRequestLimit);
    return data;
  } catch (error) {
    console.error("viewUserInfoForLotteryId", error);
    return null;
  }
};

export const fetchUserTicketsForOneRound = async (account: string, lotteryId, lotteryContract) => {
  let cursor = 0;
  let numReturned = TICKET_LIMIT_PER_REQUEST;
  const ticketData = [];

  while (numReturned === TICKET_LIMIT_PER_REQUEST) {
    const response = await viewUserInfoForLotteryId(
      account,
      lotteryId,
      cursor,
      TICKET_LIMIT_PER_REQUEST,
      lotteryContract,
    );
    cursor += TICKET_LIMIT_PER_REQUEST;
    numReturned = response.length;
    ticketData.push(...response);
  }

  return ticketData;
};

/**
 * Util to create batches of claiming request data to limit request size as needed.
 * @param unclaimedRewards
 * @param roundId
 * @returns
 */
export const getUserClaimBatches = (unclaimedRewards: any[], roundId) => {
  const chunks = [];
  const base = 100;

  const totalTicketCount = unclaimedRewards.length;
  let remaining = totalTicketCount;
  let done = false;
  let startIndex = 0;
  let endIndex = totalTicketCount <= base ? totalTicketCount : base;
  while (!done) {
    remaining -= base;
    if (remaining <= 0) {
      endIndex = totalTicketCount;
      done = true;
    }

    const slice = unclaimedRewards.slice(startIndex, endIndex);
    chunks.push({
      roundId,
      ticketIds: slice.map(tk => Number(tk.id)),
      brackets: slice.map(tk => tk.rewardBracket),
    });

    startIndex += base;
    endIndex += base;
  }

  return chunks;
};

const getWinningNumbersForRound = (targetRoundId: string, lotteriesData: LotteryRoundGraphEntity[]) => {
  const targetRound = lotteriesData.find(pastLottery => pastLottery.id === targetRoundId);
  return targetRound?.finalNumber;
};

export const fetchUnclaimedUserRewards = async (
  account: string,
  // userLotteryData: LotteryUserGraphEntity,
  // lotteriesData: LotteryRoundGraphEntity[],
  userLotteryData,
  lotteriesData: any[],
  currentLotteryId: string,
  lotteryContract,
) => {
  const { rounds } = userLotteryData;

  // If there is no user round history - return an empty array
  if (rounds.length === 0) {
    return [];
  }

  // If the web3 provider account doesn't equal the userLotteryData account, return an empty array
  // - this is effectively a loading state as the user switches accounts
  if (userLotteryData.account.toLowerCase() !== account.toLowerCase()) {
    return [];
  }

  // Filter out rounds without subgraph data (i.e. >100 rounds ago)
  const roundsInRange = rounds.filter(round => {
    const lastCheckableRoundId = parseInt(currentLotteryId, 10) - 100;
    const roundId = parseInt(round.lotteryId, 10);
    return roundId >= lastCheckableRoundId;
  });

  // Filter out non-claimable rounds
  const claimableRounds = roundsInRange.filter(round => {
    return round.status.toLowerCase() === LotteryStatus.CLAIMABLE;
  });

  // Rounds with no tickets claimed OR rounds where a user has over 100 tickets, could have prizes
  const roundsWithPossibleWinnings = claimableRounds.filter(round => {
    return !round.claimed || parseInt(round.totalTickets, 10) > 100;
  });

  // Check the X  most recent rounds, where X is NUM_ROUNDS_TO_CHECK_FOR_REWARDS
  const roundsToCheck = roundsWithPossibleWinnings.slice(0, NUM_ROUNDS_TO_CHECK_FOR_REWARDS);

  if (roundsToCheck.length > 0) {
    const idsToCheck = roundsToCheck.map(round => round.lotteryId);
    const userTicketData = await fetchUserTicketsForMultipleRounds(idsToCheck, account, lotteryContract);
    const roundsWithTickets = userTicketData.filter(roundData => roundData?.userTickets?.length > 0);

    const roundDataAndWinningTickets = roundsWithTickets.map(roundData => {
      return {
        ...roundData,
        finalNumber: getWinningNumbersForRound(roundData.roundId, lotteriesData),
      };
    });

    const winningTicketsForPastRounds = await Promise.all(
      roundDataAndWinningTickets.map(roundData => getWinningTickets(roundData, lotteryContract)),
    );

    // Filter out null values (returned when no winning tickets found for past round)
    const roundsWithWinningTickets = winningTicketsForPastRounds.filter(
      winningTicketData => winningTicketData !== null,
    );

    // Filter to only rounds with unclaimed tickets
    // const roundsWithUnclaimedWinningTickets = roundsWithWinningTickets.filter(
    //   winningTicketData => winningTicketData.ticketsWithUnclaimedRewards,
    // );

    return [];
  }
  // All rounds claimed, return empty array
  return [];
};
