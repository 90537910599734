import { useEffect, useState } from "react";
import { getTokenHolders } from "src/helpers";

export const useHoldersCount = (): string => {
  const [holders, setHolders] = useState("");
  useEffect(() => {
    const getHolders = async () => {
      try {
        const { data } = await getTokenHolders();
        setHolders(data.items.filter((item: { balance: string | any[] }) => Number(item.balance) > 1).length);
      } catch {
        setHolders("1000+");
      }
    };

    getHolders();
  }, []);

  return holders;
};
