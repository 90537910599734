import { RootState } from "src/store";
import { AaltoLotteryState } from "../lotto.types";

// export const selectCurrentLotteryId = (state: RootState) => state.lottery.currentLotteryId;
// export const selectIsTransitioning = (state: RootState) => state.lottery.isTransitioning;
export const selectLotteryInfo = (state: RootState): AaltoLotteryState => state.lottery;
export const selectCurrentRound = (state: RootState) => state.lottery?.currentRound;
export const selectUserCurrentLotteryData = (state: RootState) => state.lottery?.currentRound?.userInfo;
// export const selectLotteriesData = (state: RootState) => state.lottery.lotteriesData;
// const selectMaxNumberTicketsPerBuyOrClaim = (state: RootState) => state.lottery.maxNumberTicketsPerBuyOrClaim;
