import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import { useSelector } from "react-redux";
import { BIG_ZERO } from "src/utils/bigNumber";
import { selectCurrentRound } from "../../state/selectors";
import Divider from "@material-ui/core/Divider";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import generateTicketNumbers from "../../state/generateTicketNumbers";
import { parseRetrievedNumber } from "../../helpers";
import { doTicketsBuy, getUserDataForRound, updateLotteryTokens } from "../../state/LotterySlice";
import { useWeb3Context } from "src/hooks";
import { getLotteryContract } from "src/utils/contract.utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { checkApproval, doApproval } from "src/utils/approvals";
import { parseUnits } from "@ethersproject/units";
import TicketList from "../TicketList";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import { useAppDispatch } from "src/store";
import { error, info, success } from "src/slices/MessagesSlice";
import StyledCheckbox from "../StyledCheckBox";
import { IconButton, Radio, RadioGroup } from "@material-ui/core";
import { StyledRadioButton } from "../StyledRadioButton";

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  logo: {
    maxWidth: "25px",
    maxHeight: "25px",
    marginLeft: "-10px",
    marginRight: "10px",
  },
  dialogPaper: {
    background: "#171E3B",
    padding: "32px 30px 40px",
    width: "100%",
    maxWidth: "445px",
  },
}));

const CustomDialog = styled(Dialog)`
  padding: 32px 30px 40px;
  background: #171e3b;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 0;
  margin-bottom: 40px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0;
`;

const BackIconButton = styled(IconButton)`
  padding-left: 0;
`;

const DialogHeader = styled(Typography)`
  flex: 1;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.01em;
  color: #f0f7f7;
`;

const CustomGrid = styled(Grid)`
  width: 100%;
`;

const SelectTokenHeader = styled(Typography)`
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #f0f7f7;
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 14px;
`;

const TokenRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  margin-bottom: 10px;

  & img {
    margin: 0;
  }

  & label {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }
`;

const TokenName = styled(Typography)`
  margin-left: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #848a89;
`;

const TicketsInput = styled(OutlinedInput)`
  margin-bottom: 6px;
  width: 100%;
  height: auto;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid #2f3333;
  outline: none;
  background: #000829;

  &.Mui-disabled,
  &.Mui-disabled button {
    background: rgba(255, 255, 255, 0.12) !important;
  }

  & input {
    padding: 0;
  }

  & .MuiInputAdornment-root {
    height: auto;
    max-height: 100%;
  }
`;

const MaxButton = styled(Button)`
  padding: 4px 12px !important;
  line-height: 22px;
`;

const WalletBalance = styled(Typography)`
  padding: 0 1px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-feature-settings: "ss02" on;
  color: #848a89;
`;

const BuyInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #fff5ef;
  opacity: 0.8;
`;

const BuyButton = styled(Button)`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  background: #020de0;
  border-radius: 4px;
  color: white;
`;

const ViewNumbers = styled(Button)`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letterspacing: -0.02em;
  color: #fafcfc;
  border: 1px solid white;
  border-radius: 4px;
  color: white;

  & svg {
    width: 15px;
    height: 15px;
  }

  & svg:first-child {
    margin-right: 8px;
  }

  & svg:last-child {
    margin-left: 8px;
  }

  &:disabled svg {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const BuyTicketsModal = props => {
  const { provider, chainID, address } = useWeb3Context();
  const lotteryContract = getLotteryContract(chainID, provider.getSigner());
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { onClose, open } = props;

  const [transacting, setTransacting] = useState(false);

  const [discountValue, setDiscountValue] = useState("");
  const [totalCostInToken, setTotalCostInToken] = useState("0");
  const [ticketCostBeforeDiscount, setTicketCostBeforeDiscount] = useState("0");

  const [maxPossibleTicketPurchase, setMaxPossibleTicketPurchase] = useState(BIG_ZERO);
  const [maxTicketPurchaseExceeded, setMaxTicketPurchaseExceeded] = useState(false);

  const [ticketsToBuy, setTicketsToBuy] = useState("");
  const [viewNumbers, setViewNumbers] = useState(false);

  const [selectedToken, setSelectedToken] = useState(null);
  const [userBalanceTooLow, setUserBalanceTooLow] = useState(false);
  const [userBalanceForView, setUserBalanceForView] = useState<string>("0");
  const [userBalanceOfSelectedToken, setUserBalanceOfSelectedToken] = useState<BigNumber>(null);
  const [selectedTokenPrice, setSelectedTokenPrice] = useState<BigNumber>(null);
  const [userCurrentTickets, setUserCurrentTickets] = useState(null);
  const [ticketsNumbersToBuy, setTicketNumbersToBuy] = useState(null);

  const currentRound = useSelector(selectCurrentRound);
  const { userInfo, tokens, maxNumberTicketsPerBuyOrClaim, discountDivisor, lotteryId, isLoading } = currentRound;

  const handleTokenSelected = token => {
    const matcher = t => t.tokenAddress == token.tokenAddress;
    setSelectedToken(tokens.find(matcher));
    setUserBalanceOfSelectedToken(userInfo.tickets.find(matcher).balanceBN);
    setSelectedTokenPrice(new BigNumber(token.pricePerTicket));
    setUserBalanceForView(userInfo.tickets.find(matcher).balanceUI);
    setUserCurrentTickets(userInfo.tickets.find(matcher).tickets);
  };

  const getTicketsForPurchase = () => {
    return ticketsNumbersToBuy.map(ticket => {
      const reversedTicket = [...ticket.numbers].map(num => parseInt(num, 10)).reverse();
      reversedTicket.unshift(1);
      const ticketAsNumber = parseInt(reversedTicket.join(""), 10);
      return ticketAsNumber;
    });
  };

  const limitNumberByMaxTicketsPerBuy = useCallback(
    (number: BigNumber) => {
      return number.gt(maxNumberTicketsPerBuyOrClaim) ? maxNumberTicketsPerBuyOrClaim : number;
    },
    [maxNumberTicketsPerBuyOrClaim],
  );

  const getTicketCostAfterDiscount = useCallback(
    (numberTickets: BigNumber) => {
      const totalAfterDiscount = selectedTokenPrice
        .times(numberTickets)
        .times(discountDivisor.plus(1).minus(numberTickets))
        .div(discountDivisor);
      return totalAfterDiscount;
    },
    [discountDivisor, selectedTokenPrice],
  );

  const getMaxTicketBuyWithDiscount = useCallback(
    (numberTickets: BigNumber) => {
      const costAfterDiscount = getTicketCostAfterDiscount(numberTickets);
      const costBeforeDiscount = selectedTokenPrice.times(numberTickets);
      const discountAmount = costBeforeDiscount.minus(costAfterDiscount);
      const ticketsBoughtWithDiscount = discountAmount.div(selectedTokenPrice);
      const overallTicketBuy = numberTickets.plus(ticketsBoughtWithDiscount);
      return { overallTicketBuy, ticketsBoughtWithDiscount };
    },
    [getTicketCostAfterDiscount, selectedTokenPrice],
  );

  const validateInput = useCallback(
    (inputNumber: BigNumber) => {
      const limitedNumberTickets = limitNumberByMaxTicketsPerBuy(inputNumber);
      const tokenCostAfterDiscount = getTicketCostAfterDiscount(limitedNumberTickets);

      if (tokenCostAfterDiscount.gt(userBalanceOfSelectedToken)) {
        setUserBalanceTooLow(true);
      } else if (limitedNumberTickets.eq(maxNumberTicketsPerBuyOrClaim)) {
        setMaxTicketPurchaseExceeded(true);
      } else {
        setUserBalanceTooLow(false);
        setMaxTicketPurchaseExceeded(false);
      }
    },
    [
      limitNumberByMaxTicketsPerBuy,
      getTicketCostAfterDiscount,
      maxNumberTicketsPerBuyOrClaim,
      userBalanceOfSelectedToken,
    ],
  );

  useEffect(() => {
    if (ticketsToBuy) {
      const amount = Number(ticketsToBuy);
      const randomTickets = generateTicketNumbers(amount, userInfo.totalBaseTicketsForRound);
      const randomTicketsAsStringArray = randomTickets.map(ticket => parseRetrievedNumber(ticket.toString()).split(""));
      const tickets = Array.from({ length: amount }, (_, i) => i + 1).map(index => ({
        id: index,
        numbers: randomTicketsAsStringArray[index - 1],
        duplicateWith: [],
        isComplete: true,
      }));

      setTicketNumbersToBuy(tickets);
    }
  }, [ticketsToBuy]);

  useEffect(() => {
    const getMaxPossiblePurchase = () => {
      if (!selectedToken) {
        return;
      }

      const maxBalancePurchase = userBalanceOfSelectedToken.div(selectedTokenPrice);
      const limitedMaxPurchase = limitNumberByMaxTicketsPerBuy(maxBalancePurchase);
      let maxPurchase;

      // If the users' max CAKE balance purchase is less than the contract limit - factor the discount logic into the max number of tickets they can purchase
      if (limitedMaxPurchase.lt(maxNumberTicketsPerBuyOrClaim)) {
        // Get max tickets purchasable with the users' balance, as well as using the discount to buy tickets
        const { overallTicketBuy: maxPlusDiscountTickets } = getMaxTicketBuyWithDiscount(limitedMaxPurchase);

        // Knowing how many tickets they can buy when counting the discount - plug that total in, and see how much that total will get discounted
        const { ticketsBoughtWithDiscount: secondTicketDiscountBuy } =
          getMaxTicketBuyWithDiscount(maxPlusDiscountTickets);

        // Add the additional tickets that can be bought with the discount, to the original max purchase
        maxPurchase = limitedMaxPurchase.plus(secondTicketDiscountBuy);
      } else {
        maxPurchase = limitedMaxPurchase;
      }

      if (maxPurchase.lt(1)) {
        setUserBalanceTooLow(true);
      } else {
        setUserBalanceTooLow(false);
      }

      setMaxPossibleTicketPurchase(maxPurchase);
    };

    getMaxPossiblePurchase();
  }, [
    selectedToken,
    maxNumberTicketsPerBuyOrClaim,
    selectedTokenPrice,
    userBalanceOfSelectedToken,
    limitNumberByMaxTicketsPerBuy,
    getTicketCostAfterDiscount,
    getMaxTicketBuyWithDiscount,
  ]);

  useEffect(() => {
    if (!ticketsToBuy) {
      return;
    }
    const numberOfTicketsToBuy = new BigNumber(ticketsToBuy);
    const costAfterDiscount = getTicketCostAfterDiscount(numberOfTicketsToBuy);
    const costBeforeDiscount = selectedTokenPrice.times(numberOfTicketsToBuy);
    const discountBeingApplied = costBeforeDiscount.minus(costAfterDiscount);
    setTicketCostBeforeDiscount(costBeforeDiscount.gt(0) ? getFullDisplayBalance(costBeforeDiscount) : "0");
    setTotalCostInToken(costAfterDiscount.gt(0) ? getFullDisplayBalance(costAfterDiscount) : "0");
    setDiscountValue(discountBeingApplied.gt(0) ? getFullDisplayBalance(discountBeingApplied, 18, 5) : "0");
  }, [ticketsToBuy, selectedTokenPrice, discountDivisor, getTicketCostAfterDiscount]);

  const getNumTicketsByPercentage = (percentage: number): number => {
    const percentageOfMaxTickets = maxPossibleTicketPurchase.gt(0)
      ? maxPossibleTicketPurchase.div(new BigNumber(100)).times(new BigNumber(percentage))
      : BIG_ZERO;
    return Math.floor(percentageOfMaxTickets.toNumber());
  };

  let tenPercentOfBalance = getNumTicketsByPercentage(10);
  let twentyFivePercentOfBalance = getNumTicketsByPercentage(25);
  let fiftyPercentOfBalance = getNumTicketsByPercentage(50);
  let oneHundredPercentOfBalance = getNumTicketsByPercentage(100);

  const percentageDiscount = () => {
    const percentageAsBn = new BigNumber(discountValue).div(new BigNumber(ticketCostBeforeDiscount)).times(100);
    if (percentageAsBn.isNaN() || percentageAsBn.eq(0)) {
      return 0;
    }
    return percentageAsBn.toNumber().toFixed(2);
  };

  const handleNumberButtonClick = (number: number) => {
    setTicketsToBuy(number.toFixed());
    setUserBalanceTooLow(false);
    setMaxTicketPurchaseExceeded(false);
  };

  const handleInputChange = (input: string) => {
    // Force input to integer
    const inputAsInt = parseInt(input, 10);
    const inputAsBN = new BigNumber(inputAsInt);
    const limitedNumberTickets = limitNumberByMaxTicketsPerBuy(inputAsBN);
    validateInput(inputAsBN);
    setTicketsToBuy(inputAsInt ? limitedNumberTickets.toString() : "");
  };

  const handleClose = () => {
    setSelectedToken(null);
    setTicketsToBuy("");
    setTransacting(false);
    setDiscountValue("");
    setTicketNumbersToBuy(null);
    setTotalCostInToken("0");
    onClose();
  };

  const doBuyTickets = async () => {
    try {
      const toBuy = getTicketsForPurchase();
      setTransacting(true);
      const approved = await checkApproval(
        selectedToken.tokenAddress,
        address,
        lotteryContract.address,
        provider.getSigner(),
        parseUnits(totalCostInToken),
      );
      if (!approved) {
        dispatch(info("Approving Contract.."));
        await doApproval(selectedToken.tokenAddress, lotteryContract.address, provider.getSigner());
      }

      await doTicketsBuy(lotteryId, toBuy, selectedToken.tokenAddress, lotteryContract);
      dispatch(success("Tickets Purchased!"));
      dispatch(getUserDataForRound({ lotteryId, lotteryContract }));
      dispatch(updateLotteryTokens({ lotteryId, lotteryContract }));
      handleClose();
    } catch (e) {
      dispatch(error("Error purchasing tickets"));
      handleClose();
    }
  };

  const buyDisabled = isLoading || !selectedToken;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <CustomDialogTitle id="simple-dialog-title">
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          {viewNumbers && (
            <BackIconButton aria-label="back" onClick={() => setViewNumbers(false)}>
              <ArrowBackIcon fontSize="large" />
            </BackIconButton>
          )}

          <DialogHeader variant="h4">{!viewNumbers ? "Buy Tickets" : "View Numbers"}</DialogHeader>

          {transacting ? (
            <CircularProgress />
          ) : (
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </Grid>
      </CustomDialogTitle>

      {!viewNumbers ? (
        <CustomDialogContent>
          <Grid container direction="column">
            <CustomGrid item>
              <SelectTokenHeader>Select Purchase Token</SelectTokenHeader>

              <CustomFormGroup row>
                {tokens?.length &&
                  tokens.map(token => {
                    return (
                      <TokenRadioWrapper key={token.tokenAddress}>
                        <StyledRadioButton
                          key={token.tokenAddress}
                          checked={selectedToken?.tokenAddress == token.tokenAddress}
                          onChange={() => handleTokenSelected(token)}
                          value={token}
                          id={`radio-token-${token.tokenAddress}`}
                          name={`radio-token-${token.tokenAddress}`}
                        />

                        <label htmlFor={`radio-token-${token.tokenAddress}`}>
                          <img src={token.image} alt="" className={classes.logo} />
                          <TokenName>{token.name}</TokenName>
                        </label>
                      </TokenRadioWrapper>
                    );
                  })}
              </CustomFormGroup>
            </CustomGrid>

            <Grid item>
              <TicketsInput
                type="number"
                value={ticketsToBuy}
                onChange={e => handleInputChange(e.target.value)}
                labelWidth={0}
                disabled={!selectedToken}
                endAdornment={
                  <InputAdornment position="end">
                    <MaxButton
                      variant="contained"
                      className="aalto-primary-button"
                      onClick={() => {
                        handleNumberButtonClick(oneHundredPercentOfBalance);
                      }}
                    >
                      Max
                    </MaxButton>
                  </InputAdornment>
                }
              />

              <WalletBalance>
                Wallet Balance: {selectedToken ? `${userBalanceForView || 0} ${selectedToken.name}` : ""}
              </WalletBalance>
            </Grid>

            <Grid item container direction="row" justifyContent="space-evenly" alignItems="center">
              {tenPercentOfBalance >= 1 && selectedToken && (
                <Button variant="outlined" size="small" onClick={() => handleNumberButtonClick(tenPercentOfBalance)}>
                  {tenPercentOfBalance}
                </Button>
              )}
              {twentyFivePercentOfBalance >= 1 && selectedToken && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleNumberButtonClick(twentyFivePercentOfBalance)}
                >
                  {twentyFivePercentOfBalance}
                </Button>
              )}
              {fiftyPercentOfBalance >= 1 && selectedToken && (
                <Button variant="outlined" size="small" onClick={() => handleNumberButtonClick(fiftyPercentOfBalance)}>
                  {fiftyPercentOfBalance}
                </Button>
              )}
              {oneHundredPercentOfBalance >= 1 && selectedToken && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleNumberButtonClick(oneHundredPercentOfBalance)}
                  disabled={!selectedToken}
                >
                  MAX
                </Button>
              )}
            </Grid>

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                style={{
                  fontWeight: 400,
                  lineHeight: "28px",
                  fontSize: "18px",
                  letterSpacing: "-0.01em",
                  color: "#B3BABA",
                  marginTop: "20px",
                  marginBottom: "16px",
                }}
              >
                Cost {selectedToken ? `(${selectedToken.name})` : ""}
              </Typography>

              <Typography
                style={{
                  fontWeight: 400,
                  lineHeight: "28px",
                  fontSize: "18px",
                  letterSpacing: "-0.01em",
                  color: "#B3BABA",
                  marginTop: "20px",
                  marginBottom: "16px",
                }}
              >
                {selectedTokenPrice && getFullDisplayBalance(selectedTokenPrice.times(ticketsToBuy || 0))}
              </Typography>
            </Grid>

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                style={{
                  fontWeight: 400,
                  lineHeight: "28px",
                  fontSize: "18px",
                  letterSpacing: "-0.01em",
                  color: "#B3BABA",
                  marginBottom: "16px",
                }}
              >{`${discountValue && totalCostInToken ? percentageDiscount() : 0}% Discount`}</Typography>

              <Typography
                style={{
                  fontWeight: 400,
                  lineHeight: "28px",
                  fontSize: "18px",
                  letterSpacing: "-0.01em",
                  color: "#B3BABA",
                  marginBottom: "16px",
                }}
              >
                {`${discountValue} ` + `${selectedToken ? selectedToken.name : ""}`}
              </Typography>
            </Grid>

            <Divider variant="middle" style={{ marginRight: 0, marginLeft: 0 }} />

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                style={{
                  fontWeight: 500,
                  lineHeight: "30px",
                  fontSize: "24px",
                  letterSpacing: "-0.01em",
                  color: "#B3BABA",
                  marginTop: "15px",
                  marginBottom: "40px",
                }}
              >
                You pay
              </Typography>

              <Typography
                style={{
                  fontWeight: 400,
                  lineHeight: "28px",
                  fontSize: "18px",
                  letterSpacing: "-0.01em",
                  color: "#B3BABA",
                  marginTop: "15px",
                  marginBottom: "40px",
                }}
              >{`~${totalCostInToken} ${selectedToken?.name || ""}`}</Typography>
            </Grid>

            <Grid item>
              <BuyButton variant="contained" disabled={buyDisabled} onClick={doBuyTickets}>
                Buy Now
              </BuyButton>
            </Grid>

            <Grid
              item
              justifyContent="center"
              style={{
                marginTop: "10px",
              }}
            >
              <ViewNumbers onClick={() => setViewNumbers(true)} variant="outlined" disabled={!selectedToken}>
                View Numbers
                <ArrowForwardIcon style={{ marginTop: "-2px" }} />
              </ViewNumbers>
            </Grid>

            <Grid item>
              <BuyInfo
                style={{
                  marginTop: "10px",
                }}
              >
                "Buy Now" chooses random numbers, with no duplicates among your tickets. Prices are set before each
                round starts, equal to $5 at that time. Purchases are final.
              </BuyInfo>
            </Grid>
          </Grid>
        </CustomDialogContent>
      ) : (
        <CustomDialogContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{
              marginBottom: "40px",
            }}
          >
            <Typography
              style={{
                color: "#B3BABA",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "30px",
                letterSpacing: "-0.01em",
                fontStyle: "normal",
              }}
            >
              Total Cost
            </Typography>
            <Typography
              style={{
                color: "#FAFCFC",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "28px",
                letterSpacing: "-0.01em",
              }}
            >
              ~{totalCostInToken} {selectedToken?.name}
            </Typography>
          </Grid>

          <TicketList tickets={ticketsNumbersToBuy}></TicketList>

          <Grid
            container
            direction="column"
            style={{
              marginTop: "25px",
            }}
          >
            <Grid item>
              <BuyButton variant="contained" disabled={!selectedToken} onClick={doBuyTickets}>
                Buy Now
              </BuyButton>
            </Grid>
            <Grid
              item
              style={{
                marginTop: "10px",
              }}
            >
              <ViewNumbers onClick={() => setViewNumbers(false)} variant="outlined">
                <ArrowBackIcon style={{ marginTop: "-2px" }} />
                Go Back
              </ViewNumbers>
            </Grid>
          </Grid>
        </CustomDialogContent>
      )}
    </Dialog>
  );
};

BuyTicketsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default BuyTicketsModal;
