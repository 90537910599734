export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/wkich/mama-subgraph";
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 1;

export const TOKEN_DECIMALS = 18;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    AALTO_ADDRESS: "0xcE18FbBAd490D4Ff9a9475235CFC519513Cfb19a",
    BANK_ADDRESS: "0x5F6D61ADdE8f4cf9079016ADF52438EF9Dfb028E",
    TREASURY_ADDRESS: "0xE28A3c2Feb553DC3C29f2982D15f92309041f5F9",
    RFV_ADDRESS: "0xecE4e55220aCA34617E6FEAD3d54d2b24b41f2a3",
    DEAD_ADDRESS: "0x000000000000000000000000000000000000dEaD",
    PRESERVATION_ADDRESS: "0x4a95751bffFF50E713AAd9A1bc58386010A37250",

    BUSD_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    ETH_ADDRESS: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    AALTO_BNB_LP_ADDRESS: "0x4fea7445f515b215f4284f33cb6be610d5df0c33",
    WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    WBNB_USDT_LP_ADDRESS: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    AMES_ADDRESS: "0xb9E05B4C168B56F73940980aE6EF366354357009",
    LOCKED_ADDRESS: "0x7ee058420e5937496F5a2096f04caA7721cF70cc",
  },
};

export const messages = {
  please_connect: "Please connect your wallet to the Avalanche network to use Wonderland.",
  please_connect_wallet: "Please connect your wallet.",
  try_mint_more: (value: string) =>
    `You're trying to mint more than the maximum payout available! The maximum mint payout is ${value} CROP.`,
  before_minting: "Before minting, enter a value.",
  existing_mint:
    "You have an existing mint. Minting will reset your vesting period and forfeit any pending claimable rewards. We recommend claiming rewards first or using a fresh wallet. Do you still wish to proceed?",
  before_stake: "Before staking, enter a value.",
  before_unstake: "Before un staking, enter a value.",
  tx_successfully_send: "Your transaction was successful",
  your_balance_updated: "Your balance was successfully updated",
  nothing_to_claim: "You have nothing to claim",
  something_wrong: "Something went wrong",
  switch_to_avalanche: "Switch to the Avalanche network?",
  slippage_too_small: "Slippage too small",
  slippage_too_big: "Slippage too big",
  your_balance_update_soon: "Your balance will update soon",
};
