import type { Signer } from "@ethersproject/abstract-signer";
import type { Provider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { simpleRpcProvider } from "./providers";
import { ADDRESSES } from "../data/addresses";

import lotteryAbi from "../abi/LotteryABI.json";
import erc20Abi from "../abi/IERC20.json";

export const getContract = (address: string, abi: any, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getERC20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(address, erc20Abi.abi, signer);
};

export function getLotteryContract(chainId: number, signer?: Signer | Provider) {
  return getContract(ADDRESSES[chainId].LOTTERY_ADDRESS, lotteryAbi, signer);
}
