import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

const Logo = styled.img`
  display: inline-block;
  max-width: 36px;
  max-height: 36px;
`;

const Token = styled.span`
  display: inline-block;
  padding-left: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.1722em;
  text-transform: uppercase;
  color: #e4ebea !important;
`;

const Label = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #b3baba important!;
`;

const WhiteLabel = styled(Label)`
  color: #fafcfc !important;
`;

const CustomCard = styled(Card)`
  box-shadow: none;
`;

const Header = styled(Grid)`
  padding-bottom: 18px;
`;

const TokenInfo = ({ token, isHistoricRound }) => {
  return (
    <CustomCard>
      {token && (
        <CardContent>
          <Header container alignItems="center">
            <Grid item>
              <Logo src={token.image}></Logo>
            </Grid>
            <Grid item>
              <Token>{token.name}</Token>
            </Grid>
          </Header>
          <Grid container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item xs={8}>
              <Label>Max Ticket Amount:</Label>
            </Grid>
            <Grid item xs={4}>
              <WhiteLabel align="right">{token.maxTicketAmount}</WhiteLabel>
            </Grid>
            <Grid item xs={8}>
              <Label>Tickets Bought:</Label>
            </Grid>
            <Grid item xs={4}>
              <WhiteLabel align="right">{token.ticketCount}</WhiteLabel>
            </Grid>
            {!isHistoricRound && (
              <>
                <Grid item xs={8}>
                  <Label>Tickets Available:</Label>
                </Grid>
                <Grid item xs={4} justifyContent="flex-end">
                  <WhiteLabel align="right">{token.availableForPurchase}</WhiteLabel>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      )}
    </CustomCard>
  );
};

export default TokenInfo;
