import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as aaltoABI } from "../abi/AaltoContract.json";
import { abi as bankABI } from "../abi/BankABI.json";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { setAll, getTokenPrice, getMarketPrice, getWBNBPrice } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import * as moment from "moment";

const initialState = {
  loading: false,
  loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const marketPrice = await getMarketPrice({ networkID, provider });
    const wBNBPrice = await getWBNBPrice({ networkID, provider });

    const AaltoContract = new ethers.Contract(addresses[networkID].AALTO_ADDRESS as string, aaltoABI, provider);
    const bankContract = new ethers.Contract(addresses[networkID].BANK_ADDRESS as string, bankABI, provider);
    const busdContract = new ethers.Contract(addresses[networkID].BUSD_ADDRESS as string, ierc20Abi, provider);

    const [pools, lockedInBank1, total, bankBal1, gonsPerFragment] = await Promise.all([
      bankContract.getPools(),
      bankContract.totalLockedSupply(),
      AaltoContract.totalSupply(),
      AaltoContract.lockedBalanceOf(addresses[networkID].AALTO_ADDRESS as string),
      AaltoContract.gonsPerFragment(),
    ]);

    const lockTimeOptions: any[] = [];
    pools.forEach((pool: any) => {
      lockTimeOptions.push({
        lockTimeLabel: pool.lockTimeSeconds.toNumber() / 60 / 60 / 24 + " days",
        poolId: pool.poolId.toNumber(),
        tvl: pool.amountLocked.div(gonsPerFragment),
      });
    });

    const lockedInBank = ethers.utils.formatUnits(lockedInBank1, "ether");
    const bankBal = ethers.utils.formatUnits(bankBal1, "ether");

    const burnBal1 = await AaltoContract.balanceOf(addresses[networkID].DEAD_ADDRESS as string);
    const burnBal = ethers.utils.formatUnits(burnBal1, "ether");
    const insuranceAaltoBal1 = await AaltoContract.balanceOf(addresses[networkID].PRESERVATION_ADDRESS as string);
    const insuranceAaltoBal = ethers.utils.formatUnits(insuranceAaltoBal1, "ether");
    const insuranceBusdBal = ethers.utils.formatUnits(
      await busdContract.balanceOf(addresses[networkID].PRESERVATION_ADDRESS as string),
      "ether",
    );
    const insuranceBNBBal = ethers.utils.formatUnits(
      await provider.getBalance(addresses[networkID].PRESERVATION_ADDRESS as string),
      "ether",
    );
    const insuranceBal =
      Number(insuranceBNBBal) * wBNBPrice + Number(insuranceAaltoBal) * marketPrice + Number(insuranceBusdBal);
    const circSupply = (await AaltoContract.getCirculatingSupply()) / Math.pow(10, 18);
    const nextRebase = await AaltoContract.nextRebase();

    const totalSupply = total / Math.pow(10, 18);
    const marketCap = marketPrice * circSupply;
    const wbnbContract = new ethers.Contract(addresses[networkID].WBNB_ADDRESS as string, ierc20Abi, provider);
    const blv =
      (Number(await wbnbContract.balanceOf(addresses[networkID].AALTO_BNB_LP_ADDRESS)) / Math.pow(10, 18)) * wBNBPrice;

    const mvt =
      (Number(await provider.getBalance(addresses[networkID].TREASURY_ADDRESS)) / Math.pow(10, 18)) * wBNBPrice;
    const rfv = (Number(await provider.getBalance(addresses[networkID].RFV_ADDRESS)) / Math.pow(10, 18)) * wBNBPrice;
    const treasuryBNB = Number(await provider.getBalance(addresses[networkID].TREASURY_ADDRESS));
    const lpBNB = Number(await wbnbContract.balanceOf(addresses[networkID].AALTO_BNB_LP_ADDRESS));
    const backedLiquidity = (100 * treasuryBNB) / lpBNB;

    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        marketPrice,
        marketCap,
        circSupply,
        totalSupply,
        blv,
        mvt,
        rfv,
        backedLiquidity,
        burnBal,
        insuranceBal,
        lockedInBank,
        bankBal,
      };
    }

    const currentTime = Math.floor(Date.now() / 1000);
    const stakingRebase = 0.000239;
    const ThirtyDayRate = Math.pow(1 + stakingRebase, 30 * 24 * 4) - 1;
    const totalAPY = Math.pow(1 + stakingRebase, 365 * 24 * 4) - 1;
    const dailyAPY = Math.pow(1 + stakingRebase, 24 * 4) - 1;

    const adjustedRebase = moment.unix(nextRebase.toNumber()).add(2, "hours");

    return {
      dailyAPY,
      ThirtyDayRate,
      totalAPY,
      currentTime,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply,
      blv,
      mvt,
      rfv,
      nextRebase: adjustedRebase,
      backedLiquidity,
      burnBal,
      insuranceBal,
      insuranceBusdBal,
      lockedInBank,
      bankBal,
      pools: lockTimeOptions,
    } as IAppData;
  },
);

export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice / Math.pow(10, 9);
  } catch (e) {
    marketPrice = await getTokenPrice("aalto");
  }
  return { marketPrice };
});

interface IAppData {
  readonly circSupply: number;
  readonly currentTime?: number;
  readonly marketCap: number;
  readonly marketPrice: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly totalSupply: number;
  readonly treasuryBalance?: number;
  readonly endTime?: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
