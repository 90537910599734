import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Zoom,
} from "@material-ui/core";
import { trim } from "../../helpers";
import "./stake.scss";
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';
import ProgressCountdown from "../../components/ProgressCountdown"

function Stake() {
 
  const [zoomed, setZoomed] = useState(false);
 
  const isAppLoading = useSelector(state => state.app.loading);
 
  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const AaltoBalance = useSelector(state => {
    return state.account.balances && state.account.balances.aalto;
  });

  const nextRewardYield = useSelector(state => {
    return state.app.stakingRebase;
  });
  const dailyAPY =  useSelector(state => {
    return state.app.dailyAPY;
  });

  const ThirtyDayRate =  useSelector(state => {
    return state.app.ThirtyDayRate;
  });

  const TotalAPY = useSelector(state => {
    return state.app.totalAPY;
  });

  const nextRebase = useSelector(state => {
    return state.app.nextRebase;
  });

  return (
    <>

      <div id="stake-view">
      <Grid container spacing={1}>
        <Grid item lg={6} md={12} sm={12} xs={12} className="olympus-card">
          <Zoom in={true}>
            <Paper className="aalto-card">
              <div className="card-header">
                <Typography variant="body1">APY</Typography>
                <Typography variant="h5">{TotalAPY ? trim(TotalAPY * 100,2):"0.00"}%</Typography>    
                <Typography variant="body1">Daily ROI {dailyAPY ? trim(dailyAPY * 100,2):"0.00"}%</Typography>                             
              </div>
            </Paper>
          </Zoom>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Zoom in={true}>
            <Paper className="aalto-card">
              <div className="card-header">
                <Typography variant="body1">Your Balance</Typography>
                <Typography variant="h5">{isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim("$"+AaltoBalance*marketPrice, 2):"$0.00"}</>}</Typography>    
                <Typography variant="body1">{isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim(AaltoBalance, 2):"0.00"} AALTO</>}</Typography>                                          
              </div>             
            </Paper>
          </Zoom>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Zoom in={true}>
            <Paper className="aalto-card">
              <div className="card-header">
                  <Typography variant="body1">Next Rebase:</Typography> 
                  <Typography variant="h5" style={{"font-size":"30px", "padding-top":"10px", "padding-bottom":"20px"}}>
                     <ProgressCountdown str="Rebasing" base={moment().toDate()} unixDeadline={nextRebase} description="Next Rebase" />
                  </Typography>                             
              </div>
             
            </Paper>
          </Zoom>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Zoom in={true}>
            <Paper className="aalto-card">
              <div className="card-header">
                  <Typography variant="body1">Your Earnings/Daily</Typography>
                  <Typography variant="h5">{isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim("$"+(dailyAPY)*AaltoBalance*marketPrice,2):"$0.00"}</>}</Typography>    
                  <Typography variant="body1">{isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim((dailyAPY)*AaltoBalance,2):"0.00"} AALTO</>}</Typography>                              
              </div>             
            </Paper>
          </Zoom>
        </Grid>       
      </Grid>      

        <Zoom in={true} onEntered={() => setZoomed(true)}>          
          <Paper className={`aalto-card`}>            
            <Grid container direction="column" spacing={2}> 

              <div className="staking-area">                             
                  <>
                    <div className={`stake-user-data`}>
                      <div className="data-row">
                        <Typography variant="body1">Current AALTO Price</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim("$"+marketPrice, 12)} USD</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">Next Reward Amount</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim(AaltoBalance*nextRewardYield,2):"0.00"} AALTO</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">Next Reward Amount USD</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim("$"+AaltoBalance*nextRewardYield*marketPrice,12):"$0.00"} USD</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">Next Reward Yield</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{nextRewardYield*100}%</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">ROI (30-Day Rate)</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{ThirtyDayRate?trim(ThirtyDayRate*100, 4):"0.00"}%</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">ROI (30-Day Rate) USD</Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{AaltoBalance?trim("$"+AaltoBalance*marketPrice*ThirtyDayRate, 4):"$0.00"}USD</>}
                        </Typography>
                      </div>
                    </div>
                  </>                
              </div>
            </Grid>
          </Paper>
        </Zoom>

       
      </div>
    </>
  );
}

export default Stake;
