import BigNumber from "bignumber.js";
import { ethers } from "ethers";

export type SerializedBigNumber = string;

export enum LotteryStatus {
  PENDING = "pending",
  OPEN = "open",
  CLOSE = "close",
  CLAIMABLE = "claimable",
}

export interface LotteryTicket {
  id: string;
  number: string;
  status: boolean;
  rewardBracket?: number;
  roundId?: string;
}

export interface LotteryTicketClaimData {
  ticketsWithUnclaimedRewards: LotteryTicket[];
  allWinningTickets: LotteryTicket[];
  tokenTotals: {
    tokenAddress: string;
    total: BigNumber;
  };
  roundId: string;
}

export interface LotteryRoundUserTickets {
  isLoading?: boolean;
  tickets?: LotteryTicket[];
}

interface LotteryRoundGenerics {
  isLoading?: boolean;
  lotteryId: string;
  status: LotteryStatus;
  startTime: string;
  endTime: string;
  treasuryFee: string;
  firstTicketId: string;
  lastTicketId: string;
  finalNumber: number;
  tokens?: LotteryToken[] | null;
  maxNumberTicketsPerBuyOrClaim: BigNumber;
}

export interface LotteryRound extends LotteryRoundGenerics {
  userTickets?: LotteryRoundUserTickets;
  discountDivisor: BigNumber;
  rewardPerBracket: string[];
  countWinnersPerBracket: string[];
  rewardsBreakdown: string[];
}

export interface LotteryResponse extends LotteryRoundGenerics {
  discountDivisor: SerializedBigNumber;
  rewardPerBracket: SerializedBigNumber[];
  countWinnersPerBracket: SerializedBigNumber[];
  rewardsBreakdown: SerializedBigNumber[];
  totalValue?: string;
}

export interface LotteryInfo {
  id: string;
  totalUsers: string;
  totalTickets: string;
  winningTickets: string;
  status: LotteryStatus;
  finalNumber: string;
  startTime: string;
  endTime: string;
  ticketPrice: SerializedBigNumber;
}

export interface UserRound {
  claimed: boolean;
  lotteryId: string;
  status: LotteryStatus;
  endTime: string;
  totalTickets: string;
  tickets?: LotteryTicket[];
  tokens?: LotteryToken[];
}

export interface LotteryProtocol {
  name: string;
  token: LotteryToken;
}

export interface LotteryToken {
  tokenAddress: string;
  pairAddress?: string;
  name?: string;
  image?: string;
  amountTokenCollected?: string;
  ticketCount: string;
  maxTicketAmount: string;
  maxTicketsPerUser?: number;
  pricePerTicket: string;
  rewardPerBracket: number[];
  protocol?: LotteryProtocol;
  icon?: any;
}

export interface UserLotteryTokenInfo extends LotteryToken {
  amountTokenIn?: number;
  balanceUI?: string;
  balanceBN?: ethers.BigNumber;
  ticketIds?: number[];
}

export interface LotteryUser {
  account?: string;
  totalTickets?: string;
  rounds?: UserRound[];
  lotteryTokens?: UserLotteryTokenInfo[];
}

export interface LotteryState {
  isLoading: boolean;
  currentLotteryId: string | null;
  maxNumberTicketsPerBuyOrClaim: string | null;
  isTransitioning: boolean;
  currentRound: LotteryResponse;
  lotteriesData?: LotteryRound[];
  userLotteryData?: LotteryUser;
  userCurrentRoundData?: UserCursorTicketInfo | null;
  tokens?: LotteryToken[] | null;

  user?: LotteryUser;
}

export interface UserCursorTicketInfo {
  lotteryTicketIds: number[];
  ticketNumbers: string[];
  ticketStatuses: boolean[];
  cursor: number;
}
