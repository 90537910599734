import { useMediaQuery } from "@material-ui/core";
import styled, { keyframes } from "styled-components";

interface KeyframesBackgroundProps {
  maxX: number;
  maxY: number;
  rotation: number;
}

const moveAround = ({maxX, maxY, rotation} : KeyframesBackgroundProps) => keyframes`
  0% {
    transform: rotate(0) translate(0, 0);
  }

  50% {
    transform: rotate(${rotation}deg) translate(${maxX}px, ${maxY}px);
  }

  100% {
    transform: rotate(0) translate(0, 0);
  }
`;

function getRandomInt(max: number) {
  return Math.ceil(Math.random() * max);
}

const BackgroundDefaultIcon = styled.img`
  z-index: -1;
  position: absolute;
  animation-duration: 5s;
  animation-function: ease;
  animation-iteration-count: infinite;
  animation-direction: forwards;
`;

const BackgroundRightLotteryTicket = styled(BackgroundDefaultIcon)`
  top: 70px;
  left: 12.5%;
  animation-name: ${moveAround({ maxX: getRandomInt(10), maxY: getRandomInt(5), rotation: getRandomInt(20) })}; 
`

const BackgroundLeftAalto = styled(BackgroundDefaultIcon)`
  top: 245px;
  left: 19.65%;
  animation-name: ${moveAround({ maxX: getRandomInt(-10), maxY: getRandomInt(10), rotation: getRandomInt(-15) })}; 
  animation-duration: 5s;
`

const BackgroundAshare = styled(BackgroundDefaultIcon)`
  top: 330px;
  left: 21.6%;
  animation-name: ${moveAround({ maxX: getRandomInt(5), maxY: getRandomInt(-10), rotation: getRandomInt(10) })}; 
  animation-duration: 4s;

  @media (min-width: 1440px) {
    left: 28.6%;
  }
`

const BackgroundAmes = styled(BackgroundDefaultIcon)`
  top: 86px;
  right: 22.7%;
  animation-name: ${moveAround({ maxX: getRandomInt(10), maxY: getRandomInt(-10), rotation: getRandomInt(25) })}; 
  animation-duration: 3s;
`

const BackgroundRightAalto = styled(BackgroundDefaultIcon)`
  top: 253px;
  right: 19.37%;
  animation-name: ${moveAround({ maxX: getRandomInt(5), maxY: getRandomInt(10), rotation: getRandomInt(-15) })}; 
  animation-duration: 4s;
`

const BackgroundLeftLotteryTicket = styled(BackgroundDefaultIcon)`
  top: 330px;
  right: 27.5%;
  transform: rotate(-70deg);
  animation-name: ${moveAround({ maxX: getRandomInt(-10), maxY: getRandomInt(-5), rotation: getRandomInt(20) })}; 
  animation-duration: 5s;
`

export function LotteryBackground() {
  const isMediumScreen = useMediaQuery("(min-width: 980px)");

  return isMediumScreen ? (
    <>
      <BackgroundRightLotteryTicket src="images/bg-ticket.png" alt="Lottery Ticket icon" />
      <BackgroundLeftAalto src="images/bg-left-aalto.png" alt="Aalto icon" />
      <BackgroundAshare src="images/bg-ashare.png" alt="AShare icon" />
      <BackgroundAmes src="images/bg-ames.png" alt="AMES icon" />
      <BackgroundRightAalto src="images/bg-right-aalto.png" alt="Aalto icon" />
      <BackgroundLeftLotteryTicket src="images/bg-ticket.png" alt="Lottery Ticket icon" />
    </>
  ) : null;
}
