import { Box, Grid, Typography } from "@material-ui/core";
import { useWeb3Context } from "../../hooks/web3Context";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NextDrawCard from "./components/NextDrawCard";
import { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppDispatch } from "src/store";
import BuyTicketsModal from "./components/Modals/BuyTicketsModal";
import { loadLottery } from "./state/LotterySlice";
import { getLotteryContract } from "src/utils/contract.utils";
import LotteryHeader from "./components/LotteryHeader";
import { selectLotteryInfo } from "./state/selectors";
import CheckWinnings from "./components/CheckWinning";
import History from "./components/History/History";
import LotteryInfo from "./components/LotteryInfo";
import ConnectMenu from "../../components/TopBar/ConnectMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

const LotteryPage = styled.div`
  min-height: calc(100vh - 64px);
  padding: 1rem;
`;

const LottoSection = styled.div`
  margin: 25px 0px;
`;

const ConnectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ConnectText = styled(Typography)`
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
`;

const Lottery = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { provider, chainID, connected, address } = useWeb3Context();
  const lotteryContract = getLotteryContract(chainID, provider.getSigner());

  const [openBuyTicketsModal, setOpenBuyTicketsModal] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const lotto = useSelector(selectLotteryInfo);

  const handleClickBuyTickets = () => {
    setOpenBuyTicketsModal(true);
  };

  const handleBuyTicketsClose = () => {
    setOpenBuyTicketsModal(false);
  };

  useEffect(() => {
    if (connected && !initialized) {
      dispatch(loadLottery({ lotteryContract, user: address }));
    }
  }, [connected, initialized]);

  useEffect(() => {
    if (!initialized && !lotto?.currentRound.isLoading) {
      setInitialized(true);
    }
  }, [lotto, initialized]);

  if (!connected) {
    return (
      <LotteryPage>
        <ConnectContainer>
          <ConnectText variant="h2">Please connect your wallet.</ConnectText>

          {/* ThemeSwitcher is disabled. If we enable it, theme should be inside a context provider */}
          <ConnectMenu theme={"dark"} />
        </ConnectContainer>
      </LotteryPage>
    );
  }

  return (
    <LotteryPage>
      {initialized ? (
        <div>
          <LotteryHeader onBuyTicketOpen={handleClickBuyTickets} />
          <NextDrawCard onBuyTicketOpen={handleClickBuyTickets} />
          <BuyTicketsModal open={openBuyTicketsModal} onClose={handleBuyTicketsClose} />

          <LottoSection>
            <CheckWinnings lotteryContract={lotteryContract}></CheckWinnings>
          </LottoSection>

          <LottoSection>
            <History></History>
          </LottoSection>

          <LottoSection>
            <LotteryInfo></LotteryInfo>
          </LottoSection>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </div>
      )}
    </LotteryPage>
  );
};

export default Lottery;
