import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
import { trim } from "../../helpers";
import { increaseStake } from '../../slices/StakeThunk';

export default function IncreaseStake(props) {
  const { provider, address, chainID } = useWeb3Context();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [increaseAmount, setIncreaseAmount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const walletBalance = useSelector(state => {
      return state.account.balances && state.account.balances.aalto;
  });

  const onSumbit = async () => {
    setOpen(false);
    if (increaseAmount > 0) {
      await dispatch(increaseStake({ 
        days: props.pool.poolId, 
        quantity: increaseAmount, 
        address, provider, 
        networkID: chainID 
      }));
    }
  }


  return (
    <div>
      <Button 
        variant="contained"
        className="aalto-rec-button"
        disabled={!props.pool.isUserStaked}
        onClick={handleClickOpen}>
        Increase Stake
      </Button>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Increase Stake</DialogTitle>
        <DialogContent>
          <DialogContentText>
            *Increasing stake will reset your withdraw time.
          </DialogContentText>
          <div>
           Wallet Balance: {trim(props.walletBalance, 8)}
          </div>
          <Input
            autoFocus
            autoComplete="off"
            margin="dense"
            id="name"
            label="AMOUNT"
            type="number"
            fullWidth
            required={true}
            value={increaseAmount}
            placeholder={walletBalance}
            onChange={e => setIncreaseAmount(e.target.value)}
          />
          <Button 
            onClick={e => setIncreaseAmount(walletBalance)} 
            variant="contained"
            className="aalto-primary-button">
            Max
          </Button>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose}   
            variant="contained"
            className="aalto-primary-button">
            Cancel
          </Button>
          <Button 
            onClick={onSumbit} 
            variant="contained"
            className="aalto-primary-button">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}