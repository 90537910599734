import {
  ASHARE_ADDRESS,
  GRAPE_TOKEN_ADDRESS,
  LSHARE_ADDRESS,
  TECHNICOLOR_ADDRESS,
  WBTC_ADDRESS,
  WRAPPED_AALTO_ADDRESS,
} from "src/data/tokens";

export enum TOKENS_KEYS {
  AMES = "AMES",
  ETH = "ETH",
  BUSD = "BUSD",
  WBNB = "WBNB",
  AALTO = "AALTO",
  XAALTO = "XAALTO",
  ASHARE = "ASHARE",
  SEVENS = "SEVENS",
  QUANTIC = "QUANTIC",
  GRAPE = "GRAPE",
  BTC = "BTC",
  wAALTO = "wAALTO",
  TECHNICOLOR = "TECHNICOLOR",
  LSHARE = "LSHARE",
}

const TOKENS = {
  AMES: {
    address: "0xb9E05B4C168B56F73940980aE6EF366354357009",
    symbol: "AMES",
    assetUrl: "https://raw.githubusercontent.com/ames-defi/brand-assets/main/token/ames-token-1600-round.png",
  },
  ASHARE: {
    address: ASHARE_ADDRESS,
    symbol: "ASHARE",
    assetUrl: "https://assets.coingecko.com/coins/images/23974/small/ashare.png?1645871503",
  },
  ETH: {
    address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    symbol: "ETH",
    assetUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  },
  WBNB: {
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    symbol: "WBNB",
    assetUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png",
  },
  BUSD: {
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    symbol: "BUSD",
    assetUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
  },
  AALTO: {
    address: "0xcE18FbBAd490D4Ff9a9475235CFC519513Cfb19a",
    symbol: "AALTO",
    assetUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/20155.png",
  },
  XAALTO: {
    address: "0x5F6D61ADdE8f4cf9079016ADF52438EF9Dfb028E",
    symbol: "XAALTO",
    assetUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/20155.png",
  },
  GRAPE: {
    address: GRAPE_TOKEN_ADDRESS,
    symbol: "GRAPE",
    assetUrl: "",
  },
  BTC: {
    address: WBTC_ADDRESS,
    symbol: "BTCB",
    assetUrl: "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579",
  },
  wAALTO: {
    address: WRAPPED_AALTO_ADDRESS,
    symbol: "wAALTO",
    assetUrl:
      "https://github.com/0xBriz/token-list/blob/1336217076f0fcfa734d52bea28fe5e0bfa6e549/images/waalto-token-round.png?raw=true",
  },
  TECHNICOLOR: {
    address: TECHNICOLOR_ADDRESS,
    symbol: "Technicolor",
    assetUrl:
      "https://cld.pt/dl/download/0bd3b165-9247-4f87-a355-dba9ba8be371/technicolor.PNG",
  },
  LSHARE: {
    address: LSHARE_ADDRESS,
    symbol: "LSHARE",
    assetUrl:
      "https://github.com/0xBriz/token-list/blob/89dd62ce4acba1b55d908bf08d42e7ef00ef160a/images/roaring_lion_share.png?raw=true",
  },
};

export const watchAssetInMetamask = async (assetName: TOKENS_KEYS): Promise<boolean> => {
  const { ethereum } = window as any;
  if (ethereum && ethereum.networkVersion === "56") {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: TOKENS[assetName].address,
          symbol: TOKENS[assetName].symbol,
          decimals: 18,
          image: TOKENS[assetName].assetUrl,
        },
      },
    });
  }
  return true;
};
