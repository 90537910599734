import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserTokenBalances } from "src/utils/userTokenBalances";
import { awaitTransactionComplete } from "src/utils/utils";
import {
  getRunningRewardAllocations,
  getTotalPotValue,
  getUserTicketsForRound,
  parseLotteryReponse,
  parseTokenRewardInfo,
  parseTokensInfo,
  parseUserTicketInfo,
} from "../helpers";
import { AaltoLotteryState, UserTicketInfoForLottery } from "../lotto.types";
import { LotteryStatus } from "../types";
import store from "../../../store";
import { checkWinningTickets, fetchUserTicketsForOneRound, processRawTicketsResponse } from "./user-tickets";

const InitialLottoState: AaltoLotteryState = {
  isLoading: true,
  currentRound: {
    isLoading: true,
    lotteryId: null,
    status: LotteryStatus.PENDING,
    startTime: null,
    endTime: null,
    discountDivisor: null,
    tokens: [],
    rewardsBreakdown: [],
    countWinnersPerBracket: [],
    treasuryFee: null,
    finalNumber: 0,
    maxNumberTicketsPerBuyOrClaim: null,
    userInfo: {
      user: null,
      totalTickets: 0,
      tickets: [],
      winningTickets: [],
      totalBaseTicketsForRound: [],
      isLoading: true,
    },
  },
};

const getUserLotteryInfo = async (
  lotteryId,
  uiTokens,
  lotteryContract,
  user: string,
): Promise<UserTicketInfoForLottery> => {
  const totalBaseTicketsForRound = processRawTicketsResponse(
    await fetchUserTicketsForOneRound(user, lotteryId, lotteryContract),
  );
  return {
    user,
    totalTickets: totalBaseTicketsForRound.length,
    totalBaseTicketsForRound,
    tickets: await getUserTokenInfoForLottery(
      lotteryId,
      uiTokens,
      lotteryContract,
      user,
      await getUserTicketsForRound(lotteryContract, user, lotteryId, totalBaseTicketsForRound.length),
    ),
    isLoading: false,
  };
};

export const getUserTokenInfoForLottery = async (lotteryId, uiTokens, lotteryContract, user: string, tickets) => {
  const ticketsForTokens = [];
  for (const token of uiTokens) {
    const amountTokenIn = await lotteryContract.userLotteryTokenInfo(user, lotteryId, token.tokenAddress);
    const userBalance = await getUserTokenBalances(user, [token.tokenAddress], lotteryContract.provider);
    ticketsForTokens.push(
      parseUserTicketInfo({
        amountTokenIn,
        ...userBalance[0],
        ...tickets,
      }),
    );
  }

  return ticketsForTokens;
};

export const doTicketsBuy = async (currentLotteryId, ticketNumbers, buyToken, lotteryContract) => {
  const tx = await lotteryContract.buyTickets(currentLotteryId, ticketNumbers, buyToken);
  await awaitTransactionComplete(tx);
};

export const getCurrentLottery = async lotteryContract => {
  const currentLotteryId = await lotteryContract.currentLotteryId();
  return getLotteryInfo(currentLotteryId, lotteryContract);
};

export const getCurrentLotteryId = async lotteryContract => {
  const currentLotteryId = await lotteryContract.currentLotteryId();
  return currentLotteryId.toNumber();
};

export const getLotteryBaseInfo = async (lotteryId, lotteryContract) => {
  const [lotteryInfo, maxNumberTicketsPerBuyOrClaim] = await Promise.all([
    lotteryContract.viewLottery(lotteryId),
    lotteryContract.maxNumberTicketsPerBuyOrClaim(),
  ]);

  return parseLotteryReponse(lotteryInfo, lotteryId, maxNumberTicketsPerBuyOrClaim);
};

export const getLotteryInfo = async (lotteryId, lotteryContract) => {
  const parsedLotto = await getLotteryBaseInfo(lotteryId, lotteryContract);
  const tokens = await lotteryContract.viewLotteryTokens(lotteryId);
  const uiTokens = await parseTokensInfo(tokens);

  parsedLotto.tokens = uiTokens;
  parsedLotto.totalTokenValue = await getTotalPotValue(uiTokens, parsedLotto.treasuryFee);
  parseTokenRewardInfo(uiTokens, parsedLotto.treasuryFee);

  uiTokens.forEach(tk => getRunningRewardAllocations(tk, parsedLotto.rewardsBreakdown));

  return {
    lotteryId,
    parsedLotto,
  };
};

export const updateLotteryTokens = createAsyncThunk<any, { lotteryId; lotteryContract }>(
  "lottery/updateLotteryTokens",
  async ({ lotteryId, lotteryContract }) => {
    return getLotteryInfo(lotteryId, lotteryContract);
  },
);

export const loadLottery = createAsyncThunk<AaltoLotteryState, { lotteryContract; user: string }>(
  "lottery/loadLottery",
  async ({ lotteryContract, user }) => {
    const { lotteryId, parsedLotto } = await getCurrentLottery(lotteryContract);

    parsedLotto.userInfo = await getUserLotteryInfo(lotteryId, parsedLotto.tokens, lotteryContract, user);

    const data = {
      isLoading: false,
      currentRound: parsedLotto,
    };

    return data;
  },
);

export const fetchCurrentLottery = createAsyncThunk<AaltoLotteryState, { lotteryContract }>(
  "lottery/fetchCurrentLottery",
  async ({ lotteryContract }) => {
    const { lotteryId, parsedLotto } = await getCurrentLottery(lotteryContract);

    parsedLotto.userInfo = store.getState().lottery.currentRound.userInfo;

    const data = {
      isLoading: false,
      lotteryId,
      status: LotteryStatus.OPEN,
      startTime: null,
      endTime: null,
      discountDivisor: null,
      tokens: [],
      rewardsBreakdown: [],
      countWinnersPerBracket: [],
      treasuryFee: null,
      finalNumber: 0,
      currentRound: parsedLotto,
    };

    return data;
  },
);

const userLoading = createAsyncThunk<boolean>("lottery/userLoading", async () => {
  return true;
});

export const getUserDataForRound = createAsyncThunk<any, { lotteryId; lotteryContract }>(
  "lottery/getUserDataForRound",
  async ({ lotteryId, lotteryContract }, { dispatch }) => {
    dispatch(userLoading());
    const state = store.getState().lottery.currentRound;
    return await getUserLotteryInfo(lotteryId, state.tokens, lotteryContract, state.userInfo.user);
  },
);

export const checkUserWinningTickets = createAsyncThunk<any, { lotteryContract; user: string }>(
  "lottery/checkUserWinningTickets",
  async ({ lotteryContract, user }) => {
    return await checkWinningTickets(lotteryContract, user);
  },
);

const lotterySlice = createSlice({
  name: "lottery",
  initialState: InitialLottoState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateLotteryTokens.fulfilled, (state, action) => {
        console.log(action.payload);
        state.currentRound.tokens = action.payload.parsedLotto.tokens;
        return state;
      })
      .addCase(updateLotteryTokens.rejected, (state, { error }) => {
        console.error(error);
        return state;
      })
      .addCase(getUserDataForRound.fulfilled, (state, action) => {
        state.currentRound.userInfo = action.payload;
        state.currentRound.userInfo.isLoading = false;
        state.isLoading = false;
        state.currentRound.isLoading = false;
        return state;
      })
      .addCase(getUserDataForRound.rejected, (state, { error }) => {
        console.error(error);
        return state;
      })
      .addCase(userLoading.fulfilled, (state, action) => {
        state.currentRound.userInfo.isLoading = true;
        return state;
      })
      .addCase(loadLottery.pending, (state, action) => {
        state.isLoading = true;
        state.currentRound.isLoading = true;
        return state;
      })
      .addCase(loadLottery.fulfilled, (state, action) => {
        state = action.payload;
        state.isLoading = false;
        state.currentRound.isLoading = false;
        return state;
      })
      .addCase(loadLottery.rejected, (state, { error }) => {
        console.error("Load lotto rejected");
        console.log(error);
        return state;
      })
      .addCase(fetchCurrentLottery.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(fetchCurrentLottery.rejected, (state, { error }) => {
        console.error(error);
        // state = state;
        return state;
      })
      .addCase(checkUserWinningTickets.fulfilled, (state, action) => {
        return state;
      })
      .addCase(checkUserWinningTickets.rejected, (state, { error }) => {
        console.error(error);
        // state = state;
        return state;
      });
  },
});

export const lotteryReducer = lotterySlice.reducer;
