import { Button, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserCurrentLotteryData } from "../../state/selectors";
import ViewTicketsModal from "../Modals/ViewTicketsModal";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TicketNumber = styled(Typography)`
  padding-bottom: 8px;
  color: #fafcfc !important;
  letter-spacing: -0.01em;
  text-align: right;
`;

const ViewTicketsButton = styled(Button)`
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: #03e1d0;
  text-transform: uppercase;
`;

const ViewTickets = ({ lotteryId }) => {
  const userInfo = useSelector(selectUserCurrentLotteryData);

  const [viewTickets, setViewTickets] = useState(false);

  const handleViewTickets = () => setViewTickets(true);
  const handleViewTicketsClose = () => setViewTickets(false);

  return (
    <Container>
      <TicketNumber>
        You have {userInfo.isLoading ? <Skeleton /> : userInfo?.totalTickets || 0} ticket this round
      </TicketNumber>
      <ViewTicketsButton onClick={handleViewTickets}>VIEW TICKETS</ViewTicketsButton>

      <ViewTicketsModal open={viewTickets} lotteryId={lotteryId} onClose={handleViewTicketsClose}></ViewTicketsModal>
    </Container>
  );
};

export default ViewTickets;
