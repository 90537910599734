import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

const Heading = styled(Typography)`
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const Container = styled.div`
  width: 80px;
  padding: 12px 0;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  border: 1px solid #2f3333;
  text-align: center;
  background: linear-gradient(180deg, #171e3b 0%, #161a67 100%);
  border-radius: 4px;
  box-shadow: 0 0 50px #0ad7cccc;

  &:last-child {
    margin-right: 0;
  }
`;

const Value = styled(Typography)`
  font-size: 42px;
  font-weight: 900;
  color: white;
`;

const Unit = styled(Typography)`
  font-size: 14px !important;
  font-weight: 500 !important;
  color: white !important;
  letter-spacing: 1px;
`;

interface CounterProps {
  value: number;
  unit: string;
}

function Counter({ value, unit }: CounterProps) {
  return (
    <Container>
      <Value variant="h4">{value}</Value>
      <Unit>{unit}</Unit>
    </Container>
  );
}

interface LotteryCountdownProps {
  title: string;
  seconds?: number;
  minutes?: number;
  hours?: number;
  paddingBottom?: number;
}

export function LotteryCountdown({ title, hours, minutes, seconds, paddingBottom }: LotteryCountdownProps) {
  return (
    <Box paddingBottom={paddingBottom || 0}>
      <Heading variant="h4">{title}</Heading>
      <Wrapper>
        <Counter value={hours} unit="hours" />
        <Counter value={minutes} unit="minutes" />
        <Counter value={seconds} unit="seconds" />
      </Wrapper>
    </Box>
  );
}
