export const AALTO_ADDRESS = "0xcE18FbBAd490D4Ff9a9475235CFC519513Cfb19a";
export const BUSD_ADDRESS = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const AMES_ADDRESS = "0xb9E05B4C168B56F73940980aE6EF366354357009";
export const WBNB_ADDRESS = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const ASHARE_ADDRESS = "0xFa4b16b0f63F5A6D0651592620D585D308F749A4";
export const ETH_ADDRESS = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
export const WBTC_ADDRESS = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
export const SEVENS_FINANCE_ADDRESS = "0x2600cf5213c212734AB6EaB98AD72c59B4775D80";
export const QUANTIC_TOKEN_ADDRESS = "0x7700Edc3DBb30cBB7603212E061c804220c3cA54";
export const GRAPE_TOKEN_ADDRESS = "0xDa00B89EA3D2d0b022C663793b3d47D5b02EC627";
export const BLUE_CHIP_GAUGE_ADDRESS = "0x00687547D75A2d1378984bE922e1450CcC89211E";
export const WRAPPED_AALTO_ADDRESS = "0x12b70d84DAb272Dc5A24F49bDbF6A4C4605f15da";
export const TECHNICOLOR_ADDRESS = "0xc328f8308B915D7DCD7D65d9c0e0a225abb3A95f";
export const LSHARE_ADDRESS = "0x50d8D7F7CcEA28cc1C9dDb996689294dC62569cA";

import logo777 from "../assets/icons/777.png";
import quanticLogo from "../assets/icons/quantic-logo.png";

export const BSC_TOKENS = {
  [AALTO_ADDRESS]: {
    pairAddress: "0x4Fea7445F515B215F4284F33CB6bE610d5df0C33", // AALTO/WBNB
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/20155.png",
    symbol: "AALTO",
  },
  [AMES_ADDRESS]: {
    pairAddress: "0x81722a6457e1825050B999548a35E30d9f11dB5c", // AMES/BUSD
    image: "https://assets.coingecko.com/coins/images/23973/small/ames.png?1645871205",
    symbol: "AMES",
  },
  [ASHARE_ADDRESS]: {
    pairAddress: "0x91da56569559b0629f076dE73C05696e34Ee05c1", // ASHARE/BUSD
    image: "https://assets.coingecko.com/coins/images/23974/small/ashare.png?1645871503",
    symbol: "ASHARE",
  },
  [WBNB_ADDRESS]: {
    pairAddress: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16", // WBNB/BUSD
    image: "",
    symbol: "BNB",
  },
  [BUSD_ADDRESS]: {
    pairAddress: "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00", // BUSD/USDT
    image: "",
    symbol: "BUSD",
  },
  [ETH_ADDRESS]: {
    pairAddress: "0x7213a321f1855cf1779f42c0cd85d3d95291d34c", // ETH/BUSD
    image: "",
    symbol: "ETH",
  },
  [SEVENS_FINANCE_ADDRESS]: {
    pairAddress: "0x243fd49552ce60b1c4cF7c00673aFC13cd2CB6eB", // 777/BUSD
    image: logo777,
    symbol: "777",
  },
  [QUANTIC_TOKEN_ADDRESS]: {
    pairAddress: "0x123dfBF52f0F25287c192Aa609FdC96eed8C0118",
    image: quanticLogo,
    symbol: "QUANTIC",
  },
  [GRAPE_TOKEN_ADDRESS]: {
    pairAddress: "0xF0acE063FA8ed7Be6371F5A4EAE578e3B2134516",
    image: "https://assets.coingecko.com/coins/images/22718/small/grape.png?1642474204",
    symbol: "GRAPE",
  },
  [WBTC_ADDRESS]: {
    pairAddress: "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082", // BTCB/WBNB
    image: "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579",
    symbol: "BTCB",
  },
  [WRAPPED_AALTO_ADDRESS]: {
    pairAddress:
      "0xe53896c872b39fa3254262d18157447504b211de00020000000000000000000d-0x12b70d84DAb272Dc5A24F49bDbF6A4C4605f15da-0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    image:
      "https://github.com/0xBriz/token-list/blob/1336217076f0fcfa734d52bea28fe5e0bfa6e549/images/waalto-token-round.png?raw=true",
    symbol: "wAALTO",
  },
  [LSHARE_ADDRESS]: {
    pairAddress:
      "0x950667ef678bae44ef037c721e564c365fc8303e000200000000000000000012-0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3-0x50d8D7F7CcEA28cc1C9dDb996689294dC62569cA",
    image:
      "https://github.com/0xBriz/token-list/blob/89dd62ce4acba1b55d908bf08d42e7ef00ef160a/images/roaring_lion_share.png?raw=true",
    symbol: "LSHARE",
  },
};

export const getToken = (address: string) => {
  return BSC_TOKENS[address];
};
