import { useState } from "react";
import styled from "styled-components";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Zoom,
  Container,
  useMediaQuery,
  OutlinedInput,
  InputAdornment,
  Button,
  Link,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { trim, formatCurrency } from "../../helpers";
import "./bank.scss";
import Tooltip from "@material-ui/core/Tooltip";
import { SvgIcon } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ReactComponent as BankIcon } from "../../assets/icons/bank.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as UnionIcon } from "../../assets/icons/union.svg";
import { ReactComponent as EthIcon } from "../../assets/icons/eth.svg";
import { ReactComponent as BnbIcon } from "../../assets/icons/bnb.svg";
import { ReactComponent as AmesIcon } from "../../assets/icons/ames.svg";
import { ReactComponent as AshareIcon } from "../../assets/icons/ashare.svg";
import { ReactComponent as AaltoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as Metamask } from "../../assets/metamask.svg";
import { deposit, claim, withdraw } from "../../slices/StakeThunk";
import { error } from "../../slices/MessagesSlice";
import { isPendingTxn } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import IncreaseStake from "../../components/IncreaseStake/IncreaseStake";
import MoveStake from "../../components/MoveStake/MoveStake";
import { TOKENS_KEYS, watchAssetInMetamask } from "src/helpers/metamask";
import { ReactComponent as TidalWaveCheck } from "../../assets/icons/tidal-wave-check.svg";
import { PageHeading } from "../../components/PageHeading";
import { WaveCountdown } from "./WaveCountdown";
import { isDateAfterWave } from "./is-date-after-wave";

const CustomSpan = styled.span`
  color: #03e1d0;
`;

const CustomHeaderText = styled(Box)`
  margin-top: 2px !important;
  margin-bottom: 6px !important;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
`;

const CustomHeaderTitle = styled(Typography)`
  padding-left: 12px;
`;

const CustomSpaceText = styled(Typography)`
  margin-top: 2px !important;
  margin-bottom: 6px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomRightText = styled(Typography)`
  display: inline-grid;
  text-align: end;
`;

const CustomFooterText = styled(Typography)`
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  display: flex;
  align-items: center;
`;

const TokensWrapper = styled.div`
  position: relative;
  margin-right: 8px;
  cursor: pointer;
`;

const UserLockHeader = styled(Box)`
  margin: 0 !important;
  display: flex;
  flex-direction: column-reverse !important;
  justify-content: space-between;
  padding-top: 30px !important;
  padding-left: 30px;

  @media (min-width: 1200px) {
    flex-direction: row !important;
  }
`;

const TidalWaveRebateBadge = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 30px 10px 15px;
  border-radius: 20px 0 0 20px;
  background-color: #c0f8f3;

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

const TidalWaveRebateText = styled(Typography)`
  margin-left: 8px;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 1.5;
  color: #02877d !important;
`;

const CustomLink = styled(Link)`
  align-self: end;
  margin: 0 !important;
  font-weight: 700 !important;

  &:hover {
    text-decoration: underline;
    color: #02877d !important;
  }
`;

function TreasuryDashboard() {
  const dispatch = useDispatch();

  const { provider, address, chainID } = useWeb3Context();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");

  const AaltoBalance = useSelector(state => {
    return state.account.balances && state.account.balances.aalto;
  });

  const lockedInBank = useSelector(state => {
    return state.app.lockedInBank;
  });

  const lockOptions = useSelector(state => {
    return state.app.pools;
  });

  const totalDeposit = useSelector(state => {
    return state.account.balances.totalDeposit;
  });

  const busdReward = useSelector(state => {
    return state.account.balances.busdReward;
  });

  const userLocks = useSelector(state => {
    return state.account.userLocks;
  });

  const bnbReward = useSelector(state => {
    return state.account.balances.bnbRewardAmount;
  });

  const bnbRewardValue = useSelector(state => {
    return state.account.balances.bnbRewardValue;
  });

  const ethReward = useSelector(state => {
    return state.account.balances.ethRewardAmount;
  });

  const ethRewardValue = useSelector(state => {
    return state.account.balances.ethRewardValue;
  });

  const amesReward = useSelector(state => {
    return state.account.balances.amesRewardAmount;
  });

  const amesRewardValue = useSelector(state => {
    return state.account.balances.amesRewardValue;
  });

  const ashareReward = useSelector(state => {
    return state.account.balances.userAshareReward;
  });

  const ashareRewardValue = useSelector(state => {
    return state.account.balances.ashareRewardValue;
  });

  const aaltoReward = useSelector(state => {
    return state.account.balances.userAaltoReward;
  });

  const aaltoRewardValue = useSelector(state => {
    return state.account.balances.aaltoRewardValue;
  });

  const wbtcReward = useSelector(state => {
    return state.account.balances.userWbtcReward;
  });

  const wbtcRewardValue = useSelector(state => {
    return state.account.balances.userWbtcRewardValue;
  });

  const userGrapeReward = useSelector(state => {
    return state.account.balances.userGrapeReward;
  });

  const technicolorReward = useSelector(state => {
    return state.account.balances.technicolorReward;
  });

  const technicolorRewardValue = useSelector(state => {
    return state.account.balances.technicolorRewardValue;
  });

  const lshareReward = useSelector(state => {
    return state.account.balances.userLshareReward;
  });

  const lshareRewardValue = useSelector(state => {
    return state.account.balances.userLshareRewardValue;
  });

  const wAaltoReward = useSelector(state => {
    return state.account.balances.userwAaltoReward;
  });

  const wAaltoRewardValue = useSelector(state => {
    return state.account.balances.wAaltoRewardValue;
  });

  const trimmedAaltoBalance = trim(Number(AaltoBalance), 2);

  const [AaltoAmount, setAaltoAmount] = useState(trimmedAaltoBalance);

  const isAppLoading = useSelector(state => state.app.loading);

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const onDeposit = async action => {
    // if (AaltoAmount > AaltoBalance) return dispatch(error("You cannot stake more than your Aalto balance."));
    await dispatch(deposit({ address, days: days, quantity: AaltoAmount, provider, networkID: chainID }));
  };

  const onClaim = async action => {
    if (!busdReward && !bnbReward && !amesReward) return dispatch(error("No reward claimable."));
    await dispatch(claim({ days: days, quantity: AaltoAmount, address, provider, networkID: chainID }));
  };

  const onWithdraw = async poolId => {
    await dispatch(withdraw({ poolId, quantity: null, address, provider, networkID: chainID }));
  };

  const [days, setDays] = useState(0);

  return (
    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >
        <PageHeading title="Deep Blue Bank">
          <WaveCountdown />
        </PageHeading>
        <Box className={`hero-metrics`}>
          <Zoom in={true}>
            <Grid container direction="inherit" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Paper className="aalto-card">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                    <Box className="metric-div-1">
                      <div>
                        <CustomHeaderText className="header-span">
                          <SvgIcon color="primary" component={BankIcon} viewBox="0 0 36 36" />
                          <CustomHeaderTitle variant="h6" color="textSecondary">
                            BANK LOCKED TVL
                          </CustomHeaderTitle>
                        </CustomHeaderText>
                        <Typography variant="h5">
                          {isAppLoading ? (
                            <Skeleton width="100%" />
                          ) : (
                            <CustomSpan>
                              {lockedInBank ? formatCurrency(lockedInBank * marketPrice, 2) : "$0.00"}
                            </CustomSpan>
                          )}
                        </Typography>
                        <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                          {isAppLoading ? (
                            <Skeleton width="100%" />
                          ) : (
                            <>{lockedInBank ? trim(lockedInBank, 2) : "0.00"} AALTO</>
                          )}
                        </CustomFooterText>
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="aalto-card">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                    <Box className="metric-div-1">
                      <div>
                        <CustomHeaderText className="header-span">
                          <SvgIcon color="primary" component={UnionIcon} viewBox="0 0 36 36" />
                          <CustomHeaderTitle variant="h6" color="textSecondary">
                            YOUR DEPOSITS
                          </CustomHeaderTitle>
                        </CustomHeaderText>
                        <Typography variant="h5">
                          {isAppLoading ? (
                            <Skeleton width="100%" />
                          ) : (
                            <>{totalDeposit ? formatCurrency(totalDeposit * marketPrice, 6) : "$0.00"}</>
                          )}
                        </Typography>
                        <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                          {isAppLoading ? (
                            <Skeleton width="100%" />
                          ) : (
                            <>{totalDeposit ? trim(totalDeposit, 6) : "0.00"} AALTO</>
                          )}
                        </CustomFooterText>
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="aalto-card">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                    <Box className="metric-div-1">
                      <div>
                        <Typography variant="h5" style={{ marginTop: "12px", marginBottom: "12px" }}>
                          {`Deposit`}
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{
                            color: "white",
                          }}
                        >
                          <Box className="aalto-card-action-area-inp-wrap">
                            <Typography variant="h6" color="textSecondary">
                              AALTO AMOUNT
                            </Typography>
                            <OutlinedInput
                              type="number"
                              placeholder="Amount"
                              className="aalto-card-action-input"
                              value={AaltoAmount}
                              onChange={e => setAaltoAmount(e.target.value)}
                              labelWidth={0}
                              startAdornment={
                                <>
                                  <SvgIcon
                                    color="primary"
                                    component={LogoIcon}
                                    width="22px"
                                    viewBox="0 0 22 22"
                                    style={{ marginRight: "6px" }}
                                  />
                                </>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <div
                                    onClick={() => {
                                      setAaltoAmount(AaltoBalance);
                                    }}
                                    className="stake-card-action-input-btn"
                                  >
                                    <Button variant="contained" className="aalto-primary-button">
                                      Max
                                    </Button>
                                  </div>
                                </InputAdornment>
                              }
                            />
                          </Box>
                          <Typography variant="body2" color="textSecondary" style={{ marginTop: "4px" }}>
                            Wallet Balance: {`${AaltoBalance}`} AALTO
                          </Typography>
                          <Typography variant="h4" style={{ marginTop: "12px", marginBottom: "12px" }}>
                            {`Lock period`}
                          </Typography>
                          <Grid container direction="inherit" spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(0)}
                                checked={days == 0}
                              />{" "}
                              7 days
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(1)}
                                checked={days == 1}
                              />{" "}
                              14 days
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(2)}
                                checked={days == 2}
                              />{" "}
                              30 days
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(3)}
                                checked={days == 3}
                              />{" "}
                              60 days
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(4)}
                                checked={days == 4}
                              />{" "}
                              90 days
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(5)}
                                checked={days == 5}
                              />{" "}
                              180 days
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <input
                                type="checkbox"
                                className="checkbox-round"
                                onChange={e => setDays(6)}
                                checked={days == 6}
                              />{" "}
                              365 days
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box marginTop={2}>
                          <Button
                            variant="contained"
                            className="aalto-rec-button"
                            disabled={isPendingTxn(pendingTransactions, "enter")}
                            onClick={() => {
                              onDeposit("stake");
                            }}
                          >
                            Deposit
                          </Button>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="aalto-card">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                    <Box className="metric-div-1">
                      <Typography variant="h5" style={{ marginTop: "12px", marginBottom: "12px" }}>
                        {`Pending rewards`}
                      </Typography>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.BUSD)} role="button">
                            <img
                              src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766"
                              className="reward-img"
                              style={{
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          BUSD
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{busdReward ? trim(busdReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{busdReward ? formatCurrency(busdReward, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.TECHNICOLOR)} role="button">
                            <img 
                              src="https://cld.pt/dl/download/0bd3b165-9247-4f87-a355-dba9ba8be371/technicolor.PNG" 
                              className="reward-img"
                              style={{
                              height: "30px",
                              width: "30px",
                            }}
                            />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          Beautiful Technicolor
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{technicolorReward ? trim(technicolorReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{technicolorRewardValue ? formatCurrency(technicolorRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.AALTO)} role="button">
                            <SvgIcon color="primary" component={AaltoIcon} viewBox="0 0 20 20" />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          AALTO
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{aaltoReward ? trim(aaltoReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{aaltoRewardValue ? formatCurrency(aaltoRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.wAALTO)} role="button">
                            <img
                              src="https://github.com/0xBriz/token-list/blob/1336217076f0fcfa734d52bea28fe5e0bfa6e549/images/waalto-token-round.png?raw=true"
                              className="reward-img"
                              style={{
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          wAALTO
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{wAaltoReward ? trim(wAaltoReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{wAaltoRewardValue ? formatCurrency(wAaltoRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.LSHARE)} role="button">
                            <img
                              src="https://github.com/0xBriz/token-list/blob/89dd62ce4acba1b55d908bf08d42e7ef00ef160a/images/roaring_lion_share.png?raw=true"
                              className="reward-img"
                              style={{
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          LSHARE
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{lshareReward ? trim(lshareReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{lshareRewardValue ? formatCurrency(lshareRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.WBNB)} role="button">
                            <SvgIcon color="primary" component={BnbIcon} viewBox="0 0 45 45" />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          BNB
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? <Skeleton width="100%" /> : <>{bnbReward ? trim(bnbReward, 10) : "0.00"}</>}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{bnbRewardValue ? formatCurrency(bnbRewardValue, 4) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.ETH)} role="button">
                            <SvgIcon color="primary" component={EthIcon} viewBox="0 0 33 33" />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          ETH
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? <Skeleton width="100%" /> : <>{ethReward ? trim(ethReward, 10) : "0.00"}</>}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{ethRewardValue ? formatCurrency(ethRewardValue, 4) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.BTC)} role="button">
                            <img
                              src="https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579"
                              className="reward-img"
                              style={{
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          BTCB
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{wbtcReward ? trim(wbtcReward, 10) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{wbtcRewardValue ? formatCurrency(wbtcRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>

                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.AMES)} role="button">
                            <AmesIcon />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          AMES
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{amesReward ? trim(amesReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{amesRewardValue ? formatCurrency(amesRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>
                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.ASHARE)} role="button">
                            <AshareIcon />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          ASHARE
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{ashareReward ? trim(ashareReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{ashareRewardValue ? formatCurrency(ashareRewardValue, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>

                      <CustomSpaceText>
                        <CustomHeaderText variant="body1" className="header-span">
                          <TokensWrapper onClick={() => watchAssetInMetamask(TOKENS_KEYS.GRAPE)} role="button">
                            <img
                              src="https://assets.coingecko.com/coins/images/22718/small/grape.png?1642474204"
                              className="reward-img"
                            />
                            <Metamask className="metamask-icon" />
                          </TokensWrapper>
                          GRAPE
                        </CustomHeaderText>
                        <CustomRightText>
                          <span variant="text" color="textSecondary" className="CustomRightText-upper">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{userGrapeReward ? trim(userGrapeReward, 2) : "0.00"}</>
                            )}
                          </span>
                          <span variant="text" color="textSecondary" className="CustomRightText-lower">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{userGrapeReward ? formatCurrency(userGrapeReward, 2) : "0.00"}</>
                            )}
                          </span>
                        </CustomRightText>
                      </CustomSpaceText>

                      <Box marginTop={2}>
                        <Button
                          variant="contained"
                          className="aalto-rec-button"
                          disabled={isPendingTxn(pendingTransactions, "enter")}
                          onClick={() => {
                            onClaim("stake");
                          }}
                        >
                          Claim
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h3">Your Locked Deposits</Typography>
              </Grid>
              {isAppLoading ? (
                <Skeleton width="100%" height="50px" />
              ) : userLocks != undefined ? (
                userLocks.map(userLock => (
                  <Grid item xs={12} sm={6} key={userLock.poolId}>
                    <Paper className="aalto-card" style={{ padding: 0 }}>
                      <UserLockHeader className="metric-div-1">
                        <CustomHeaderText variant="h4" className="header-span">
                          <SvgIcon color="primary" component={BankIcon} viewBox="0 0 36 36" />
                          <Box display="flex" flexDirection="column" alignItems="flex-start" marginLeft={"16px"}>
                            <span
                              style={{
                                display: "inline-block",
                                margin: "0px",
                              }}
                            >
                              {userLock.lockTimeLabel}
                            </span>

                            <Typography
                              style={{ display: "contents", fontSize: "14px", marginLeft: "4px" }}
                              color="textSecondary"
                            >
                              Lock Time
                            </Typography>
                          </Box>
                        </CustomHeaderText>

                        {["90", "180", "365"].some(days => userLock.lockTimeLabel.includes(days)) &&
                          isDateAfterWave(userLock.endTimeDate) && (
                            <CustomLink
                              href={
                                "https://aalto-defi.notion.site/Tidal-Wave-Rebate-Cycle-Quarterly-Bonuses-9f4f9e635f7b4d429693e1fbcd1f8818"
                              }
                              target="_blank"
                            >
                              <TidalWaveRebateBadge>
                                <SvgIcon color="primary" component={TidalWaveCheck} viewBox="0 0 16 16" />
                                <TidalWaveRebateText>Tidal Wave Rebate eligible</TidalWaveRebateText>
                              </TidalWaveRebateBadge>
                            </CustomLink>
                          )}
                      </UserLockHeader>

                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingX={"30px"}
                      >
                        <Box className="metric-div-2">
                          <CustomHeaderText variant="h6" color="textSecondary">
                            LOCKED TVL
                          </CustomHeaderText>
                          <Typography variant="h5">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <CustomSpan>
                                {userLock.poolTotalDeposits
                                  ? formatCurrency(userLock.poolTotalDeposits * marketPrice, 2)
                                  : "$0.00"}
                              </CustomSpan>
                            )}
                          </Typography>
                          <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <>{userLock.poolTotalDeposits ? trim(userLock.poolTotalDeposits, 2) : "0.00"} AALTO</>
                            )}
                          </CustomFooterText>
                        </Box>
                        <Box className="metric-div-2">
                          <CustomHeaderText variant="h6" color="textSecondary">
                            Claimable Rewards APR
                          </CustomHeaderText>
                          <Typography variant="h5">
                            {isAppLoading ? (
                              <Skeleton width="100%" />
                            ) : (
                              <div>
                                <CustomSpan>{userLock.apr}</CustomSpan>
                                <Tooltip title="Based on last 3 days of rewards">
                                  <SvgIcon color="primary" component={InfoIcon} viewBox="0 0 36 36" />
                                </Tooltip>
                              </div>
                            )}
                          </Typography>
                          <CustomFooterText
                            variant="text"
                            color="textSecondary"
                            className="footer-span"
                          ></CustomFooterText>
                        </Box>
                        <Box className="metric-div-1" style={{ padding: "0" }}>
                          <Typography variant="body1" color="textSecondary">
                            YOUR DEPOSIT
                          </Typography>
                        </Box>
                        <Box className="metric-div-1" style={{ padding: "0" }}>
                          <CustomSpaceText>
                            <CustomHeaderText variant="body1" className="header-span">
                              Amount Deposited
                            </CustomHeaderText>
                            <CustomRightText>
                              <span variant="text" color="textSecondary">
                                {isAppLoading ? (
                                  <Skeleton width="100%" />
                                ) : (
                                  <>{userLock.amountLocked ? trim(userLock.amountLocked, 4) : "0.00"}</>
                                )}
                              </span>
                              <span variant="text" color="textSecondary">
                                {isAppLoading ? (
                                  <Skeleton width="100%" />
                                ) : (
                                  <>
                                    {userLock.amountLocked
                                      ? formatCurrency(userLock.amountLocked * marketPrice, 2)
                                      : "0.00"}
                                  </>
                                )}
                              </span>
                            </CustomRightText>
                          </CustomSpaceText>
                        </Box>

                        <Box className="metric-div-1" style={{ padding: "0" }}>
                          <CustomSpaceText>
                            <CustomHeaderText variant="body1" className="header-span">
                              Started
                            </CustomHeaderText>
                            <CustomRightText>
                              <span variant="text" color="textSecondary">
                                {userLock.startTimeDate}
                              </span>
                            </CustomRightText>
                          </CustomSpaceText>
                        </Box>
                        <Box className="metric-div-1" style={{ padding: "0" }}>
                          <CustomSpaceText>
                            <CustomHeaderText variant="body1" className="header-span">
                              Ends
                            </CustomHeaderText>
                            <CustomRightText>
                              <span variant="text" color="textSecondary">
                                {userLock.endTimeDate}
                              </span>
                            </CustomRightText>
                          </CustomSpaceText>
                        </Box>
                        <Box marginTop={2} marginBottom={4}>
                          <IncreaseStake pool={userLock} walletBalance={AaltoBalance}></IncreaseStake>
                        </Box>
                        <Box marginTop={2} marginBottom={4}>
                          <MoveStake
                            currentPool={userLock}
                            options={lockOptions.filter(p => p.poolId > userLock.poolId)}
                          ></MoveStake>
                        </Box>
                        <Box marginTop={2} marginBottom={4}>
                          <Button
                            variant="contained"
                            className="aalto-rec-button"
                            disabled={
                              isPendingTxn(pendingTransactions, "withdraw") ||
                              !userLock.isUserStaked ||
                              !userLock.canWithdraw
                            }
                            onClick={() => {
                              onWithdraw(userLock.poolId);
                            }}
                          >
                            Withdraw
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))
              ) : (
                <></>
              )}
            </Grid>
          </Zoom>
        </Box>
      </Container>
    </div>
  );
}

export default TreasuryDashboard;
