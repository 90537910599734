import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as SupABI } from "../abi/AaltoContract.json";
import { abi as BankABI } from "../abi/BankABI.json";
import { abi as AaltoABI } from "../abi/AaltoContract.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { SEVENS_FINANCE_ADDRESS } from "src/data/tokens";

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

function alreadyApprovedToken(token: string, stakeAllowance: BigNumber, unstakeAllowance: BigNumber) {
  // set defaults
  let bigZero = BigNumber.from("0");
  let applicableAllowance = bigZero;

  // determine which allowance to check
  if (token === "sup") {
    applicableAllowance = stakeAllowance;
  } else if (token === "doges") {
    applicableAllowance = unstakeAllowance;
  }

  // check if allowance exists
  if (applicableAllowance.gt(bigZero)) return true;

  return false;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ provider, address, networkID }: IChangeApprovalAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const supContract = new ethers.Contract(addresses[networkID].AALTO_ADDRESS as string, SupABI, signer);
    let approveTx;
    let supAllowance = await supContract.allowance(address, addresses[networkID].AALTO_ADDRESS);
    // return early if approval has already happened
    if (supAllowance.gt(BigNumber.from("0"))) {
      dispatch(info("Approval completed."));
      return dispatch(
        fetchAccountSuccess({
          staking: {
            aalto: +supAllowance,
          },
        }),
      );
    }

    try {
      approveTx = await supContract.approve(
        addresses[networkID].AALTO_ADDRESS,
        ethers.utils.parseUnits("10000000000000000000000000000000000000", "gwei").toString(),
      );
      const text = "Approve Sup";
      const pendingTxnType = "approve";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
    supAllowance = await supContract.allowance(address, addresses[networkID].AALTO_ADDRESS);
    dispatch(getBalances({ address, networkID, provider }));
    return dispatch(
      fetchAccountSuccess({
        staking: {
          aalto: +supAllowance,
        },
      }),
    );
  },
);

export const deposit = createAsyncThunk(
  "stake/deposit",
  async ({ days, quantity, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const aaltoContract = new ethers.Contract(addresses[networkID].AALTO_ADDRESS as string, AaltoABI, signer);
    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: quantity.toString(),
      approved: true,
      txHash: null,
      type: null,
    };
    try {
      const deposit = ethers.utils.parseUnits(quantity.toString(), "ether");
      stakeTx = await aaltoContract.stake(days, deposit);
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "enter aalto", type: "enter" }));
      await stakeTx.wait();
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      console.log("rcpError", rpcError.data);
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.data.message));
      }
      return;
    } finally {
      if (stakeTx) {
        segmentUA(uaData);
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const claim = createAsyncThunk(
  "stake/claim",
  async ({ days, quantity, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const bankContract = new ethers.Contract(addresses[networkID].BANK_ADDRESS as string, BankABI, signer);
    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: quantity.toString(),
      approved: true,
      txHash: null,
      type: null,
    };
    try {
      stakeTx = await bankContract.claimOldAndCurrentRewards(ethers.constants.AddressZero);
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "enter aalto", type: "enter" }));
      await stakeTx.wait();
    } catch (e: unknown) {
      console.log(e);
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      console.log("rcpError", rpcError.data);
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.data.message));
      }
      return;
    } finally {
      if (stakeTx) {
        segmentUA(uaData);
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const withdraw = createAsyncThunk(
  "stake/claim",
  async ({ poolId, provider, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const aaltoContract = new ethers.Contract(addresses[networkID].AALTO_ADDRESS as string, AaltoABI, signer);
    let stakeTx;
    try {
      stakeTx = await aaltoContract.withdrawStaking(poolId);
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "enter aalto", type: "enter" }));
      await stakeTx.wait();
    } catch (e: unknown) {
      const rpcError = e as IJsonRPCError;
      console.log("rcpError", rpcError);
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.data.message));
      }
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const moveStake = createAsyncThunk(
  "stake/moveToHigh",
  async ({ currentPoolId, newPoolId, provider, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    let stakeTx;
    try {
      const aaltoContract = new ethers.Contract(
        addresses[networkID].AALTO_ADDRESS as string,
        AaltoABI,
        provider.getSigner(),
      );
      stakeTx = await aaltoContract.moveToHigherPool(currentPoolId, newPoolId);
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "Move Stake", type: "moveStake" }));
      await stakeTx.wait();
    } catch (e: unknown) {
      // uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      console.log("rcpError", rpcError.data);

      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.data.message));
      }

      return;
    } finally {
      if (stakeTx) {
        //segmentUA(uaData);
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const increaseStake = createAsyncThunk(
  "stake/increaseStake",
  async ({ action, days, quantity, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    let tx: any;
    let uaData: IUAData = {
      address: address,
      value: quantity.toString(),
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      const signer = provider.getSigner();
      const aaltoContract = new ethers.Contract(addresses[networkID].AALTO_ADDRESS as string, AaltoABI, signer);
      const amount = ethers.utils.parseUnits(quantity.toString(), "ether");
      tx = await aaltoContract.increaseStakeInCurrentPool(days, amount);
      uaData.txHash = tx.hash;
      dispatch(fetchPendingTxns({ txnHash: tx.hash, text: getStakingTypeText(action), type: action }));
      await tx.wait();
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (tx) {
        segmentUA(uaData);
        dispatch(clearPendingTxn(tx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);
