import { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import RoundRewards from "./RoundRewards";
import TokenInfo from "./TokenInfo";
import { Button } from "@material-ui/core";
import styled from "styled-components";

const ToggleButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto 0;
  color: #03e1d0;
  font-weight: bold;
  text-align: center;
  letter-spacing: normal;

  & path {
    fill: #03e1d0 !important;
  }
`;

const LotteryDetails = ({ tokens, countWinnersPerBracket, isHistoricRound }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div>
      <Collapse in={checked}>
        {tokens?.length && (
          <Grid container spacing={3} style={{ marginBottom: "20px" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "30px",
                  color: "#E4EBEA",
                  letterSpacing: "-0.01em",
                }}
              >
                Rewards for round
              </Typography>
            </Grid>

            {tokens?.length &&
              tokens.map(token => (
                <Grid item md={6} sm={12} key={token.tokenAddress}>
                  <TokenInfo token={token} key={token.tokenAddress} isHistoricRound={isHistoricRound}></TokenInfo>
                </Grid>
              ))}
          </Grid>
        )}
        {tokens?.length && (
          <RoundRewards
            tokens={tokens}
            countWinnersPerBracket={countWinnersPerBracket}
            isHistoricRound={isHistoricRound}
          ></RoundRewards>
        )}
      </Collapse>

      <ToggleButton onClick={() => setChecked(!checked)}>
        {checked ? "Hide details" : "Show details"}
        {checked ? (
          <KeyboardArrowUpIcon className="details-chevron" />
        ) : (
          <KeyboardArrowDownIcon className="details-chevron" />
        )}
      </ToggleButton>
    </div>
  );
};

export default LotteryDetails;
