import { useState, useRef } from "react";
import styled from "styled-components";
import { Ticket } from "../state/useTicketsReducer";
import Grid from "@material-ui/core/Grid";

const InputsContainer = styled.div<{ focused: boolean; isDuplicate: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #2f3333;
  background-color: #000829;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 40px;
  margin-bottom: 40px;
`;

const DigitInput = styled.input`
  border: none;
  height: 32px;
  padding: 0 12px;
  font-size: 16px;
  flex: 1;
  width: 16px;
  text-align: center;
  min-width: 0;
  background-color: transparent;
  caret-color: #7a6faa;

  &::placeholder {
    text-align: center;
  }

  &:placeholder-shown {
    text-align: left;
  }

  &:focus {
    outline: none;
  }

  color: #fafcfc;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  -moz-appearance: textfield; /* Firefox */
`;

const TicketNumber = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1722em;
  text-transform: uppercase;
  font-feature-settings: "ss02" on;
  color: #b3baba;
  margin-bottom: 8px;
`;

const getIdLabel = (id: number): string => {
  if (id < 10) return `#00${id}`;
  if (id < 100) return `#0${id}`;
  return `#${id}`;
};

const TicketContainer: React.FC<{
  ticket: Ticket;
  duplicateWith: number[];
  //updateTicket: UpdateTicketAction
  disabled: boolean;
}> = ({ ticket, disabled }) => {
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const digit1 = useRef<HTMLInputElement>(null);
  const digit2 = useRef<HTMLInputElement>(null);
  const digit3 = useRef<HTMLInputElement>(null);
  const digit4 = useRef<HTMLInputElement>(null);
  const digit5 = useRef<HTMLInputElement>(null);
  const digit6 = useRef<HTMLInputElement>(null);

  const digitRefs = [digit1, digit2, digit3, digit4, digit5, digit6];

  const scrollInputIntoView = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const onPasteHandler = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pasteContent = e.clipboardData.getData("Text");
    if (pasteContent.length <= 6 && /^\d+$/.test(pasteContent)) {
      // const filler = Array(6 - pasteContent.length).fill("");
      // updateTicket(ticket.id, [...pasteContent.split(''), ...filler])
    }
  };

  const onFocusHandler = () => {
    scrollInputIntoView();
    setFocused(true);
  };

  const onBlurHandler = () => {
    setFocused(false);
  };

  const onChangeHandler = (event: React.KeyboardEvent, digitId: number) => {
    const currentKey = parseInt(event.key, 10);

    if (["e", "E", ".", ",", "-", "Unidentified"].includes(event.key)) {
      event.preventDefault();
      return;
    }

    // Handling numeric inputs
    if (currentKey >= 0 && currentKey <= 9) {
      event.preventDefault();
      const newNumbers = [...ticket.numbers];
      newNumbers[digitId] = `${currentKey}`;
      //updateTicket(ticket.id, newNumbers)
      const nextDigitId = digitId + 1;
      // if we're not on the last digit - auto-tab
      const nextInput = digitRefs[nextDigitId];
      if (nextDigitId !== 6 && nextInput.current) {
        nextInput.current.focus();
      }
    }

    if (event.key === "Backspace") {
      event.preventDefault();
      // If some number is there - delete the number
      if (ticket.numbers[digitId]) {
        const newNumbers = [...ticket.numbers];
        newNumbers[digitId] = "";
        //updateTicket(ticket.id, newNumbers)
      } else {
        // if the cell is empty and user presses backspace - remove previous
        const prevDigitId = digitId - 1;
        const nextInput = digitRefs[prevDigitId];
        // prevent focusing on non-existent input
        if (prevDigitId !== -1 && nextInput.current) {
          nextInput.current.focus();
          const newNumbers = [...ticket.numbers];
          newNumbers[prevDigitId] = "";
          // updateTicket(ticket.id, newNumbers)
        }
      }
    }

    if (event.key === "Delete") {
      event.preventDefault();
      if (ticket.numbers[digitId]) {
        const newNumbers = [...ticket.numbers];
        newNumbers[digitId] = "";
        // updateTicket(ticket.id, newNumbers)
      } else {
        // if the cell is empty and user presses delete - remove next
        const nextDigitId = digitId + 1;
        const nextInput = digitRefs[nextDigitId];
        // prevent focusing on non-existent input
        if (nextDigitId !== 6 && nextInput.current) {
          nextInput.current.focus();
          const newNumbers = [...ticket.numbers];
          newNumbers[nextDigitId] = "";
          // updateTicket(ticket.id, newNumbers)
        }
      }
    }

    if (event.key === "ArrowLeft") {
      event.preventDefault();
      const prevDigitId = digitId - 1;
      const nextInput = digitRefs[prevDigitId];
      // prevent focusing on non-existent input
      if (prevDigitId !== -1 && nextInput.current) {
        nextInput.current.focus();
      }
    }

    if (event.key === "ArrowRight") {
      event.preventDefault();
      const nextDigitId = digitId + 1;
      const nextInput = digitRefs[nextDigitId];
      // prevent focusing on non-existent input
      if (nextDigitId !== 6 && nextInput.current) {
        nextInput.current.focus();
      }
    }
  };

  return (
    <>
      <Grid justifyContent="space-between">
        <TicketNumber>TICKET {getIdLabel(ticket.id)}</TicketNumber>
      </Grid>
      <InputsContainer ref={containerRef} onClick={scrollInputIntoView} focused={focused} isDuplicate={false}>
        <DigitInput
          ref={digit1}
          type="number"
          value={ticket.numbers[0]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 0)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit2}
          type="number"
          value={ticket.numbers[1]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 1)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit3}
          type="number"
          value={ticket.numbers[2]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 2)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit4}
          type="number"
          value={ticket.numbers[3]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 3)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit5}
          type="number"
          value={ticket.numbers[4]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 4)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit6}
          type="number"
          value={ticket.numbers[5]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 5)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
      </InputsContainer>
    </>
  );
};

export default TicketContainer;
