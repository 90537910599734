import styled from "styled-components";

export const ScrollableContainer = styled.div`
  height: 350px;
  width: 310px;
  overflow-y: scroll;
  padding: 7px;
`;

export const Round = styled.span`
  width: 70px;
  height: 30px;
  margin-right: 5px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;
  color: #fafcfc;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const RoundNumberInput = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 56px;
  height: 44px;
  background: #000829;
  border: 1px solid #2f3333;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const RoundId = styled.span`
  width: 28px;
  height: 24px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fafcfc;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
