import { useSelector } from "react-redux";
import styled from "styled-components";
import { useEffect, useState } from "react";
import "./calculator.scss";
import { Grid, InputAdornment, OutlinedInput, Zoom, Paper, Box, Typography, Button, Link } from "@material-ui/core";
import { formatCurrency, trim } from "../../helpers";
import { Skeleton } from "@material-ui/lab";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as WalletIcon } from "../../assets/icons/wallet.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { PageHeading } from "../../components/PageHeading";

const CustomHeaderText = styled(Box)`
  margin-top: 2px !important;
  margin-bottom: 6px !important;
  display: flex;
  align-items: center;
`;

const CustomHeaderTitle = styled(Typography)`
  padding-left: 12px;
`;

const CustomFooterText = styled(Typography)`
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  display: flex;
  align-items: center;
`;

function Calculator() {
  const isAppLoading = useSelector(state => state.app.loading);

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const TotalAPY = useSelector(state => {
    return state.app.totalAPY;
  });

  const dailyAPY = useSelector(state => {
    return 0.023;
  });

  const AaltoBalance = useSelector(state => {
    return state.account.balances && state.account.balances.aalto;
  });

  const trimmedTotalAPY = trim(TotalAPY * 100, 1);
  const trimmedAaltoBalance = trim(Number(AaltoBalance), 2);
  const trimeMarketPrice = trim(marketPrice, 12);
  const [AaltoAmount, setAaltoAmount] = useState(trimmedAaltoBalance);
  const [rewardYield, setRewardYield] = useState(trimmedTotalAPY);
  const [priceAtPurchase, setPriceAtPurchase] = useState(trimeMarketPrice);
  const [futureMarketPrice, setFutureMarketPrice] = useState(trimeMarketPrice);
  const [days, setDays] = useState(30);

  const [rewardsEstimation, setRewardsEstimation] = useState("0");
  const [potentialReturn, setPotentialReturn] = useState("0");

  const calcInitialInvestment = () => {
    const _Aalto = parseFloat(AaltoAmount) || 0;
    const price = parseFloat(priceAtPurchase) || 0;
    const amount = _Aalto * price;
    return trim(amount, 12);
  };

  const calcCurrentWealth = () => {
    const _Aalto = Number(AaltoAmount) || 0;
    const price = parseFloat(trimeMarketPrice);
    const amount = _Aalto * price;
    return trim(amount, 12);
  };

  const [initialInvestment, setInitialInvestment] = useState(calcInitialInvestment());

  useEffect(() => {
    const newInitialInvestment = calcInitialInvestment();
    setInitialInvestment(newInitialInvestment);
  }, [AaltoAmount, priceAtPurchase]);

  const calcNewBalance = () => {
    let value = parseFloat(rewardYield) / 100;
    value = Math.pow(value, 1 / (365 * 8)) - 1 || 0;
    let balance = Number(AaltoAmount);
    for (let i = 0; i < days; i++) {
      balance += balance * dailyAPY;
    }
    return balance;
  };

  useEffect(() => {
    const newBalance = calcNewBalance();
    setRewardsEstimation(trim(newBalance, 6));
    const newPotentialReturn = newBalance * (parseFloat(futureMarketPrice) || 0);
    setPotentialReturn(trim(newPotentialReturn, 2));
  }, [days, rewardYield, futureMarketPrice, AaltoAmount]);

  return (
    <div className="calculator-view">
      <PageHeading title="Calculator"/>
      <Zoom in={true}>
        <Grid container direction="inherit" spacing={2}>
          <Grid item xs={12} sm={8}>
            <Paper className="aalto-card calculator-card">
              <Grid className="calculator-card-grid" container direction="column" spacing={2}>
                <Grid item>
                  <Box className="calculator-card-header">
                    <Typography variant="h5">Estimate your returns</Typography>
                  </Box>
                </Grid>
                <Box className="calculator-card-area">
                  <Box>
                    <Box className="calculator-card-action-area">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <Box className="calculator-card-action-area-inp-wrap">
                            <Typography
                              variant="h6"
                              style={{
                                color: "white",
                              }}
                            >
                              AMOUNT
                            </Typography>
                            <OutlinedInput
                              type="number"
                              placeholder="Amount"
                              className="calculator-card-action-input"
                              value={AaltoAmount}
                              onChange={e => setAaltoAmount(e.target.value)}
                              labelWidth={0}
                              startAdornment={
                                <>
                                  <SvgIcon color="primary" component={LogoIcon} width="22px" viewBox="0 0 22 22" />
                                </>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <div
                                    onClick={() => setAaltoAmount(trimmedAaltoBalance)}
                                    className="stake-card-action-input-btn"
                                  >
                                    <Button variant="contained" className="aalto-primary-button">
                                      Max
                                    </Button>
                                  </div>
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box className="calculator-card-action-area-inp-wrap">
                            <Typography
                              variant="h6"
                              style={{
                                color: "white",
                              }}
                            >
                              PRICE
                            </Typography>
                            <OutlinedInput
                              type="number"
                              placeholder="Amount"
                              className="calculator-card-action-input"
                              value={futureMarketPrice}
                              onChange={e => setFutureMarketPrice(e.target.value)}
                              labelWidth={0}
                              startAdornment={<Typography color="secondary">$</Typography>}
                              endAdornment={
                                <InputAdornment position="end">
                                  <div
                                    onClick={() => setFutureMarketPrice(trimeMarketPrice)}
                                    className="stake-card-action-input-btn"
                                  >
                                    <Button variant="contained" className="aalto-primary-button">
                                      Current
                                    </Button>
                                  </div>
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box className="calculator-card-action-area-inp-wrap">
                            <Typography
                              variant="h6"
                              style={{
                                color: "white",
                              }}
                            >
                              DAYS
                            </Typography>
                            <OutlinedInput
                              type="number"
                              placeholder="Amount"
                              className="calculator-card-action-input"
                              value={days}
                              labelWidth={0}
                              onChange={e => setDays(e.target.value)}
                              endAdornment={<Typography color="secondary">Days</Typography>}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box className="calculator-card-action-area-inp-wrap">
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 7 ? `active` : ``}`}
                              onClick={e => setDays(7)}
                            >
                              1 week
                            </Button>
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 14 ? `active` : ``}`}
                              onClick={e => setDays(14)}
                            >
                              2 weeks
                            </Button>
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 30 ? `active` : ``}`}
                              onClick={e => setDays(30)}
                            >
                              1 month
                            </Button>
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 60 ? `active` : ``}`}
                              onClick={e => setDays(60)}
                            >
                              2 months
                            </Button>
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 90 ? `active` : ``}`}
                              onClick={e => setDays(90)}
                            >
                              3 months
                            </Button>
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 180 ? `active` : ``}`}
                              onClick={e => setDays(180)}
                            >
                              6 months
                            </Button>
                            <Button
                              variant="contained"
                              className={`aalto-transparent-button ${days == 365 ? `active` : ``}`}
                              onClick={e => setDays(365)}
                            >
                              1 year
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                <Grid item>
                  <Box className="calculator-card-header">
                    <Typography variant="h5">Estimate returns</Typography>
                  </Box>
                </Grid>
                <Box className="calculator-card-area">
                  <Box>
                    <Box className="calculator-card-action-area">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <Box className="calculator-card-action-area-inp-wrap">
                            <Typography
                              variant="h6"
                              style={{
                                color: "white",
                              }}
                            >
                              AALTO BALANCE
                            </Typography>
                            <Typography variant="h5">
                              <SvgIcon color="primary" component={LogoIcon} width="22px" viewBox="0 0 22 22" />{" "}
                              {rewardsEstimation}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box className="calculator-card-action-area-inp-wrap">
                            <Typography style={{ marginBottom: "12px", color: "white" }} variant="h6">
                              USD BALANCE
                            </Typography>
                            <Typography variant="h5">{formatCurrency(potentialReturn, 2)}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className="aalto-card" style={{ paddingTop: "36px", paddingBottom: "36px" }}>
              <Box className="metric" style={{ marginBottom: "72px" }}>
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={WalletIcon} viewBox="0 0 36 36" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      WALLET BALANCE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? trim("$" + AaltoBalance * marketPrice, 2) : "$0.00"}</>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? trim(AaltoBalance, 2) : "0.00"} AALTO</>
                    )}
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      AALTO PRICE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{marketPrice ? trim("$" + marketPrice, 6) : "$0.00"}</>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <Link
                      href="https://defined.fi/bsc/0x4fea7445f515b215f4284f33cb6be610d5df0c33?cache=b16e4"
                      style={{ color: "inherit" }}
                    >
                      <u>VIEW CHART</u>
                    </Link>
                  </CustomFooterText>
                </div>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Zoom>
    </div>
  );
}

export default Calculator;
