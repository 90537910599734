import React, { useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

interface ProgressCountdownProps {
  str: string;
  base: Date;
  unixDeadline: string;
  description: string;
}

const ProgressCountdown: React.FC<ProgressCountdownProps> = ({ unixDeadline }) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (unixDeadline) {
      setTimeLeft(+unixDeadline * 1000);
    }
  }, [unixDeadline]);

  const countdownRenderer = (countdownProps: CountdownRenderProps) => {
    const { minutes, seconds } = countdownProps;
    const m = String(minutes);
    const s = String(seconds);

    return (
      <div className="counter">
        <span>{m.padStart(2, "0")}</span>:<span>{s.padStart(2, "0")}</span>
      </div>
    );
  };

  return timeLeft && <Countdown date={timeLeft} renderer={countdownRenderer} />;
};

export default ProgressCountdown;
