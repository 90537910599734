import styled from "styled-components";
import { Paper, Typography, Box, Container, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { trim, formatCurrency, getTokenHolders } from "../../helpers";
import moment from "moment";
import "./dashboard.scss";
import ProgressCountdown from "src/components/ProgressCountdown";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as BankIcon } from "../../assets/icons/bank.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/wallet.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as Metamask } from "../../assets/metamask.svg";
import { ReactComponent as AmesIcon } from "../../assets/icons/ames.svg";
import { ReactComponent as RebaseIcon } from "../../assets/icons/rebase.svg";
import { ReactComponent as BurnIcon } from "../../assets/icons/burn.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { ReactComponent as InsuranceIcon } from "../../assets/icons/insurance.svg";
import { useHoldersCount } from "src/hooks/useHoldersCount";
import { TOKENS_KEYS, watchAssetInMetamask } from "src/helpers/metamask";
import { PageHeading } from "../../components/PageHeading";

const CustomSpan = styled.span`
  color: #03e1d0;
`;

const CustomHeaderText = styled(Box)`
  padding-top: 2px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
`;

const CustomHeaderTitle = styled(Typography)`
  padding-left: 12px;
  white-space: normal;
`;

const CustomFooterText = styled(Typography)`
  padding-top: 15px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
`;

const StyledMetamask = styled(Metamask)`
  margin: -2px 0 0 5px;
  cursor: pointer;
  flex-shrink: 0;
`;

const MarianaTrenchPer = styled(Typography)`
  margin-left: 6px;
  font-size: 16px;
  color: #03e1d0;
  font-weight: 400;
`;

function TreasuryDashboard() {
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");
  const holdersCount = useHoldersCount();

  const isAppLoading = useSelector(state => state.app.loading);

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const circSupply = useSelector(state => {
    return state.app.circSupply;
  });
  const totalSupply = useSelector(state => {
    return state.app.totalSupply;
  });

  const marketCap = useSelector(state => {
    return state.app.marketCap;
  });
  const bankBal = useSelector(state => {
    return state.account.balances.totalDeposit;
  });

  const dailyAPY = useSelector(state => {
    return state.app.dailyAPY;
  });

  const AaltoBalance = useSelector(state => {
    return state.account.balances && state.account.balances.aalto;
  });

  const NextRebaseValue = useSelector(state => {
    return state.account.balances.nextRebaseValue;
  });

  const NextRebaseDollarValue = useSelector(state => {
    const nextDollarValue = NextRebaseValue * marketPrice;
    return nextDollarValue.toFixed(6);
  });

  const NextBankRebaseValue = useSelector(state => {
    return state.account.balances.nextBankRebaseValue;
  });

  const NextBankRebaseDollarValue = useSelector(state => {
    const nextDollarValue = (NextBankRebaseValue || 0) * marketPrice;
    return nextDollarValue.toFixed(6);
  });

  const WalletAndBankAmount = useSelector(state => {
    const bankBalance = Number(state.account.balances.totalDeposit);
    const balance = state.account.balances && state.account.balances.aalto ? Number(state.account.balances.aalto) : 0;

    const nextAaltoAmount = bankBalance + balance;
    return nextAaltoAmount.toFixed(6);
  });

  const nextRebase = useSelector(state => {
    return state.app.nextRebase;
  });

  const burnBal = useSelector(state => {
    return state.app.burnBal;
  });

  const lockedInBank = useSelector(state => {
    return state.app.lockedInBank;
  });

  return (
    <div id="treasury-dashboard-view" className={`${smallerScreen && "smaller"} ${verySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: smallerScreen || verySmallScreen ? "0" : "3.3rem",
          paddingRight: smallerScreen || verySmallScreen ? "0" : "3.3rem",
        }}
      >
        <PageHeading title="Dashboard" />
        <Box className={`hero-metrics`}>
          <Paper className="aalto-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box className="metric-div-4">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={WalletIcon} viewBox="0 0 32 32" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      WALLET BALANCE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? formatCurrency(AaltoBalance * marketPrice, 2) : "$0.00"}</>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? trim(AaltoBalance, 8) : "0.00"} AALTO</>
                    )}
                    <StyledMetamask onClick={() => watchAssetInMetamask(TOKENS_KEYS.AALTO)} />
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric-div-4">
                <div>
                  <Typography variant="h6" color="textSecondary" className="header-span">
                    DAILY ROI
                  </Typography>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <CustomSpan>
                        {AaltoBalance ? formatCurrency(WalletAndBankAmount * marketPrice * dailyAPY, 2) : "$0.00"}
                      </CustomSpan>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? (WalletAndBankAmount * dailyAPY).toFixed(4) : "0.00"} AALTO</>
                    )}
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric-div-4">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={BankIcon} viewBox="0 0 36 36" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      BANK BALANCE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{bankBal ? trim("$" + bankBal * marketPrice, 4) : "$0.00"}</>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? <Skeleton width="100%" /> : <>{bankBal ? trim(bankBal, 6) : "0.00"} xAALTO</>}
                    <StyledMetamask onClick={() => watchAssetInMetamask(TOKENS_KEYS.XAALTO)} />
                  </CustomFooterText>
                </div>
              </Box>
              {/* <Box className="metric-div-4">
                <div>
                  <CustomHeaderText variant="h6" color="textSecondary" className="header-span">
                    BANK EARNING
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <CustomSpan>{bankBal ? trim("$" + bankBal * marketPrice * dailyAPY, 12) : "$0.00"}</CustomSpan>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    <SvgIcon color="primary" component={AmesIcon} viewBox="0 0 22 22" />
                    <img src="https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850" className="fml" />
                    <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766"  className="fml"/>
                  </CustomFooterText>
                </div>
              </Box> */}
            </Box>
          </Paper>
        </Box>
        <Box className={`hero-metrics metric-div-4`}>
          <Paper className="aalto-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box className="metric-div-4">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={ClockIcon} viewBox="0 0 36 36" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      NEXT REBASE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    <CustomSpan>
                      <ProgressCountdown
                        str="Rebasing"
                        base={moment().toDate()}
                        unixDeadline={nextRebase}
                        description="Next Rebase"
                      />
                    </CustomSpan>
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary">
                    Rebases every 2 hours
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric-div-4">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={RebaseIcon} viewBox="0 0 36 36" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      NEXT WALLET REBASE VALUE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <CustomSpan>{AaltoBalance ? trim("$" + NextRebaseDollarValue, 4) : "$0.00"}</CustomSpan>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? trim(NextRebaseValue, 8) : "0.00"} AALTO</>
                    )}
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric-div-4">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={RebaseIcon} viewBox="0 0 36 36" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      NEXT BANK REBASE VALUE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <CustomSpan>{AaltoBalance ? trim("$" + NextBankRebaseDollarValue, 4) : "$0.00"}</CustomSpan>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{AaltoBalance ? trim(NextBankRebaseValue, 8) : "0.00"} AALTO</>
                    )}
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric-div-4">
                <div>
                  <Typography variant="h6" color="textSecondary" className="header-span">
                    APY
                  </Typography>
                  <Typography variant="h5">{isAppLoading ? <Skeleton width="100%" /> : <>402,252.1%</>}</Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    DAILY ROI {isAppLoading ? <Skeleton width="100%" /> : <> 2.3</>}%
                  </CustomFooterText>
                </div>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box className={`hero-metrics`}>
          <Paper className="aalto-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box className="metric">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      AALTO PRICE
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{marketPrice ? trim("$" + marketPrice, 6) : "$0.00"}</>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <a
                      href="https://defined.fi/bsc/0x4fea7445f515b215f4284f33cb6be610d5df0c33?cache=b16e4"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      <u>VIEW CHART</u>
                    </a>
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric">
                <div>
                  <Typography variant="h6" color="textSecondary" className="header-span">
                    MARKET CAP
                  </Typography>
                  <Typography variant="h5">
                    {marketCap ? formatCurrency(marketCap, 0) : <Skeleton type="text" />}
                  </Typography>
                </div>
              </Box>
              <Box className="metric">
                <div>
                  <Typography variant="h6" color="textSecondary" className="header-span">
                    HOLDER COUNT
                  </Typography>
                  <Typography variant="h5">{`${holdersCount} Holders`}</Typography>
                </div>
              </Box>
              <Box className="metric">
                <div>
                  <CustomHeaderText className="header-span">
                    <SvgIcon color="primary" component={BankIcon} viewBox="0 0 36 36" />
                    <CustomHeaderTitle variant="h6" color="textSecondary">
                      TOTAL LOCKED IN BANK
                    </CustomHeaderTitle>
                  </CustomHeaderText>
                  <Typography variant="h5">
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{lockedInBank ? formatCurrency(lockedInBank * marketPrice, 0) : "$0.00"}</>
                    )}
                  </Typography>
                  <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                    {isAppLoading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <>{lockedInBank ? trim(lockedInBank, 2) : "0.00"} AALTO</>
                    )}
                  </CustomFooterText>
                </div>
              </Box>
              <Box className="metric">
                <div>
                  <Typography variant="h6" color="textSecondary" className="header-span">
                    CIRCULATING SUPPLY
                  </Typography>
                  <Typography variant="h5">
                    <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />{" "}
                    {circSupply ? (
                      <>
                        {circSupply
                          ? new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                            }).format(circSupply)
                          : "0.00"}
                      </>
                    ) : (
                      <Skeleton type="text" width="100%" />
                    )}
                  </Typography>
                </div>
              </Box>
              <Box className="metric">
                <Typography variant="h6" color="textSecondary" className="header-span">
                  TOTAL SUPPLY
                </Typography>
                <Typography variant="h5">
                  <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />{" "}
                  {totalSupply ? (
                    <>
                      {totalSupply
                        ? new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(
                            totalSupply,
                          )
                        : "0.00"}
                    </>
                  ) : (
                    <Skeleton type="text" width="100%" />
                  )}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box className={`hero-metrics`} paddingBottom={7}>
          <Paper className="aalto-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box className="metric-div-2">
                <CustomHeaderText className="header-span">
                  <SvgIcon color="primary" component={BurnIcon} viewBox="0 0 36 36" />
                  <CustomHeaderTitle variant="h6" color="textSecondary">
                    TOTAL BURNED IN MARIANA TRENCH
                  </CustomHeaderTitle>
                </CustomHeaderText>
                <Typography variant="h5">
                  {isAppLoading ? (
                    <Skeleton width="100%" />
                  ) : (
                    <>
                      {burnBal ? formatCurrency(burnBal * marketPrice, 0) : "$0.00"}
                      <MarianaTrenchPer as="span">{trim((burnBal * 100) / totalSupply, 2)}%</MarianaTrenchPer>
                    </>
                  )}
                </Typography>
                <CustomFooterText variant="text" color="textSecondary" className="footer-span">
                  <SvgIcon color="primary" component={LogoIcon} viewBox="0 0 22 22" />
                  {isAppLoading ? <Skeleton width="100%" /> : <>{burnBal ? trim(burnBal, 2) : "0.00"} AALTO</>}
                </CustomFooterText>
              </Box>
              {/* <Box className="metric-div-2">
                <CustomHeaderText variant="h6" color="textSecondary" className="header-span">
                  <SvgIcon color="primary" component={InsuranceIcon} viewBox="0 0 36 36" />
                  CORAL PRESERVATION FUND (INSURANCE)
                </CustomHeaderText>
                <Typography variant="h5">
                  {isAppLoading ? (
                    <Skeleton width="100%" />
                  ) : (
                    <>{insuranceBusdBal ? formatCurrency(insuranceBusdBal, 0) : "$0.00"}</>
                  )}
                </Typography>
              </Box> */}
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default TreasuryDashboard;
