import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const Container = styled.div`
  padding: 14px;
  gap: 40px;
  width: 100%;
  background: #000829;
  border: 1px solid #2f3333;
  border-radius: 8px;
`;

const MatchName = styled(Typography)`
  padding-bottom: 15px;
  letter-spacing: 0.1722em;
  color: #03e1d0 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 16px;
`;

const TokenWrapper = styled(Typography)`
  &:not(:last-child) {
    padding-bottom: 10px;
  }
`;

interface TokenNameProps {
  inline?: boolean;
}

const TokenName = styled(Typography)<TokenNameProps>`
  padding-bottom: ${({ inline }) => (inline ? undefined : "4px")};
  color: #03e1d0 !important;
  letter-spacing: -0.02em;
  font-size: ${({ inline }) => (inline ? "16" : "14")}px !important;
  font-weight: 700 !important;
  line-height: 22px;
`;

const TokenAmount = styled(Typography)`
  color: #e4ebea !important;
  letter-spacing: -0.02em;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 22px;
`;

const TokenValue = styled(Typography)`
  padding-top: 4px;
  letter-spacing: -0.02em;
  color: #b3baba !important;
  font-size: 14px !important;
  line-height: 16px;
  font-weight: 700 !important;
`;

const TicketNumber = styled(Typography)`
  padding-bottom: 6px;
  letter-spacing: 0.1em;
  color: #848a89 !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500 !important;
`;

const RoundRewards = props => {
  const { tokens, countWinnersPerBracket, isHistoricRound } = props;

  return (
    <Container>
      <Grid container direction="row" spacing={3}>
        {countWinnersPerBracket &&
          countWinnersPerBracket.map((numWinners, i) => {
            return (
              <Grid item xs={6} sm={4} md={4} key={i}>
                <MatchName>MATCH FIRST {i + 1}</MatchName>

                {tokens?.length &&
                  tokens.map(token => {
                    return (
                      <TokenWrapper key={token.tokenAddress}>
                        {isHistoricRound ? (
                          <div>
                            <TokenName>{token.name}</TokenName>
                            <TokenAmount>
                              ~
                              {token.bracketsRewards[i].amount > 0 && numWinners > 0
                                ? (token.bracketsRewards[i].amount / numWinners).toFixed(4)
                                : token.bracketsRewards[i].amount}{" "}
                              each
                            </TokenAmount>
                          </div>
                        ) : (
                          <div>
                            <TokenAmount as={"span"}>{token.bracketsRewards[i].amount}</TokenAmount>{" "}
                            <TokenName inline as={"span"}>
                              {token.name}
                            </TokenName>
                          </div>
                        )}

                        <TokenValue>
                          {`~$${
                            token.bracketsRewards[i].amount > 0 && numWinners > 0
                              ? (token.bracketsRewards[i].value / numWinners).toFixed(2)
                              : token.bracketsRewards[i].value
                          }`}
                        </TokenValue>
                      </TokenWrapper>
                    );
                  })}

                {isHistoricRound && <TicketNumber>{numWinners} Winning Tickets</TicketNumber>}
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default RoundRewards;
