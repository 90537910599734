import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TicketList from "../TicketList";
import { makeStyles } from "@material-ui/styles";
import RounderHeader from "./RoundHeader";
import Grid from "@material-ui/core/Grid";
import WinningNumber from "./WinningNumber";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    background: "#171E3B",
    padding: "32px 30px 40px",
  },
  ticketInfo: {
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    color: "#F0F7F7",
  },
}));

const UserHistoryTicketModal = ({ open, onClose, data }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        {data?.lottoData && (
          <RounderHeader lotteryId={data.lottoData.lotteryId} endTimeDate={data.lottoData.endTimeDate} />
        )}
      </DialogTitle>
      <DialogContent>
        {data?.ticketData?.ticketNumbers && (
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <WinningNumber winningNumber={data.lottoData.finalNumber} />
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                style={{
                  padding: "20px 10px",
                }}
              >
                <Grid item>
                  <span className={classes.ticketInfo}>Total Tickets:</span>
                </Grid>
                <Grid item>
                  <span className={classes.ticketInfo}>{data.ticketData.ticketNumbers.length}</span>
                </Grid>
              </Grid>

              <TicketList tickets={data.ticketData.ticketNumbers}></TicketList>
            </Grid>
          </Grid>
        )}

        <Button
          onClick={onClose}
          variant="outlined"
          fullWidth={true}
          style={{
            marginTop: "20px",
          }}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UserHistoryTicketModal;
