import { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LotteryHistory from "./LotteryHistory";
import UserLotteryHistory from "./UserLotteryHistory";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { useWeb3Context } from "src/hooks";
import { getLotteryContract } from "src/utils/contract.utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    active: {
      background: "#020de0",
    },
  }),
);

const FinishedHeader = styled.div`
  width: 100%;
  max-width: 635px;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 50px;
  /* identical to box height, or 104% */

  text-align: center;
  letter-spacing: -0.046em;
  font-feature-settings: "ss02" on;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const ToggleButton = styled.span`
  /* Chip / Primary */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;

  width: 95px;
  height: 44px;
  margin: 15px;

  border-radius: 228px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  cursor: pointer;
`;

const ButtonText = styled.span`
  width: 71px;
  height: 22px;

  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  text-align: center;
  letter-spacing: -0.02em;

  /* Gray / 100 */

  color: #fafcfc;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ButtonText2 = styled(ButtonText)`
  width: 88px;
`;

const History = () => {
  const { provider, chainID, connected, address } = useWeb3Context();
  const lotteryContract = getLotteryContract(chainID, provider.getSigner());
  const classes = useStyles();

  const [view, setView] = useState("lotto");
  const [initialLottery, setInitialLottery] = useState("lotto");

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item>
          <FinishedHeader>Finished Rounds</FinishedHeader>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <ToggleButton className={view == "lotto" ? classes.active : ""} onClick={() => setView("lotto")}>
          <ButtonText>All History</ButtonText>
        </ToggleButton>
        <ToggleButton className={view == "user" ? classes.active : ""} onClick={() => setView("user")}>
          <ButtonText2>Your History</ButtonText2>
        </ToggleButton>
      </Grid>

      {view && view == "lotto" ? (
        <LotteryHistory lotteryContract={lotteryContract} lottery={null}></LotteryHistory>
      ) : (
        <UserLotteryHistory></UserLotteryHistory>
      )}
    </div>
  );
};

export default History;
