import { ethers } from "ethers";
import axios from 'axios';

const DEXSCREENER_PAIR_BASE_URL =
  'https://api.dexscreener.io/latest/dex/pairs/';

export async function getDexPairInfo(
  chainSymbol: 'bsc' | 'harmony',
  pairAddress: string,
) {
  try {
    const req = await axios.get(
      `${DEXSCREENER_PAIR_BASE_URL}${chainSymbol}/${pairAddress}`,
    );
    return req.data;
  } catch (error) {
    throw error;
  }
}


export function setPoolInfo(pools: any[], gonsPerFragment: ethers.BigNumber) {
    const lockTimeOptions: any[] = [];
    pools.forEach((pool: any) => {
      lockTimeOptions.push({
        lockTimeLabel: (pool.lockTimeSeconds.toNumber() / 60 / 60 / 24) + ' days' ,
        poolId: pool.poolId.toNumber(),
        poolTotalDeposits: ethers.utils.formatEther(pool.amountLocked.div(gonsPerFragment)),
      })
    });

    return lockTimeOptions;
}

