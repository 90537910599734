import { useState } from "react";
import { useWeb3Context } from "src/hooks/web3Context";
import { getLotteryContract } from "src/utils/contract.utils";
import { FetchStatus } from "../lotto.types";
import { checkWinningTickets } from "../state/user-tickets";

const useGetUnclaimedRewards = () => {
  const { address, chainID, provider } = useWeb3Context();
  const lotteryContract = getLotteryContract(chainID, provider);
  const [unclaimedRewards, setUnclaimedRewards] = useState([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.Idle);

  const fetchAllRewards = async () => {
    setFetchStatus(FetchStatus.Fetching);
    const unclaimedRewardsResponse = await checkWinningTickets(lotteryContract, address);
    setUnclaimedRewards(unclaimedRewardsResponse);
    setFetchStatus(FetchStatus.Fetched);
  };

  return { fetchAllRewards, unclaimedRewards, fetchStatus };
};

export default useGetUnclaimedRewards;
