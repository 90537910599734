import { ethers } from "ethers";
import { abi as AaltoABI } from "../abi/AaltoContract.json";
import { abi as BankABI } from "../abi/BankABI.json";
import { setAll } from "../helpers";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { getDexPairInfo, setPoolInfo } from "./utils";
import {
  AMES_ADDRESS,
  BSC_TOKENS,
  WBNB_ADDRESS,
  ETH_ADDRESS,
  ASHARE_ADDRESS,
  AALTO_ADDRESS,
  SEVENS_FINANCE_ADDRESS,
  QUANTIC_TOKEN_ADDRESS,
  GRAPE_TOKEN_ADDRESS,
  WBTC_ADDRESS,
  WRAPPED_AALTO_ADDRESS,
  LSHARE_ADDRESS,
  TECHNICOLOR_ADDRESS,
} from "src/data/tokens";
import { formatEther, formatUnits } from "@ethersproject/units";
import { ADDRESSES } from "src/data/addresses";
import { getGaugeRewardValue } from "src/utils/gauges";

interface LockedData {
  poolId: number;
  lockPeriod: number;
  amountLocked: string;
  poolTotalDeposits: string;
  apr: number;
  endTime: number;
  canWithdraw: boolean;
  startTimeDate: Date | null;
  endTimeDate: Date | null;
  isUserStaked: boolean;
}

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    const AaltoContract = new ethers.Contract(ADDRESSES[networkID].AALTO_ADDRESS as string, AaltoABI, provider);
    const AaltoBalance = await AaltoContract.balanceOf(address);
    const allowance = await AaltoContract.allowance(address, ADDRESSES[networkID].AALTO_ADDRESS);
    return {
      balances: {
        aalto: ethers.utils.formatUnits(AaltoBalance, "ether"),
        allowance: allowance,
      },
    };
  },
);

export const getBankRewards = async (networkID, provider, address) => {
  const BankContract = new ethers.Contract(ADDRESSES[networkID].BANK_ADDRESS as string, BankABI, provider);
  const AaltoContract = new ethers.Contract(ADDRESSES[networkID].AALTO_ADDRESS as string, AaltoABI, provider);

  // TODO: Multicall this shit
  const [
    userAaltoBalance,
    busdReward,
    bnbReward,
    amesReward,
    ethReward,
    ashareReward,
    wAaltoReward,
    aaltoReward,
    lshareRewards,
    btcRewards,
    technicolorReward,
  ] = await Promise.all([
    AaltoContract.balanceOf(address),
    BankContract.userRewardsForToken(address, ADDRESSES[networkID].BUSD_ADDRESS),
    BankContract.userRewardsForToken(address, ADDRESSES[networkID].WBNB_ADDRESS),
    BankContract.userRewardsForToken(address, ADDRESSES[networkID].AMES_ADDRESS),
    BankContract.userRewardsForToken(address, ADDRESSES[networkID].ETH_ADDRESS),
    BankContract.userRewardsForToken(address, ASHARE_ADDRESS),
    BankContract.userRewardsForToken(address, WRAPPED_AALTO_ADDRESS),
    BankContract.userRewardsForToken(address, AALTO_ADDRESS),
    BankContract.userRewardsForToken(address, LSHARE_ADDRESS),
    BankContract.userRewardsForToken(address, WBTC_ADDRESS),
    BankContract.userRewardsForToken(address, TECHNICOLOR_ADDRESS),
  ]);

  const [wbnbInfo, amesInfo, ethInfo, ashareInfo, wAaltoInfo, aaltoInfo, lshareInfo, wbtcInfo, technicolorRewardValue] =
    await Promise.all([
      getDexPairInfo("bsc", BSC_TOKENS[WBNB_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[AMES_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[ETH_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[ASHARE_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[WRAPPED_AALTO_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[AALTO_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[LSHARE_ADDRESS].pairAddress),
      getDexPairInfo("bsc", BSC_TOKENS[WBTC_ADDRESS].pairAddress),
      getGaugeRewardValue(
        TECHNICOLOR_ADDRESS,
        "0x835ff599b9388e4f733c165a15a93e736ebf91d3000100000000000000000017",
        provider,
        formatEther(technicolorReward),
      ),
    ]);

  const userBNB = formatEther(bnbReward);
  const userAmes = formatEther(amesReward);
  const userETH = formatEther(ethReward);
  const userAshareReward = formatEther(ashareReward);
  const userwAaltoReward = formatEther(wAaltoReward);
  const userAaltoReward = formatEther(aaltoReward);
  const userLshareReward = formatEther(lshareRewards);
  const userWbtcReward = formatEther(btcRewards);
  const userTechReward = formatEther(technicolorReward);

  return {
    aalto: formatEther(userAaltoBalance),
    busdReward: formatEther(busdReward),
    bnbRewardAmount: userBNB,
    bnbRewardValue: Number(userBNB) * Number(wbnbInfo.pair?.priceUsd || 0),
    ethRewardAmount: userETH,
    ethRewardValue: Number(userETH) * Number(ethInfo.pair?.priceUsd || 0),
    amesRewardAmount: userAmes,
    amesRewardValue: Number(userAmes) * Number(amesInfo.pair?.priceUsd || 0),
    userAshareReward,
    ashareRewardValue: Number(userAshareReward) * Number(ashareInfo.pair?.priceUsd || 0),
    userwAaltoReward,
    wAaltoRewardValue: Number(userwAaltoReward) * Number(wAaltoInfo.pair?.priceUsd || 0),
    userAaltoReward,
    aaltoRewardValue: Number(userAaltoReward) * Number(aaltoInfo.pair?.priceUsd || 0),
    userGrapeReward: "0.0",
    userGrapeRewardValue: 0,
    userLshareReward,
    userLshareRewardValue: Number(userLshareReward) * Number(lshareInfo.pair?.priceUsd || 0),
    userWbtcReward,
    userWbtcRewardValue: Number(userWbtcReward) * Number(wbtcInfo.pair?.priceUsd || 0),
    technicolorReward: userTechReward,
    technicolorRewardValue,
  };
};

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    const AaltoContract = new ethers.Contract(ADDRESSES[networkID].AALTO_ADDRESS as string, AaltoABI, provider);
    const BankContract = new ethers.Contract(ADDRESSES[networkID].BANK_ADDRESS as string, BankABI, provider);

    const totalDeposit = await AaltoContract.lockedBalanceOf(address);

    const gonsPerFragment = await AaltoContract.gonsPerFragment();
    const userBalance = await AaltoContract.balanceOf(address);
    let nextRebaseValue = 0;
    let nextBankRebaseValue = 0;

    // TODO: Multicall this shit
    let [rewardYield, rewardYieldDenominator, totalGons, totalSupply, circSupply] = await Promise.all([
      AaltoContract.rewardYield(),
      AaltoContract.rewardYieldDenominator(),
      AaltoContract.totalGons(),
      AaltoContract.totalSupply(),
      AaltoContract.getCirculatingSupply(),
    ]);

    const supplyDeltaBN = circSupply.mul(rewardYield).div(rewardYieldDenominator);
    const willBeTotalSupplyBN = totalSupply.add(supplyDeltaBN);
    const newGonsPerFragmentBN = totalGons.div(willBeTotalSupplyBN);

    const userNewBalanceBN = userBalance.mul(gonsPerFragment).div(newGonsPerFragmentBN);
    const userCurrentBalanceNum = Number(formatEther(userBalance));
    const userNewBalanceNum = Number(formatEther(userNewBalanceBN));

    if (userBalance.gt(0)) {
      nextRebaseValue = userNewBalanceNum - userCurrentBalanceNum;
    }

    if (totalDeposit.gt(0)) {
      const userCurrentBankBalanceNum = Number(formatEther(totalDeposit));
      const userNewBankBalanceBN = totalDeposit.mul(gonsPerFragment).div(newGonsPerFragmentBN);
      const userNewBankBalanceNum = Number(formatEther(userNewBankBalanceBN));
      nextBankRebaseValue = userNewBankBalanceNum - userCurrentBankBalanceNum;
    }

    const emergencyRelease = await BankContract.emergencyRelease();
    let userLocks: LockedData[] = [];
    let pools = await BankContract.getPools();
    pools = setPoolInfo(pools, gonsPerFragment);

    const aprs = ["6.23%", "8.91%", "14.57%", "16.52%", "19.08%", "23.35%", "26.67%"];
    for (const pool of pools) {
      const userRecord = await BankContract.userPools(address, pool.poolId);
      const isUserStaked = userRecord.amountLocked.gt(ethers.constants.Zero);
      const endTimeNum = userRecord.endTime.toNumber();

      const userData: LockedData = {
        ...pool,
        apr: aprs[pool.poolId],
        amountLocked: formatEther(userRecord.amountLocked.div(gonsPerFragment)),
        endTime: isUserStaked ? endTimeNum / 60 / 60 / 24 : 0,
        canWithdraw: isUserStaked ? endTimeNum * 1000 <= Date.now() || emergencyRelease : false,
        startTimeDate: isUserStaked ? new Date(userRecord.startTime.toNumber() * 1000).toUTCString() : "",
        endTimeDate: isUserStaked ? new Date(userRecord.endTime * 1000).toString() : "",
        isUserStaked,
      };

      userLocks.push(userData);
    }

    const bankRewards = await getBankRewards(networkID, provider, address);

    return {
      balances: {
        totalDeposit: formatEther(totalDeposit),
        nextRebaseValue,
        nextBankRebaseValue,
        ...bankRewards,
      },

      userLocks,
      emergencyRelease,
    };
  },
);

interface IAccountSlice {
  balances: {
    aalto: string;
  };
  loading: boolean;
}

const initialState: IAccountSlice = {
  loading: false,
  balances: { aalto: "" },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
