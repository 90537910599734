import { formatEther } from "@ethersproject/units";
import { BSC_TOKENS } from "src/data/tokens";
import { TOKENS_KEYS } from "src/helpers/metamask";
import { getDexPriceFromPair } from "src/utils/dexscreener";
import { LotteryBase, LotteryToken } from "./lotto.types";
import { LotteryStatus, UserCursorTicketInfo } from "./types";
import { ethersToBigNumber } from "src/utils/bigNumber";

export const getRunningRewardAllocations = (token, rewardsBreakdown: number[]) => {
  const bracketsRewards = [];
  const treasuryPercent = 3000 / 10000;
  rewardsBreakdown.forEach(allocation => {
    let bracketValue = token.amountTokenCollected * (allocation / 10000);
    if (bracketValue > 0) {
      bracketValue -= bracketValue * treasuryPercent;
    }
    bracketsRewards.push({
      amount: bracketValue.toFixed(bracketValue == 0 ? 2 : 4),
      value: (bracketValue * token.currentPrice).toFixed(2),
    });
  });

  token.bracketsRewards = bracketsRewards;
};

export const LOTTO_STATUS = {
  0: "pending",
  1: "open",
  2: "close",
  3: "claimable",
};

/**
 * Remove the '1' and reverse the digits in a lottery number retrieved from the smart contract
 */
export const parseRetrievedNumber = (number: string): string => {
  const numberAsArray = number.split("");
  numberAsArray.splice(0, 1);
  numberAsArray.reverse();
  return numberAsArray.join("");
};

export const getDrawnDate = (locale: string, endTime: string) => {
  const endTimeInMs = parseInt(endTime, 10) * 1000;
  const endTimeAsDate = new Date(endTimeInMs);
  return endTimeAsDate.toLocaleDateString(locale, dateTimeOptions);
};

export const getNextDrawDateTime = (status, endDate) => {
  if (status === LotteryStatus.OPEN) {
    return `"Draw": ${endDate.toLocaleString()}`;
  }
  return "";
};

export const getTotalPotValue = async (tokens: LotteryToken[], treasuryFee: number) => {
  try {
    let totalPot = 0;
    const treasuryPercent = treasuryFee / 10000;
    for (const token of tokens) {
      const priceInfo = await getDexPriceFromPair("bsc", token.pairAddress);
      token.totalValue = priceInfo.priceNum * token.amountTokenCollected;
      token.totalValue -= token.totalValue * treasuryFee;
      token.currentPrice = priceInfo.priceNum;
      totalPot += priceInfo.priceNum * token.amountTokenCollected;
    }

    totalPot -= totalPot * treasuryPercent;

    return Number(totalPot.toFixed(2));
  } catch (error) {
    console.log(error);
  }
};

export const parseTokenRewardInfo = (tokens: LotteryToken[], treasuryFee: number) => {
  const treasuryPercent = treasuryFee / 10000;
  tokens.forEach(token => {
    token.rewardInfo = [];
    token.rewardPerBracket.forEach((rw, i) => {
      let prizeValue = Number((rw * token.currentPrice).toFixed(2));
      prizeValue -= prizeValue * treasuryPercent;
      let amount = rw;
      if (amount > 0) {
        amount -= amount * treasuryPercent;
      }
      token.rewardInfo.push({
        prizeValue: Number(prizeValue.toFixed(prizeValue == 0 ? 2 : 6)),
        amountToken: amount.toFixed(prizeValue == 0 ? 2 : 6),
      });
    });
  });
};

export const parseTokensInfo = async (tokens: any[]): Promise<LotteryToken[]> => {
  const uiTokens: LotteryToken[] = [];
  for (const token of tokens) {
    const symbol = BSC_TOKENS[token.tokenAddress]?.symbol;
    const maxTicketAmount = token.maxTicketAmount.toNumber();
    const ticketCount = token.ticketCount.toNumber();

    const info: any = {
      amountTokenCollected: Number(formatEther(token.amountTokenCollected)),
      maxTicketAmount,
      ticketCount,
      availableForPurchase: maxTicketAmount - ticketCount,
      pricePerTicket: ethersToBigNumber(token.pricePerTicket),
      tokenAddress: token.tokenAddress,
      treasuryFee: token.treasuryFee.add(token.burnFee).toNumber(), // global bank and Aalto fee also
      image: BSC_TOKENS[token.tokenAddress]?.image,
      tokenKey: TOKENS_KEYS[symbol],
      name: symbol,
      rewardPerBracket: token.rewardPerBracket.map(rw => Number(formatEther(rw))),
      pairAddress: BSC_TOKENS[token.tokenAddress]?.pairAddress,
    };
    uiTokens.push(info);
  }

  return uiTokens;
};

export const parseLotteryReponse = (response, lotteryId, maxNumberTicketsPerBuyOrClaim): LotteryBase => {
  const startTime = response.startTime.toNumber();
  const endTime = response.endTime.toNumber();

  return {
    lotteryId: lotteryId.toNumber ? lotteryId.toNumber() : lotteryId,
    status: LOTTO_STATUS[response.status],
    startTime,
    startTimeDate: new Date(startTime * 1000).toString(),
    endTime,
    endTimeDate: new Date(endTime * 1000).toUTCString(),
    firstTicketId: response.firstTicketId.toNumber(),
    firstTicketIdNextLottery: response.firstTicketIdNextLottery,
    rewardsBreakdown: response.rewardsBreakdown.map(rew => rew.toNumber()),
    rewardsBreakdownBN: response.rewardsBreakdown,
    discountDivisor: ethersToBigNumber(response.discountDivisor),
    finalNumber: response.finalNumber,
    maxNumberTicketsPerBuyOrClaim: ethersToBigNumber(maxNumberTicketsPerBuyOrClaim),
    countWinnersPerBracket: response.countWinnersPerBracket.map(ct => ct.toNumber()),
    treasuryFee: response.treasuryFee.toNumber(),
  };
};

export const parseUserLotteryReponse = (info): UserCursorTicketInfo => {
  const lotteryTicketIds: number[] = [];
  info[0].forEach(id => (id = lotteryTicketIds.push(id.toNumber())));
  const cursor = info[3].toNumber();

  return {
    lotteryTicketIds,
    ticketNumbers: info[1],
    ticketStatuses: info[2],
    cursor,
  };
};

export const getUserTicketsForRound = async (contract, user: string, lotteryId, totalCount: number) => {
  let cursor = 0;
  let size = 100;
  if (totalCount < size) {
    size = totalCount;
  }
  const info = await contract.viewUserInfoForLotteryId(user, lotteryId, cursor, size);

  const lotteryTicketIds = [];
  info[0].forEach(id => (id = lotteryTicketIds.push(id.toNumber())));
  cursor = info[3].toNumber();

  const ticketNumbers = info[1];
  const ticketStatuses = info[2];

  const final = [];
  info[0].forEach(
    (id, idx) =>
      (id = final.push({
        id: id.toNumber(),
        number: ticketNumbers[idx],
        status: ticketStatuses[idx],
      })),
  );

  const data = {
    tickets: final,
    cursor,
  };

  return data;
};

export const paginateUserTicketsForRound = async (contract, user: string, lotteryId, cursor = 0, size = 100) => {
  const info = await contract.viewUserInfoForLotteryId(user, lotteryId, cursor, size);

  const lotteryTicketIds = [];
  info[0].forEach(id => (id = lotteryTicketIds.push(id.toNumber())));
  cursor = info[3].toNumber();

  const ticketNumbers = info[1];
  const ticketStatuses = info[2];

  const final = [];
  info[0].forEach(
    (id, idx) =>
      (id = final.push({
        id: id.toNumber(),
        number: ticketNumbers[idx],
        status: ticketStatuses[idx],
      })),
  );

  const data = {
    tickets: final,
    cursor,
  };

  return data;
};

export const parseUserTicketInfo = info => {
  return {
    ...info,
    amountTokenIn: formatEther(info.amountTokenIn.amountTokenIn),
    tickets: info.tickets,
  };
};

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};

export const dateTimeOptions: Intl.DateTimeFormatOptions = {
  ...dateOptions,
  ...timeOptions,
};
