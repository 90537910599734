import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect, useState } from "react";
import styled from "styled-components";
import WinningNumber from "./WinningNumber";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LastPageIcon from "@material-ui/icons/LastPage";
import ViewTickets from "./ViewTickets";
import LotteryDetails from "../LotteryDetails";
import { getLotteryInfo } from "../../state/LotterySlice";
import { Round, RoundNumberInput, RoundId } from "../styles";
import { parseRetrievedNumber } from "../../helpers";

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 30px 40px;
  gap: 40px;
  max-width: 635px;
  width: 100%;
  background: #171e3b;
  border: 1px solid #2f3333;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const DrawnDate = styled.div`
  height: 28px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  font-feature-settings: "ss02" on;
  color: #e4ebea;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Arrow = styled.span`
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
`;

const LotteryHistory = ({ lotteryContract, lottery }) => {
  const [winningNumber, setWinningNumber] = useState(null);
  const [currentLotto, setCurrentLotto] = useState(null);
  const [maxRoundId, setMaxRoundId] = useState<number>(null);
  const [currentRoundId, setCurrentRoundId] = useState<number>(null);

  const setLottoNumber = finalNumber => {
    setWinningNumber(parseRetrievedNumber(String(finalNumber)).split(""));
  };

  const loadLotteryInfo = async id => {
    setCurrentLotto(null);
    setWinningNumber(null);
    const { parsedLotto } = await getLotteryInfo(id, lotteryContract);
    setCurrentLotto(parsedLotto);
    setLottoNumber(parsedLotto.finalNumber);
  };

  useEffect(() => {
    const loadLottery = async () => {
      setCurrentLotto(null);
      const currentId = await lotteryContract.currentLotteryId();
      setCurrentRoundId(currentId);
      const { parsedLotto } = await getLotteryInfo(currentId, lotteryContract);

      if (!parsedLotto.finalNumber) {
        setMaxRoundId(parsedLotto.lotteryId - 1);
        await loadLotteryInfo(parsedLotto.lotteryId - 1);
      } else {
        setCurrentLotto(parsedLotto);
        setLottoNumber(parsedLotto.finalNumber);
      }
    };

    loadLottery();
  }, []);

  const getNextLotto = async () => {
    if (currentLotto.lotteryId + 1 <= currentRoundId) {
      await loadLotteryInfo(currentLotto.lotteryId + 1);
    }
  };

  const getPreviousLotto = async () => {
    if (currentLotto.lotteryId - 1 !== 0) {
      await loadLotteryInfo(currentLotto.lotteryId - 1);
    }
  };

  const getCurrentLotto = async () => {
    if (currentLotto.lotteryId + 1 < maxRoundId) {
      await loadLotteryInfo(maxRoundId);
    }
  };

  return (
    <Frame>
      <Grid container alignItems="flex-start">
        <Grid item container xs={12}>
          <Grid item container alignItems="center" xs={6}>
            <Grid item>
              <Round>Round</Round>
            </Grid>
            <Grid item>
              <RoundNumberInput>
                <RoundId>{currentLotto ? currentLotto.lotteryId : <Skeleton></Skeleton>}</RoundId>
              </RoundNumberInput>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
              <Grid item>
                <Arrow>
                  <ArrowBackIcon onClick={getPreviousLotto}></ArrowBackIcon>
                </Arrow>
                <Arrow>
                  <ArrowForwardIcon onClick={getNextLotto}></ArrowForwardIcon>
                </Arrow>
                <Arrow>
                  <LastPageIcon onClick={getCurrentLotto}></LastPageIcon>
                </Arrow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            paddingTop: "5px",
          }}
        >
          {currentLotto ? (
            <DrawnDate>Drawn: {currentLotto.endTimeDate}</DrawnDate>
          ) : (
            <Skeleton variant="rect"></Skeleton>
          )}
        </Grid>

        
      </Grid>

      <Grid container direction="column" justifyContent="center">
        {winningNumber && <WinningNumber winningNumber={winningNumber} />}
        {/* <NumberFrame>
          <NumberHeader>Winning Number</NumberHeader>
          <Grid container justifyContent="center">
            {winningNumber ? (
              winningNumber.map((num, i) => {
                return <WinningNumber index={i} value={num} key={i}></WinningNumber>;
              })
            ) : (
              <Skeleton variant="rect"></Skeleton>
            )}
          </Grid>
        </NumberFrame> */}
      </Grid>

      {currentLotto?.userInfo && (
        <Grid container justifyContent="center">
          <Grid item>
            <ViewTickets lotteryId={currentLotto.lotteryId} />
          </Grid>
        </Grid>
      )}

      <LotteryDetails
        tokens={currentLotto?.tokens}
        countWinnersPerBracket={currentLotto?.countWinnersPerBracket}
        isHistoricRound={true}
      ></LotteryDetails>
    </Frame>
  );
};

export default LotteryHistory;
