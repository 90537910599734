import React from 'react';
import { IconButton, DialogTitle, Grid, Typography, DialogContent, DialogActions, Dialog, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
import { moveStake } from '../../slices/StakeThunk';
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { StyledRadioButton } from "../../views/Lottery/components/StyledRadioButton";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    background: "#171E3B",
    padding: "24px 32px",
    width: "100%",
    maxWidth: "445px",
  },
  dialogTitle: {
    color: "#848a89"
  }
}));

const CustomDialogTitle = styled(DialogTitle)`
  padding: 0;
  margin-bottom: 40px;
`;

const DialogHeader = styled(Typography)`
  flex: 1;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.01em;
  color: #f0f7f7;
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0;
`;

const TokenRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 24px;
  margin-bottom: 10px;

  & img {
    margin: 0;
  }

  & label {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }
`;

const TokenName = styled(Typography)`
  margin-left: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #848a89;
`;

export default function MoveStake(props) {
  const { provider, address, chainID } = useWeb3Context();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [newPoolId, setNewPoolId] = React.useState(null);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSumbit = async () => {
    setOpen(false);
    if (newPoolId != null) {
      await dispatch(moveStake({ 
          currentPoolId: props.currentPool.poolId, 
          newPoolId, 
          address, 
          provider, 
          networkID: chainID 
      }));
    }
  }

  return (
    <div>
      <Button 
        variant="contained"
        className="aalto-rec-button"
        disabled={!props.currentPool.isUserStaked || props.currentPool.poolId == 6}
        onClick={handleClickOpen}>
        Move Stake
      </Button>
      
      <Dialog open={open} onClose={handleClose} classes={{
        paper: classes.dialogPaper,
      }}>

        <CustomDialogTitle>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <DialogHeader variant="h4">Move Stake</DialogHeader>

            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="large" />
            </IconButton>          
          </Grid>
        </CustomDialogTitle>

        <CustomDialogContent>
          <Grid container direction="inherit" spacing={2}>
            {
              props.options.map(pool => (
                <Grid item xs={6} alignItems="flex-start">
                  <TokenRadioWrapper key={pool.poolId}>
                    <StyledRadioButton
                      key={pool.poolId}
                      checked={newPoolId == pool.poolId}
                      onChange={() => setNewPoolId(pool.poolId)}
                      value={pool}
                      id={`radio-token-${pool.poolId}`}
                      name={`radio-token-${pool.poolId}`}
                    />

                    <label htmlFor={`radio-token-${pool.poolId}`}>
                      <TokenName>{pool.lockTimeLabel}</TokenName>
                    </label>
                  </TokenRadioWrapper>
                </Grid>
              ))
            }
          </Grid>
        </CustomDialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSumbit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
