import styled from "styled-components";

const numberIndexMap = {
  0: {
    background: "#1AA6EC",
  },
  1: {
    background: "#03E1D0",
  },
  2: {
    background: "#D0D6D6",
  },
  3: {
    background: "#F9BF1B",
  },
  4: {
    background: "#E29400",
  },
  5: {
    background: "#9144B6",
  },
};
interface LottoNumberProps {
  index: number;
}

const LottoNumber = styled.span<LottoNumberProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  width: 30px;
  height: 30px;
  margin: 3px;
  background: ${({ index }) => numberIndexMap[index].background};
  border-radius: 600px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const NumberValue = styled.span`
  width: 10px;
  height: 22px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #1c1f1e;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const LotteryNumber = ({ index, value }) => {
  return (
    <LottoNumber index={index}>
      <NumberValue>{value}</NumberValue>
    </LottoNumber>
  );
};

export default LotteryNumber;
