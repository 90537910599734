import { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useWeb3Context } from "src/hooks";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { convertUserRawTicketResponse, fetchUserTicketsForOneRound } from "../../state/user-tickets";
import { getLotteryContract } from "src/utils/contract.utils";
import { getCurrentLotteryId, getLotteryInfo } from "../../state/LotterySlice";
import UserHistoryTicketModal from "./UserTicketHistoryModal";

const headers = {
  color: "#03E1D0",
  borderBottom: 0,
};

const cells = {
  fontSize: "18px",
  lineHeight: "28px",
  color: "#B3BABA",
};

const UserLotteryHistory = () => {
  const { provider, chainID, address } = useWeb3Context();
  const lotteryContract = getLotteryContract(chainID, provider.getSigner());

  const [fetching, setFetching] = useState(true);
  const [ticketList, setTicketList] = useState(null);
  const [viewTickets, setViewTickets] = useState(false);
  const [selectedTicketsRoundId, setSelectedTicketsRoundId] = useState(false);
  const [userDataForView, setUserDataForView] = useState(null);

  const handleViewTicketsOpen = id => {
    setSelectedTicketsRoundId(id);
    setUserDataForView(ticketList.find(tx => tx.ticketData.roundId == id));
    setViewTickets(true);
  };

  const handleViewTicketsClose = () => {
    setSelectedTicketsRoundId(null);
    setViewTickets(false);
    setUserDataForView(null);
  };

  const fetchUserRound = async lotteryId => {
    const [lottoData, rawTicketInfo] = await Promise.all([
      getLotteryInfo(lotteryId, lotteryContract),
      fetchUserTicketsForOneRound(address, lotteryId, lotteryContract),
    ]);

    const data = {
      roundId: lottoData.parsedLotto.lotteryId,
      endTimeDate: lottoData.parsedLotto.endTimeDate,
      totalTickets: rawTicketInfo.length ? rawTicketInfo[0].length : 0,
      ticketNumbers: rawTicketInfo.length ? convertUserRawTicketResponse(rawTicketInfo) : [],
    };

    return {
      ticketData: data,
      lottoData: lottoData.parsedLotto,
    };
  };

  useEffect(() => {
    const getData = async () => {
      setFetching(true);
      //  Start from current - TODO: count back then in batches. Virtual scroll or something
      const currentId = await getCurrentLotteryId(lotteryContract);
      const roundsToFetch = [];
      let id = currentId - 1; // disregard current for now, but check if claimable then
      while (id > 0) {
        roundsToFetch.push(id);
        id--;
      }

      const list = [];
      for (const round of roundsToFetch) {
        list.push(await fetchUserRound(round));
      }

      setTicketList(list);
      setFetching(false);
    };

    if (address) {
      getData();
    }
  }, [address]);

  const Loader = () => {
    const style = {
      margin: "10px 0px",
    };
    return (
      <div>
        <div>
          <Skeleton variant="rect" height={25} style={style} />
        </div>
        <div>
          <Skeleton variant="rect" height={25} style={style} />
        </div>
        <div>
          <Skeleton variant="rect" height={25} style={style} />
        </div>
      </div>
    );
  };

  const UserView = () => {
    return (
      <Paper style={{ margin: "24px auto" }} className="aalto-card">
        {address && ticketList?.length ? (
          fetching ? (
            <Loader />
          ) : (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={headers}>#</TableCell>
                    <TableCell style={headers}>DRAW DATE</TableCell>
                    <TableCell style={headers}>YOUR TICKETS</TableCell>
                    <TableCell style={headers}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketList.map((row, i) => (
                    <TableRow key={row.ticketData.roundId}>
                      <TableCell component="th" scope="row" style={cells}>
                        {row.ticketData.roundId}
                      </TableCell>
                      <TableCell
                        style={{
                          ...cells,
                          color: "#FAFCFC",
                        }}
                      >
                        {row.ticketData.endTimeDate}
                      </TableCell>
                      <TableCell
                        style={{
                          ...cells,
                          color: "#FAFCFC",
                        }}
                      >
                        {row.ticketData.totalTickets}
                      </TableCell>
                      <TableCell>
                        <span
                          className="aalto-txt pointer"
                          onClick={() => handleViewTicketsOpen(row.ticketData.roundId)}
                        >
                          VIEW TICKETS
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              {fetching ? (
                <Loader />
              ) : (
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "24px",
                    lineHeight: "30px",
                    letterSpacing: "-0.01em",
                    color: "#E4EBEA",
                  }}
                >
                  No data to show
                </p>
              )}
            </Grid>
          </Grid>
        )}

        {selectedTicketsRoundId && (
          // <ViewTicketsModal
          //   open={viewTickets}
          //   lotteryId={selectedTicketsRoundId}
          //   onClose={handleViewTicketsClose}
          // ></ViewTicketsModal>
          <UserHistoryTicketModal
            data={userDataForView}
            open={viewTickets}
            onClose={handleViewTicketsClose}
          ></UserHistoryTicketModal>
        )}
      </Paper>
    );
  };

  return <UserView />;
};

export default UserLotteryHistory;
